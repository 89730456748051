import React from 'react';
import { Marker } from 'react-map-gl';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { determineImageSource } from '../../../../utils/cycloneUtils';
import classNames from 'classnames';

interface ForecastTrackMarkerProps {
  forecastFeature: Feature<Geometry, GeoJsonProperties>;
  onShowPopup: (trackFeature: Feature<Geometry, GeoJsonProperties>) => void;
  onHidePopup: () => void;
}

const ForecastTrackMarker = ({
  forecastFeature,
  onShowPopup,
  onHidePopup
}: ForecastTrackMarkerProps) => {
  return (
    <Marker
      longitude={
        forecastFeature.geometry.type === 'Point'
          ? forecastFeature.geometry.coordinates[0]
          : 0
      }
      latitude={
        forecastFeature.geometry.type === 'Point'
          ? forecastFeature.geometry.coordinates[1]
          : 0
      }
    >
      <img
        src={
          determineImageSource(forecastFeature.properties?.icon) ?? undefined
        }
        alt={forecastFeature.properties?.icon}
        className={classNames(
          'w-9',
          'h-9',
          'cursor-pointer',
          'hover:w-12',
          'hover:h-12'
        )}
        onMouseEnter={() => onShowPopup(forecastFeature)}
        onMouseLeave={onHidePopup}
      />
    </Marker>
  );
};

export default ForecastTrackMarker;
