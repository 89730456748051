import { Dispatch } from 'react';
import { VoyageSchedule } from '../models/Voyage';
import { RouteAction, RouteActionType } from '../reducers/routeReducer';
import { checkRouteDepartureTime } from './checkRouteDepartureTime';

interface fetchRoutesProps {
  voyageData?: VoyageSchedule | null;
  voyageError?: string;
  routeDispatch: Dispatch<RouteAction>;
  setShouldFetchVoyageSummaries: (arg: boolean) => void;
}

export const fetchRoutes = ({
  voyageData,
  voyageError,
  routeDispatch,
  setShouldFetchVoyageSummaries
}: fetchRoutesProps) => {
  if (voyageError) {
    routeDispatch({
      type: RouteActionType.FETCH_ROUTES_AND_ERROR_TEXT,
      payload: {
        shouldFetchRoutes: false,
        routeErrorText: voyageError,
        routeStartTimestamp: null
      }
    });
  } else if (voyageData) {
    const searchTime = checkRouteDepartureTime(voyageData);
    setShouldFetchVoyageSummaries(false);
    routeDispatch({
      type: RouteActionType.FETCH_ROUTES_AND_ERROR_TEXT,
      payload: {
        shouldFetchRoutes: true,
        routeErrorText: voyageError,
        routeStartTimestamp: searchTime
      }
    });
  }
};
