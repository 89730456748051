import { Layer, Source } from 'react-map-gl';
import { CycloneMapData } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

export interface CycloneForecastLayerProps {
  cycloneMapData: CycloneMapData;
  paint:
    | {
        'text-color': string;
        'text-halo-color'?: undefined;
        'text-halo-width'?: undefined;
      }
    | {
        'text-color': string;
        'text-halo-color': string;
        'text-halo-width': number;
      };
}

const CycloneForecastLayer = ({
  cycloneMapData,
  paint
}: CycloneForecastLayerProps) => {
  return (
    <Source
      id={MapSourceId.CycloneForecast}
      type="geojson"
      data={cycloneMapData.forecastData}
    >
      <Layer
        id={MapLayerId.CycloneForecast}
        type="symbol"
        layout={{
          'text-field': ['get', 'dateTimeISO'],
          'text-allow-overlap': true,
          'text-anchor': 'left',
          'text-offset': [1.5, 0],
          'text-max-width': 100
        }}
        paint={paint}
      ></Layer>
    </Source>
  );
};

export default CycloneForecastLayer;
