import { VesselStatus } from './Vessel';

export interface RoutePoint {
  SK: string;
  /* Degrees relative to truth north (zero degrees) */
  Heading: number;
  Latitude: number;
  Longitude: number;
  /* UTC milliseconds */
  MessageTimestamp: number;
  SpeedOverGround: number;
  Status: VesselStatus;
  StatusDetail: string;
}
export interface Route {
  previousPort: string | null;
  nextPort: string | null;
  routeType: RouteType;
  routePoints: Array<RoutePoint>;
  movementDate: number;
  count: number;
  scannedCount: number;
  /** Populated by front-end */
  lowSpeedOverGround?: number;
  /** Populated by front-end */
  highSpeedOverGround?: number;
}

export enum RouteType {
  Current = 'Current',
  Historical = 'Historical'
}

export interface Routes {
  routes: Array<Route>;
}

export enum Source {
  HistoricalPoints = 'historical-points-source',
  HistoricalRoute = 'historical-route-source',
  RouteHeatMap = 'route-heat-map-source'
}

export enum Layer {
  HistoricalPoints = 'historical-points-layer',
  HistoricalRoute = 'historical-route-layer',
  RouteHeatMap = 'route-heat-map-layer'
}

export interface FeatureCoord {
  /* current browser time */
  timestamp: string;
  timestampMillisecondsUtc: number;
  /* degrees relative to true north (zero degrees) */
  heading: string;
  speedOverGround: number;
  speedOverGroundDisplay: string;
  coords: Array<number>;
}
