import cn from 'classnames';
import { ReactNode } from 'react';

export interface TabContentProps {
  className?: string | string[];
  tabs: string[];
  onChange: (tabIndex: number) => void;
  activeTabIndex?: number;
  children?: ReactNode | ReactNode[];
}

export function TabContent({
  tabs,
  className,
  onChange,
  activeTabIndex,
  children
}: TabContentProps) {
  return (
    <div
      className={cn('bg-white', 'w-fit', 'mb-10', 'md:mb-0', `h-detail-tab`)}
    >
      <div
        className={cn(
          'w-full',
          'flex',
          'flex-row',
          'bg-white',
          'relative',
          'even:border-l-0',
          'odd:border-r-0',
          className
        )}
      >
        {tabs.map((tab, index) => (
          <button
            className={cn(
              'px-3',
              'py-4',
              'h-14',
              'text-h300',
              'font-bold',
              'rounded-t',
              'border',
              'w-full',
              'border-silver-50',
              {
                'bg-white border-b-0': index === activeTabIndex,
                'text-silver-50 hover:bg-silver-5 hover:text-silver-90':
                  index !== activeTabIndex
              }
            )}
            onClick={() => onChange(index)}
            key={`${index}-${tab}`}
          >
            {tab}
          </button>
        ))}
      </div>
      <div
        className={cn(
          'bg-white',
          'border',
          'border-silver-50',
          'border-t-0',
          'w-full',
          'h-full',
          'rounded-b'
        )}
      >
        {children}
      </div>
    </div>
  );
}
