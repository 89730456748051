import {
  bearing,
  bearingToAzimuth,
  Coord,
  destination,
  Feature,
  lineArc,
  lineString,
  LineString,
  lineStrings,
  lineToPolygon,
  MultiPolygon,
  Point,
  point,
  Polygon,
  polygonTangents,
  Position,
  Properties,
  union
} from '@turf/turf';
import { isNil } from 'lodash';
import { DateTime } from 'luxon';
import { Outer34KtData } from '../components/Map/Layers/CycloneLayer/WindFieldLayer';
import { ForecastPoint, TrackPoint, WindRadii } from '../models/Cyclone';

export interface CyclonePointData {
  dateTime: DateTime | null;
  point: Feature<Point, Properties> | null;
  radius34KtMaxNM: number | null;
  windRadii: WindRadii[] | null;
  forecastHourIncrement: ForecastHourIncrement;
}

export declare type ForecastHourIncrement = 0 | 6 | 12 | 18 | 24 | 36 | 48 | 72;

export interface Max34KnotCircles {
  currentCyclonePointData: CyclonePointData | null;
  current34KtMaxCircleArc: Feature<LineString, Properties> | null;
  firstForecastPointData: CyclonePointData | null;
  firstForecastCircleArc: Feature<LineString, Properties> | null;
  secondForecastPointData: CyclonePointData | null;
  secondForecastCircleArc: Feature<LineString, Properties> | null;
  thirdForecastPointData: CyclonePointData | null;
  thirdForecastCircleArc: Feature<LineString, Properties> | null;
}

export interface MaxOuter34KnotCircles {
  firstForecastCircleRadius: number | null;
  firstForecastCircleArc: Feature<LineString, Properties> | null;
  secondForecastCircleRadius: number | null;
  secondForecastCircleArc: Feature<LineString, Properties> | null;
  thirdForecastCircleRadius: number | null;
  thirdForecastCircleArc: Feature<LineString, Properties> | null;
}

export interface MaxInner34KnotCircles {
  firstCenterPoint: Feature<Point, Properties> | null;
  firstCenterPointCircleRadius: number | null;
  firstInnerCircleArc: Feature<LineString, Properties> | null;
  secondCenterPoint: Feature<Point, Properties> | null;
  secondCenterPointCircleRadius: number | null;
  secondInnerCircleArc: Feature<LineString, Properties> | null;
  thirdCenterPoint: Feature<Point, Properties> | null;
  thirdCenterPointCircleRadius: number | null;
  thirdInnerCircleArc: Feature<LineString, Properties> | null;
}

export interface TangentLine {
  origin: Feature<Point, Properties>;
  destination: Feature<Point, Properties>;
}

export interface TangentLines {
  line1: TangentLine;
  line2: TangentLine;
}

export interface Max34KnotTangents {
  firstCirclePairPoints: TangentLines | null;
  secondCirclePairPoints: TangentLines | null;
  thirdCirclePairPoints: TangentLines | null;
}

interface WindRadiiHolder {
  cyclonePointData: CyclonePointData | null;
  windRadii: WindRadii[] | null | undefined;
}

export const WIND_SPEED_34_KNOT = 34;
export const WIND_SPEED_50_KNOT = 50;
export const WIND_SPEED_64_KNOT = 64;
/**
 * For every hour after the current time
 * we multiply by this factor. In other words,
 * this is the extra radius in nautical miles
 * that is added to the forecast prediction
 * for every hour after the current time.
 *
 * Original NOAA 1-2-3 rule radius for reference:
 * 24 hour: 100 nm
 * 48 hour: 200 nm
 * 72 hour: 300 nm
 */
const EXTRA_RADIUS_123_RULE_NAUTICAL_MILE_PER_HOUR_FACTOR = 4.166667;

export const findWindRadiiBySpeed = (
  windRadii: WindRadii[] | null | undefined,
  windSpeedKts: number
) => {
  if (windRadii && windRadii.length > 0) {
    const foundWindRadii = windRadii.find(
      (radii) => radii.windSpeedKTS === windSpeedKts
    );
    return foundWindRadii;
  }

  return null;
};

export const getMax34Knot = (windRadii: WindRadii[] | null): number | null => {
  const foundWindRadii = findWindRadiiBySpeed(windRadii, WIND_SPEED_34_KNOT);
  if (foundWindRadii) {
    const distances = [
      foundWindRadii.quadrants.nw.distanceNM,
      foundWindRadii.quadrants.se.distanceNM,
      foundWindRadii.quadrants.sw.distanceNM,
      foundWindRadii.quadrants.ne.distanceNM
    ];
    const maxDistance = Math.max(...distances);
    return maxDistance;
  }
  return null;
};

export const createCyclonePointData = (
  foundForecastPoint: ForecastPoint | undefined,
  hourIncrement: ForecastHourIncrement
): CyclonePointData | undefined => {
  if (foundForecastPoint) {
    const forecastHourDateTime = DateTime.fromISO(
      foundForecastPoint.dateTimeISO
    );

    const forecastPoint = point([
      foundForecastPoint.loc.long,
      foundForecastPoint.loc.lat
    ]);

    const forecastRadius34KtMaxNM = getMax34Knot(
      foundForecastPoint.details.windRadii
    );

    return {
      dateTime: forecastHourDateTime,
      point: forecastPoint,
      radius34KtMaxNM: forecastRadius34KtMaxNM,
      windRadii: foundForecastPoint.details.windRadii,
      forecastHourIncrement: hourIncrement
    };
  }
};

export const getForecastPointData = (
  currentCycloneDateTime: DateTime,
  forecastPoints: ForecastPoint[] | null | undefined,
  hourIncrement: ForecastHourIncrement
): CyclonePointData => {
  const result: CyclonePointData = {
    dateTime: null,
    point: null,
    radius34KtMaxNM: null,
    windRadii: null,
    forecastHourIncrement: 0
  };

  if (forecastPoints) {
    const dateTimeFuture = currentCycloneDateTime.plus({
      hours: hourIncrement
    });

    const foundForecastPoint = forecastPoints.find((forecastPoint) =>
      DateTime.fromISO(forecastPoint.dateTimeISO).equals(dateTimeFuture)
    );

    const cyclonePointData = createCyclonePointData(
      foundForecastPoint,
      hourIncrement
    );

    if (cyclonePointData) {
      return cyclonePointData;
    }
  }

  return result;
};

export const createCustomForecastPoint = (
  currentCycloneDateTime: DateTime,
  foundForecastPoint: ForecastPoint | undefined
): CyclonePointData | undefined => {
  if (foundForecastPoint) {
    const forecastHourIncrement = (DateTime.fromISO(
      foundForecastPoint.dateTimeISO
    )
      .diff(currentCycloneDateTime, 'hours')
      .toObject().hours ?? 0) as ForecastHourIncrement;
    return createCyclonePointData(foundForecastPoint, forecastHourIncrement);
  }
};

export const createCircle = (
  center: Coord,
  radius: number
): Feature<LineString, Properties> => {
  return lineArc(center, radius, 0, 360, {
    units: 'nauticalmiles',
    steps: 128
  });
};

export const findForecastPoint = (
  forecastPoints: ForecastPoint[] | null | undefined,
  occurrence: number
): ForecastPoint | undefined => {
  let result;
  if (
    forecastPoints &&
    occurrence >= 1 &&
    forecastPoints.length >= occurrence
  ) {
    result = forecastPoints[occurrence - 1];
  }
  return result;
};

export const createMax34KnotCircles = (
  currentCycloneTrackPoint: TrackPoint,
  forecastPoints: ForecastPoint[] | null | undefined
): Max34KnotCircles => {
  const result: Max34KnotCircles = {
    currentCyclonePointData: null,
    current34KtMaxCircleArc: null,
    firstForecastPointData: null,
    firstForecastCircleArc: null,
    secondForecastPointData: null,
    secondForecastCircleArc: null,
    thirdForecastPointData: null,
    thirdForecastCircleArc: null
  };

  const currentCycloneDateTime = DateTime.fromISO(
    currentCycloneTrackPoint.dateTimeISO
  );
  const currentCyclonePoint = point([
    currentCycloneTrackPoint.loc.long,
    currentCycloneTrackPoint.loc.lat
  ]);
  const currentRadius34KtMax = getMax34Knot(
    currentCycloneTrackPoint.details.windRadii
  );

  result.currentCyclonePointData = {
    dateTime: currentCycloneDateTime,
    point: currentCyclonePoint,
    radius34KtMaxNM: currentRadius34KtMax,
    windRadii: currentCycloneTrackPoint.details.windRadii,
    forecastHourIncrement: 0
  };

  //Find traditional 1-2-3 rule 24 hour increment forecasts
  result.firstForecastPointData = getForecastPointData(
    currentCycloneDateTime,
    forecastPoints,
    24
  );
  result.secondForecastPointData = getForecastPointData(
    currentCycloneDateTime,
    forecastPoints,
    48
  );
  result.thirdForecastPointData = getForecastPointData(
    currentCycloneDateTime,
    forecastPoints,
    72
  );
  /**
   * Find modified 1-2-3 rule 12 hour increment forecasts
   */
  if (
    result.firstForecastPointData.dateTime === null ||
    result.secondForecastPointData.dateTime === null ||
    result.thirdForecastPointData.dateTime === null
  ) {
    result.firstForecastPointData = getForecastPointData(
      currentCycloneDateTime,
      forecastPoints,
      12
    );
    result.secondForecastPointData = getForecastPointData(
      currentCycloneDateTime,
      forecastPoints,
      24
    );
    result.thirdForecastPointData = getForecastPointData(
      currentCycloneDateTime,
      forecastPoints,
      36
    );
  }
  /**
   * If we couldn't find 24 or 12 hour forecasts then
   * take a look for 6 hour forecasts
   */
  if (
    result.firstForecastPointData.dateTime === null &&
    result.secondForecastPointData.dateTime === null &&
    result.thirdForecastPointData.dateTime === null
  ) {
    result.firstForecastPointData = getForecastPointData(
      currentCycloneDateTime,
      forecastPoints,
      6
    );
    result.secondForecastPointData = getForecastPointData(
      currentCycloneDateTime,
      forecastPoints,
      12
    );
    result.thirdForecastPointData = getForecastPointData(
      currentCycloneDateTime,
      forecastPoints,
      18
    );
  }
  /**
   * If no forecasts can be found at this point then
   * do some fuzzy logic
   */
  if (
    result.firstForecastPointData.dateTime === null &&
    result.secondForecastPointData.dateTime === null &&
    result.thirdForecastPointData.dateTime === null
  ) {
    const firstPoint = findForecastPoint(forecastPoints, 1);
    const secondPoint = findForecastPoint(forecastPoints, 2);
    const thirdPoint = findForecastPoint(forecastPoints, 3);

    const customFirstPoint = createCustomForecastPoint(
      currentCycloneDateTime,
      firstPoint
    );
    const customSecondPoint = createCustomForecastPoint(
      currentCycloneDateTime,
      secondPoint
    );
    const customThirdPoint = createCustomForecastPoint(
      currentCycloneDateTime,
      thirdPoint
    );

    if (customFirstPoint) {
      result.firstForecastPointData = customFirstPoint;
    }
    if (customSecondPoint) {
      result.secondForecastPointData = customSecondPoint;
    }
    if (customThirdPoint) {
      result.thirdForecastPointData = customThirdPoint;
    }
  }

  // Create 34 knot circle arcs
  if (currentRadius34KtMax) {
    result.current34KtMaxCircleArc = createCircle(
      currentCyclonePoint,
      currentRadius34KtMax
    );
  }

  if (
    result.firstForecastPointData.point &&
    result.firstForecastPointData.radius34KtMaxNM
  ) {
    result.firstForecastCircleArc = createCircle(
      result.firstForecastPointData.point,
      result.firstForecastPointData.radius34KtMaxNM
    );
  }

  if (
    result.secondForecastPointData.point &&
    result.secondForecastPointData.radius34KtMaxNM
  ) {
    result.secondForecastCircleArc = createCircle(
      result.secondForecastPointData.point,
      result.secondForecastPointData.radius34KtMaxNM
    );
  }

  if (
    result.thirdForecastPointData.point &&
    result.thirdForecastPointData.radius34KtMaxNM
  ) {
    result.thirdForecastCircleArc = createCircle(
      result.thirdForecastPointData.point,
      result.thirdForecastPointData.radius34KtMaxNM
    );
  }

  return result;
};

export const createOuterCircle = (
  cyclonePointData: CyclonePointData | null,
  extra123RuleRadius: number
) => {
  if (
    cyclonePointData &&
    cyclonePointData.point &&
    cyclonePointData.radius34KtMaxNM
  ) {
    const radius = cyclonePointData.radius34KtMaxNM + extra123RuleRadius;
    const circle = createCircle(cyclonePointData.point, radius);
    return { radius, circle };
  }

  return null;
};

export const createPolygonFromCoords = (coords: Position[]) => {
  const currentLineString = lineStrings([coords]);
  return lineToPolygon(currentLineString);
};

export const createMax34KnotCirclePolygons = (
  max34KnotCircles: Max34KnotCircles
) => {
  const results = [];

  if (max34KnotCircles.current34KtMaxCircleArc) {
    results.push(
      createPolygonFromCoords(
        max34KnotCircles.current34KtMaxCircleArc.geometry.coordinates
      )
    );
  }
  if (max34KnotCircles.firstForecastCircleArc) {
    results.push(
      createPolygonFromCoords(
        max34KnotCircles.firstForecastCircleArc.geometry.coordinates
      )
    );
  }
  if (max34KnotCircles.secondForecastCircleArc) {
    results.push(
      createPolygonFromCoords(
        max34KnotCircles.secondForecastCircleArc.geometry.coordinates
      )
    );
  }
  if (max34KnotCircles.thirdForecastCircleArc) {
    results.push(
      createPolygonFromCoords(
        max34KnotCircles.thirdForecastCircleArc.geometry.coordinates
      )
    );
  }

  return results;
};

export const determineExtraRadius = (
  cyclonePoint: CyclonePointData | null
): number => {
  let result = 0;

  if (cyclonePoint) {
    result = Math.floor(
      cyclonePoint.forecastHourIncrement *
        EXTRA_RADIUS_123_RULE_NAUTICAL_MILE_PER_HOUR_FACTOR
    );
  }

  return result;
};

export const createMaxOuter34KnotCircles = (
  max34KnotCircles: Max34KnotCircles
): MaxOuter34KnotCircles => {
  const result: MaxOuter34KnotCircles = {
    firstForecastCircleRadius: null,
    firstForecastCircleArc: null,
    secondForecastCircleRadius: null,
    secondForecastCircleArc: null,
    thirdForecastCircleRadius: null,
    thirdForecastCircleArc: null
  };

  const firstForecastMaxOuter34KnotRadius = determineExtraRadius(
    max34KnotCircles.firstForecastPointData
  );
  const firstResult = createOuterCircle(
    max34KnotCircles.firstForecastPointData,
    firstForecastMaxOuter34KnotRadius
  );
  if (firstResult) {
    result.firstForecastCircleRadius = firstResult.radius;
    result.firstForecastCircleArc = firstResult.circle;
  }

  const secondForecastMaxOuter34KnotRadius = determineExtraRadius(
    max34KnotCircles.secondForecastPointData
  );
  const secondResult = createOuterCircle(
    max34KnotCircles.secondForecastPointData,
    secondForecastMaxOuter34KnotRadius
  );
  if (secondResult) {
    result.secondForecastCircleRadius = secondResult.radius;
    result.secondForecastCircleArc = secondResult.circle;
  }

  const thirdForecastMaxOuter34KnotRadius = determineExtraRadius(
    max34KnotCircles.thirdForecastPointData
  );
  const thirdResult = createOuterCircle(
    max34KnotCircles.thirdForecastPointData,
    thirdForecastMaxOuter34KnotRadius
  );
  if (thirdResult) {
    result.thirdForecastCircleRadius = thirdResult.radius;
    result.thirdForecastCircleArc = thirdResult.circle;
  }

  return result;
};

export const determineCenterPointAndInnerCircle = (
  firstCircleRadius: number | null,
  firstCircleCenterPoint: Feature<Point, Properties> | null | undefined,
  secondCircleRadius: number | null,
  secondCircleCenterPoint: Feature<Point, Properties> | null | undefined
) => {
  let centerPoint: Feature<Point, Properties> | null = null;
  let centerPointCircleRadius: number | null = null;
  let innerCircle: Feature<LineString, Properties> | null = null;

  if (
    firstCircleRadius &&
    secondCircleRadius &&
    firstCircleCenterPoint &&
    secondCircleCenterPoint
  ) {
    if (firstCircleRadius <= secondCircleRadius) {
      centerPoint = firstCircleCenterPoint;
      centerPointCircleRadius = firstCircleRadius;
      const innerCircleRadius = secondCircleRadius - firstCircleRadius;
      innerCircle = createCircle(secondCircleCenterPoint, innerCircleRadius);
    } else {
      centerPoint = secondCircleCenterPoint;
      centerPointCircleRadius = secondCircleRadius;
      const innerCircleRadius = firstCircleRadius - secondCircleRadius;
      innerCircle = createCircle(firstCircleCenterPoint, innerCircleRadius);
    }
  }

  return { centerPoint, centerPointCircleRadius, innerCircle };
};

/**
 * Each center point / inner circle arc will act as a pair
 * to calculate tangent lines. The firstCenterPoint will be
 * the center point of the opposing circle compared to the
 * firstInnerCircleArc.
 *
 * In other words, if the current cyclone position max 34
 * knot circle's radius is smaller than the first forecast
 * position max 34 knot outer circle's radius, then the
 * firstCenterPoint would be the center point of the
 * current cyclone position circle, and the
 * firstInnerCircleArc would be a circle centered on
 * the first forecast position with radius equal to
 * the first forecast max 34 knot outer circle radius
 * minus the current cyclone max 34 knot circle radius.
 *
 * However, if the current cyclone position max 34 knot
 * circle's radius is larger than the first forecast
 * position max 34 knot outer circle's radius, then the
 * firstCenterPoint would be the center point of the
 * first forecast position, and the firstInnerCircleArc
 * would be a circle centered on the current cyclone
 * position with radius equal to the current cyclone
 * max 34 knot circle radius minus the first forecast
 * max 34 knot outer circle radius.
 */
export const createMaxInner34KnotCircles = (
  max34KnotCircles: Max34KnotCircles,
  maxOuter34KnotCircles: MaxOuter34KnotCircles
): MaxInner34KnotCircles => {
  const result: MaxInner34KnotCircles = {
    firstCenterPoint: null,
    firstCenterPointCircleRadius: null,
    firstInnerCircleArc: null,
    secondCenterPoint: null,
    secondCenterPointCircleRadius: null,
    secondInnerCircleArc: null,
    thirdCenterPoint: null,
    thirdCenterPointCircleRadius: null,
    thirdInnerCircleArc: null
  };

  if (
    max34KnotCircles.currentCyclonePointData &&
    max34KnotCircles.firstForecastPointData
  ) {
    const { centerPoint, centerPointCircleRadius, innerCircle } =
      determineCenterPointAndInnerCircle(
        max34KnotCircles.currentCyclonePointData.radius34KtMaxNM,
        max34KnotCircles.currentCyclonePointData.point,
        maxOuter34KnotCircles.firstForecastCircleRadius,
        max34KnotCircles.firstForecastPointData.point
      );
    result.firstCenterPoint = centerPoint;
    result.firstCenterPointCircleRadius = centerPointCircleRadius;
    result.firstInnerCircleArc = innerCircle;
  }

  if (
    max34KnotCircles.firstForecastPointData &&
    max34KnotCircles.secondForecastPointData
  ) {
    const { centerPoint, centerPointCircleRadius, innerCircle } =
      determineCenterPointAndInnerCircle(
        maxOuter34KnotCircles.firstForecastCircleRadius,
        max34KnotCircles.firstForecastPointData.point,
        maxOuter34KnotCircles.secondForecastCircleRadius,
        max34KnotCircles.secondForecastPointData.point
      );
    result.secondCenterPoint = centerPoint;
    result.secondCenterPointCircleRadius = centerPointCircleRadius;
    result.secondInnerCircleArc = innerCircle;
  }

  if (
    max34KnotCircles.secondForecastPointData &&
    max34KnotCircles.thirdForecastPointData
  ) {
    const { centerPoint, centerPointCircleRadius, innerCircle } =
      determineCenterPointAndInnerCircle(
        maxOuter34KnotCircles.secondForecastCircleRadius,
        max34KnotCircles.secondForecastPointData.point,
        maxOuter34KnotCircles.thirdForecastCircleRadius,
        max34KnotCircles.thirdForecastPointData.point
      );
    result.thirdCenterPoint = centerPoint;
    result.thirdCenterPointCircleRadius = centerPointCircleRadius;
    result.thirdInnerCircleArc = innerCircle;
  }

  return result;
};

export const calcExternalCircleTangents = (
  initialPoint: Coord,
  initialCircleRadius: number,
  tangentPoints: Coord[]
): TangentLines => {
  const x1y1 = initialPoint;
  const x2y2 = tangentPoints[0];
  const x3y3 = tangentPoints[1];
  let bear1 = bearing(x1y1, x2y2);
  let bear2 = bearing(x1y1, x3y3);
  const bear1Degrees = bearingToAzimuth(bear1);
  const bear2Degrees = bearingToAzimuth(bear2);
  if (
    (Math.abs(bear1 - bear2) <= 180 &&
      bear1Degrees <= 180 &&
      bear2Degrees >= 180) ||
    bear1Degrees > bear2Degrees
  ) {
    bear1 += 90;
    bear2 -= 90;
  } else {
    bear1 -= 90;
    bear2 += 90;
  }

  const dest1 = destination(x1y1, initialCircleRadius, bear1, {
    units: 'nauticalmiles'
  });
  const dest2 = destination(x2y2, initialCircleRadius, bear1, {
    units: 'nauticalmiles'
  });

  const dest3 = destination(x1y1, initialCircleRadius, bear2, {
    units: 'nauticalmiles'
  });
  const dest4 = destination(x3y3, initialCircleRadius, bear2, {
    units: 'nauticalmiles'
  });

  return {
    line1: { origin: dest1, destination: dest2 },
    line2: { origin: dest3, destination: dest4 }
  };
};

const createTangentLinePoints = (
  centerPoint: Feature<Point, Properties> | null,
  centerPointCircleRadius: number | null,
  innerCircleArc: Feature<LineString, Properties> | null
): TangentLines | null => {
  if (centerPoint && centerPointCircleRadius && innerCircleArc) {
    const circlePolygon = <Feature<Polygon, Properties>>(
      lineToPolygon(innerCircleArc)
    );

    const tangents = polygonTangents(centerPoint, circlePolygon);

    return calcExternalCircleTangents(
      centerPoint,
      centerPointCircleRadius,
      tangents.features.map((feat) => feat.geometry.coordinates)
    );
  }

  return null;
};

export const createMaxOuter34KnotTangents = (
  maxInner34KnotCircles: MaxInner34KnotCircles
): Max34KnotTangents => {
  const firstCirclePairPoints = createTangentLinePoints(
    maxInner34KnotCircles.firstCenterPoint,
    maxInner34KnotCircles.firstCenterPointCircleRadius,
    maxInner34KnotCircles.firstInnerCircleArc
  );
  const secondCirclePairPoints = createTangentLinePoints(
    maxInner34KnotCircles.secondCenterPoint,
    maxInner34KnotCircles.secondCenterPointCircleRadius,
    maxInner34KnotCircles.secondInnerCircleArc
  );
  const thirdCirclePairPoints = createTangentLinePoints(
    maxInner34KnotCircles.thirdCenterPoint,
    maxInner34KnotCircles.thirdCenterPointCircleRadius,
    maxInner34KnotCircles.thirdInnerCircleArc
  );

  return {
    firstCirclePairPoints,
    secondCirclePairPoints,
    thirdCirclePairPoints
  };
};

const createPolygonsFromLineStrings = (
  lineStrings: Array<Feature<LineString, Properties> | null>
): Feature<Polygon, Properties>[] => {
  const results: Feature<Polygon, Properties>[] = [];

  lineStrings.forEach((lineString) => {
    if (lineString) {
      results.push(<Feature<Polygon, Properties>>lineToPolygon(lineString));
    }
  });

  return results;
};

const createPolygonFromTangents = (
  tangents: TangentLines | null
): Feature<Polygon, Properties> | null => {
  if (tangents) {
    const tangentPositions: Position[][] = [];
    const tempPositions: Position[] = [];
    tempPositions.push(tangents.line1.origin.geometry.coordinates);
    tempPositions.push(tangents.line1.destination.geometry.coordinates);
    tempPositions.push(tangents.line1.destination.geometry.coordinates);
    tempPositions.push(tangents.line2.destination.geometry.coordinates);
    tempPositions.push(tangents.line2.destination.geometry.coordinates);
    tempPositions.push(tangents.line2.origin.geometry.coordinates);
    tempPositions.push(tangents.line2.origin.geometry.coordinates);
    tempPositions.push(tangents.line1.origin.geometry.coordinates);

    tangentPositions.push(tempPositions);

    const tangentLines = lineStrings(tangentPositions);
    return <Feature<Polygon, Properties>>lineToPolygon(tangentLines);
  }

  return null;
};

const createPolygonsFromTangents = (
  max34KnotTangents: Max34KnotTangents
): Array<Feature<Polygon, Properties> | null> => {
  return [
    createPolygonFromTangents(max34KnotTangents.firstCirclePairPoints),
    createPolygonFromTangents(max34KnotTangents.secondCirclePairPoints),
    createPolygonFromTangents(max34KnotTangents.thirdCirclePairPoints)
  ];
};

export const createMaxOuter34KnotPolygons = (
  max34KnotCircles: Max34KnotCircles,
  maxOuter34KnotCircles: MaxOuter34KnotCircles,
  max34KnotTangents: Max34KnotTangents
): Array<Feature<Polygon, Properties> | null> => {
  const circlePolygons = createPolygonsFromLineStrings([
    max34KnotCircles.current34KtMaxCircleArc,
    maxOuter34KnotCircles.firstForecastCircleArc,
    maxOuter34KnotCircles.secondForecastCircleArc,
    maxOuter34KnotCircles.thirdForecastCircleArc
  ]);

  const tangentPolygons = createPolygonsFromTangents(max34KnotTangents);

  return [...circlePolygons, ...tangentPolygons];
};

export const createUnionPolygon = (
  max34KnotPolygons: Array<Feature<Polygon, Properties> | null>
): Feature<Polygon | MultiPolygon, Properties> | null => {
  const polysToCombine: Array<Feature<Polygon, Properties>> = [];
  max34KnotPolygons.forEach((poly) => {
    if (poly) {
      polysToCombine.push(poly);
    }
  });

  let unionPoly = null;
  for (let i = 1; i < polysToCombine.length; i++) {
    if (i === 1) {
      unionPoly = union(polysToCombine[i - 1], polysToCombine[i]);
    } else if (unionPoly) {
      unionPoly = union(unionPoly, polysToCombine[i]);
    }
  }

  return unionPoly;
};

const getWindRadiiToProcess = (
  max34KnotCircles: Max34KnotCircles
): WindRadiiHolder[] => {
  return [
    {
      cyclonePointData: max34KnotCircles.currentCyclonePointData,
      windRadii: max34KnotCircles.currentCyclonePointData?.windRadii
    },
    {
      cyclonePointData: max34KnotCircles.firstForecastPointData,
      windRadii: max34KnotCircles.firstForecastPointData?.windRadii
    },
    {
      cyclonePointData: max34KnotCircles.secondForecastPointData,
      windRadii: max34KnotCircles.secondForecastPointData?.windRadii
    },
    {
      cyclonePointData: max34KnotCircles.thirdForecastPointData,
      windRadii: max34KnotCircles.thirdForecastPointData?.windRadii
    }
  ];
};

const createQuadrantArc = (
  radius: number | undefined,
  windRadiiHolder: WindRadiiHolder,
  bearing1: number,
  bearing2: number
): Feature<LineString, Properties> | null => {
  if (
    radius &&
    windRadiiHolder.cyclonePointData &&
    windRadiiHolder.cyclonePointData.point
  ) {
    return lineArc(
      windRadiiHolder.cyclonePointData.point,
      radius,
      bearing1,
      bearing2,
      {
        units: 'nauticalmiles',
        steps: 128
      }
    );
  }

  return null;
};

const createConnectorLine = (
  arc1: Feature<LineString, Properties> | null,
  arc2: Feature<LineString, Properties> | null
): Feature<LineString, Properties> | null => {
  if (arc1 && arc2) {
    return lineString([
      arc1.geometry.coordinates[arc1.geometry.coordinates.length - 1],
      arc2.geometry.coordinates[0]
    ]);
  }

  return null;
};

const findAndCreatePolygonFromWindQuadrants = (
  windRadiiHolder: WindRadiiHolder,
  windSpeedKts: number,
  polygonResults: Feature<Polygon, Properties>[],
  lineStringResults: Feature<LineString, Properties>[]
) => {
  const foundWindRadii = findWindRadiiBySpeed(
    windRadiiHolder.windRadii,
    windSpeedKts
  );

  if (foundWindRadii) {
    const nwRadiusNM = foundWindRadii.quadrants.nw.distanceNM;
    const neRadiusNM = foundWindRadii.quadrants.ne.distanceNM;
    const seRadiusNM = foundWindRadii.quadrants.se.distanceNM;
    const swRadiusNM = foundWindRadii.quadrants.sw.distanceNM;

    const nwArc = createQuadrantArc(nwRadiusNM, windRadiiHolder, 270, 360);
    const neArc = createQuadrantArc(neRadiusNM, windRadiiHolder, 0, 90);
    const seArc = createQuadrantArc(seRadiusNM, windRadiiHolder, 90, 180);
    const swArc = createQuadrantArc(swRadiusNM, windRadiiHolder, 180, 270);

    const nwToNeArc = createConnectorLine(nwArc, neArc);
    const neToSeArc = createConnectorLine(neArc, seArc);
    const seToSwArc = createConnectorLine(seArc, swArc);
    const swToNwArc = createConnectorLine(swArc, nwArc);

    if (
      nwArc &&
      nwToNeArc &&
      neArc &&
      neToSeArc &&
      seArc &&
      seToSwArc &&
      swArc &&
      swToNwArc
    ) {
      const tempPositions: Position[] = [];
      tempPositions.push(...nwArc.geometry.coordinates);
      tempPositions.push(...nwToNeArc.geometry.coordinates);
      tempPositions.push(...neArc.geometry.coordinates);
      tempPositions.push(...neToSeArc.geometry.coordinates);
      tempPositions.push(...seArc.geometry.coordinates);
      tempPositions.push(...seToSwArc.geometry.coordinates);
      tempPositions.push(...swArc.geometry.coordinates);
      tempPositions.push(...swToNwArc.geometry.coordinates);

      const quadrantLines = lineString(tempPositions);
      lineStringResults.push(quadrantLines);
      polygonResults.push(
        <Feature<Polygon, Properties>>(
          lineToPolygon(quadrantLines, { orderCoords: false })
        )
      );
    }
  }
};

export const addWindQuadrantPolygons = (
  max34KnotCircles: Max34KnotCircles,
  windSpeedKts: number
) => {
  const polygonResults: Feature<Polygon, Properties>[] = [];
  const lineStringResults: Feature<LineString, Properties>[] = [];

  const windRadiiToProcess: WindRadiiHolder[] =
    getWindRadiiToProcess(max34KnotCircles);

  windRadiiToProcess.forEach((windRadiiHolder) => {
    findAndCreatePolygonFromWindQuadrants(
      windRadiiHolder,
      windSpeedKts,
      polygonResults,
      lineStringResults
    );
  });

  return { polygonResults, lineStringResults };
};

export const createOuter34KtData = (
  hoursFromCurrent: ForecastHourIncrement | null | undefined,
  cyclonePointData: CyclonePointData | null,
  outer34KtRadiusNM: number | null | undefined
): Outer34KtData | null => {
  if (
    !isNil(hoursFromCurrent) &&
    hoursFromCurrent >= 0 &&
    cyclonePointData &&
    cyclonePointData.point &&
    cyclonePointData.dateTime &&
    !isNil(outer34KtRadiusNM) &&
    outer34KtRadiusNM >= 0
  ) {
    return {
      hoursFromCurrent,
      longitude: cyclonePointData.point.geometry.coordinates[0],
      latitude: cyclonePointData.point.geometry.coordinates[1],
      dateTime: cyclonePointData.dateTime,
      outer34KtRadiusNM
    };
  }

  return null;
};

export const createAllOuter34KtData = (
  max34KnotCircles: Max34KnotCircles,
  maxOuter34KnotCircles: MaxOuter34KnotCircles
): Outer34KtData[] => {
  const results: Outer34KtData[] = [];

  const currentOuter34KtData = createOuter34KtData(
    0,
    max34KnotCircles.currentCyclonePointData,
    max34KnotCircles.currentCyclonePointData?.radius34KtMaxNM
  );
  if (currentOuter34KtData) {
    results.push(currentOuter34KtData);
  }

  const firstForecastOuter34KtData = createOuter34KtData(
    max34KnotCircles.firstForecastPointData?.forecastHourIncrement,
    max34KnotCircles.firstForecastPointData,
    maxOuter34KnotCircles.firstForecastCircleRadius
  );
  if (firstForecastOuter34KtData) {
    results.push(firstForecastOuter34KtData);
  }

  const secondForecastOuter34KtData = createOuter34KtData(
    max34KnotCircles.secondForecastPointData?.forecastHourIncrement,
    max34KnotCircles.secondForecastPointData,
    maxOuter34KnotCircles.secondForecastCircleRadius
  );
  if (secondForecastOuter34KtData) {
    results.push(secondForecastOuter34KtData);
  }

  const thirdForecastOuter34KtData = createOuter34KtData(
    max34KnotCircles.thirdForecastPointData?.forecastHourIncrement,
    max34KnotCircles.thirdForecastPointData,
    maxOuter34KnotCircles.thirdForecastCircleRadius
  );
  if (thirdForecastOuter34KtData) {
    results.push(thirdForecastOuter34KtData);
  }

  return results;
};
