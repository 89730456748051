import { useMemo } from 'react';
import {
  Feature,
  FeatureCollection,
  Geometry,
  GeoJsonProperties
} from 'geojson';
import { Polygon, Properties } from '@turf/turf';
import { CycloneData, TrackPoint } from '../../../../models/Cyclone';
import {
  createMax34KnotCircles,
  Max34KnotCircles,
  createMaxOuter34KnotCircles,
  createMaxInner34KnotCircles,
  MaxOuter34KnotCircles,
  MaxInner34KnotCircles,
  createMaxOuter34KnotTangents,
  Max34KnotTangents,
  createMaxOuter34KnotPolygons,
  createUnionPolygon,
  WIND_SPEED_34_KNOT,
  WIND_SPEED_50_KNOT,
  WIND_SPEED_64_KNOT,
  addWindQuadrantPolygons,
  createAllOuter34KtData,
  ForecastHourIncrement
} from '../../../../utils/windFieldUtils';
import { Layer, MapRef, Source } from 'react-map-gl';
import { Vessel } from '../../../../models/Vessel';
import CycloneIntersectionLayer from './CycloneIntersectionLayer';
import { DateTime } from 'luxon';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

const windQuadrantColors = {
  knot34Poly: '#9C70FF',
  knot50Poly: '#FFCC00',
  knot64Poly: '#00A300',
  knot34Border: '#FFFFFF',
  knot50Border: '#FFFFFF',
  knot64Border: '#FFFFFF',
  zoomStep1: 0,
  zoomStep2: 10,
  borderWidthStep1: 4,
  borderWidthStep2: 2
};

export class MapFeatureCollection
  implements FeatureCollection<Geometry, GeoJsonProperties>
{
  type = 'FeatureCollection' as const;
  features: Array<Feature>;

  constructor() {
    this.features = [];
  }
}

export interface Outer34KtData {
  hoursFromCurrent: ForecastHourIncrement;
  longitude: number;
  latitude: number;
  dateTime: DateTime;
  outer34KtRadiusNM: number;
}

export interface CycloneOuter34KtData {
  cycloneShortName: string;
  cycloneOuter34KtData: Outer34KtData[];
}

export class Noaa123RulePolygonData {
  windQuadrantPolygons34Kt: MapFeatureCollection;
  windQuadrantPolygons50Kt: MapFeatureCollection;
  windQuadrantPolygons64Kt: MapFeatureCollection;
  windQuadrantLines34Kt: MapFeatureCollection;
  windQuadrantLines50Kt: MapFeatureCollection;
  windQuadrantLines64Kt: MapFeatureCollection;
  noaa123RulePolygon: MapFeatureCollection;
  cycloneOuter34KtData: CycloneOuter34KtData[];

  constructor() {
    this.windQuadrantPolygons34Kt = new MapFeatureCollection();
    this.windQuadrantPolygons50Kt = new MapFeatureCollection();
    this.windQuadrantPolygons64Kt = new MapFeatureCollection();
    this.windQuadrantLines34Kt = new MapFeatureCollection();
    this.windQuadrantLines50Kt = new MapFeatureCollection();
    this.windQuadrantLines64Kt = new MapFeatureCollection();
    this.noaa123RulePolygon = new MapFeatureCollection();
    this.cycloneOuter34KtData = [];
  }
}

interface WindFieldLayerProps {
  map: MapRef | undefined;
  selectedLayerName: string;
  symbolColor: string;
  paint:
    | {
        'text-color': string;
        'text-halo-color'?: undefined;
        'text-halo-width'?: undefined;
      }
    | {
        'text-color': string;
        'text-halo-color': string;
        'text-halo-width': number;
      };
  beforeLayerId: string;
  cycloneData: CycloneData | null | undefined;
  vessels: Vessel[];
  windFieldOpacity: number;
}

const WindFieldLayer = ({
  map,
  selectedLayerName,
  symbolColor,
  paint,
  beforeLayerId,
  cycloneData,
  vessels,
  windFieldOpacity
}: WindFieldLayerProps) => {
  const windFieldOpacityPercent = useMemo(
    () => windFieldOpacity / 100,
    [windFieldOpacity]
  );

  const noaa123RulePolygonData: Noaa123RulePolygonData = useMemo(() => {
    const result = new Noaa123RulePolygonData();

    cycloneData?.CycloneData?.forEach((cyclone) => {
      // Find current cyclone info
      const cycloneTrackPoint: TrackPoint | undefined =
        cyclone?.track[cyclone.track.length - 1];

      if (cycloneTrackPoint) {
        /**
         * Create 34 knot circles for current cyclone position and 24/48/72 or 12/24/48 hour forecasts
         */
        const max34KnotCircles: Max34KnotCircles = createMax34KnotCircles(
          cycloneTrackPoint,
          cyclone?.forecast
        );
        /**
         * Create 1-2-3 rule outer circle arcs based on 34 knot circles
         */
        const maxOuter34KnotCircles: MaxOuter34KnotCircles =
          createMaxOuter34KnotCircles(max34KnotCircles);
        /**
         * Retain outer 34 knot circle metadata to be used later for
         * vessel and cyclone intersection calculations
         */
        result.cycloneOuter34KtData.push({
          cycloneShortName: cyclone.position.details.stormShortName,
          cycloneOuter34KtData: createAllOuter34KtData(
            max34KnotCircles,
            maxOuter34KnotCircles
          )
        });
        /**
         * Create 1-2-3 rule inner circle arcs based on outer circles
         */
        const maxInner34KnotCircles: MaxInner34KnotCircles =
          createMaxInner34KnotCircles(max34KnotCircles, maxOuter34KnotCircles);
        /**
         * Calculate tangents between
         * current(34 knot circle)-12/24(outer circle),
         * 12/24(outer circle)-24/48(outer circle),
         * 24/48(outer circle)-36/72(outer circle)
         */
        const max34KnotTangents: Max34KnotTangents =
          createMaxOuter34KnotTangents(maxInner34KnotCircles);
        /**
         * Create polygons between current-tangents-12/24-tangents-24/48-tangents-36/72
         */
        const max34KnotPolygons: Array<Feature<Polygon, Properties> | null> =
          createMaxOuter34KnotPolygons(
            max34KnotCircles,
            maxOuter34KnotCircles,
            max34KnotTangents
          );
        /**
         * Union all polygons into single polygon
         */
        const unionPolygon = createUnionPolygon(max34KnotPolygons);

        if (unionPolygon) {
          result.noaa123RulePolygon.features.push(unionPolygon);
        }
        /**
         * Add 34, 50, 64 knot wind quadrant polygons if available
         */
        const result34 = addWindQuadrantPolygons(
          max34KnotCircles,
          WIND_SPEED_34_KNOT
        );
        result.windQuadrantPolygons34Kt.features.push(
          ...result34.polygonResults
        );
        result.windQuadrantLines34Kt.features.push(
          ...result34.lineStringResults
        );

        const result50 = addWindQuadrantPolygons(
          max34KnotCircles,
          WIND_SPEED_50_KNOT
        );
        result.windQuadrantPolygons50Kt.features.push(
          ...result50.polygonResults
        );
        result.windQuadrantLines50Kt.features.push(
          ...result50.lineStringResults
        );

        const result64 = addWindQuadrantPolygons(
          max34KnotCircles,
          WIND_SPEED_64_KNOT
        );
        result.windQuadrantPolygons64Kt.features.push(
          ...result64.polygonResults
        );
        result.windQuadrantLines64Kt.features.push(
          ...result64.lineStringResults
        );
      }
    });

    return result;
  }, [cycloneData]);

  return (
    <>
      <Source
        id={MapSourceId.Noaa123RulePolygons}
        type="geojson"
        data={noaa123RulePolygonData.noaa123RulePolygon}
      >
        <Layer
          id={MapLayerId.Noaa123RulePolygons}
          beforeId={beforeLayerId}
          type="fill"
          paint={{
            'fill-color': '#AB0004',
            'fill-opacity': windFieldOpacityPercent / 2
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.WindQuadrant34KtPolygons}
        type="geojson"
        data={noaa123RulePolygonData.windQuadrantPolygons34Kt}
      >
        <Layer
          id={MapLayerId.WindQuadrant34KtPolygons}
          beforeId={beforeLayerId}
          type="fill"
          paint={{
            'fill-color': windQuadrantColors.knot34Poly,
            'fill-opacity': windFieldOpacityPercent
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.WindQuadrant50KtPolygons}
        type="geojson"
        data={noaa123RulePolygonData.windQuadrantPolygons50Kt}
      >
        <Layer
          id={MapLayerId.WindQuadrant50KtPolygons}
          beforeId={beforeLayerId}
          type="fill"
          paint={{
            'fill-color': windQuadrantColors.knot50Poly,
            'fill-opacity': windFieldOpacityPercent
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.WindQuadrant64KtPolygons}
        type="geojson"
        data={noaa123RulePolygonData.windQuadrantPolygons64Kt}
      >
        <Layer
          id={MapLayerId.WindQuadrant64KtPolygons}
          beforeId={beforeLayerId}
          type="fill"
          paint={{
            'fill-color': windQuadrantColors.knot64Poly,
            'fill-opacity': windFieldOpacityPercent
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.WindQuadrant34KtLines}
        type="geojson"
        data={noaa123RulePolygonData.windQuadrantLines34Kt}
      >
        <Layer
          id={MapLayerId.WindQuadrant34KtLines}
          beforeId={beforeLayerId}
          type="line"
          layout={{ 'line-join': 'round', 'line-cap': 'round' }}
          paint={{
            'line-color': windQuadrantColors.knot34Border,
            'line-opacity': windFieldOpacityPercent / 2,
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              windQuadrantColors.zoomStep1,
              windQuadrantColors.borderWidthStep1,
              windQuadrantColors.zoomStep2,
              windQuadrantColors.borderWidthStep2
            ]
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.WindQuadrant50KtLines}
        type="geojson"
        data={noaa123RulePolygonData.windQuadrantLines50Kt}
      >
        <Layer
          id={MapLayerId.WindQuadrant50KtLines}
          beforeId={beforeLayerId}
          type="line"
          layout={{ 'line-join': 'round', 'line-cap': 'round' }}
          paint={{
            'line-color': windQuadrantColors.knot50Border,
            'line-opacity': windFieldOpacityPercent / 2,
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              windQuadrantColors.zoomStep1,
              windQuadrantColors.borderWidthStep1,
              windQuadrantColors.zoomStep2,
              windQuadrantColors.borderWidthStep2
            ]
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.WindQuadrant64KtLines}
        type="geojson"
        data={noaa123RulePolygonData.windQuadrantLines64Kt}
      >
        <Layer
          id={MapLayerId.WindQuadrant64KtLines}
          beforeId={beforeLayerId}
          type="line"
          layout={{ 'line-join': 'round', 'line-cap': 'round' }}
          paint={{
            'line-color': windQuadrantColors.knot64Border,
            'line-opacity': windFieldOpacityPercent / 2,
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              windQuadrantColors.zoomStep1,
              windQuadrantColors.borderWidthStep1,
              windQuadrantColors.zoomStep2,
              windQuadrantColors.borderWidthStep2
            ]
          }}
        ></Layer>
      </Source>

      <CycloneIntersectionLayer
        map={map}
        selectedLayerName={selectedLayerName}
        symbolColor={symbolColor}
        paint={paint}
        noaa123RulePolygonData={noaa123RulePolygonData}
        vessels={vessels}
      />
    </>
  );
};

export default WindFieldLayer;
