import { Layer, Source } from 'react-map-gl';
import { CycloneMapData } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

export interface CycloneTrackLineLayerProps {
  cycloneMapData: CycloneMapData;
}

const CycloneTrackLineLayer = ({
  cycloneMapData
}: CycloneTrackLineLayerProps) => {
  return (
    <Source
      id={MapSourceId.CycloneTrackLine}
      type="geojson"
      data={cycloneMapData.trackLineData}
      generateId={true}
    >
      <Layer
        id={MapLayerId.CycloneTrackLine}
        type="line"
        layout={{ 'line-join': 'round', 'line-cap': 'round' }}
        paint={{
          'line-color': 'yellow',
          'line-opacity': 0.75,
          'line-width': ['interpolate', ['linear'], ['zoom'], 0, 4, 10, 1]
        }}
      ></Layer>
    </Source>
  );
};

export default CycloneTrackLineLayer;
