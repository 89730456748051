const DEFAULT_PRECISION = 2;

export const formatLatitude = (
  lat: number,
  maxPrecision = DEFAULT_PRECISION
) => {
  const absLat = Math.abs(lat);
  if (absLat >= 0 && absLat <= 180) {
    const validatedPrecision =
      maxPrecision >= 0 && maxPrecision - Math.floor(maxPrecision) == 0
        ? maxPrecision
        : DEFAULT_PRECISION;

    return `${Math.abs(
      Math.round(lat * Math.pow(10, validatedPrecision)) /
        Math.pow(10, validatedPrecision)
    )}° ${lat >= 0 ? 'N' : 'S'}`;
  }
  return '';
};

export const formatLongitude = (
  long: number,
  maxPrecision = DEFAULT_PRECISION
) => {
  const absLat = Math.abs(long);
  if (absLat >= 0 && absLat <= 180) {
    const validatedPrecision =
      maxPrecision >= 0 && maxPrecision - Math.floor(maxPrecision) == 0
        ? maxPrecision
        : DEFAULT_PRECISION;

    return `${Math.abs(
      Math.round(long * Math.pow(10, validatedPrecision)) /
        Math.pow(10, validatedPrecision)
    )}° ${long >= 0 ? 'E' : 'W'}`;
  }
  return '';
};

export function formatLatLong(
  lat: number,
  long: number,
  maxPrecision = DEFAULT_PRECISION
): string {
  const formattedLat = formatLatitude(lat, maxPrecision);
  const formattedLong = formatLongitude(long, maxPrecision);
  if (formattedLat && formattedLong) {
    return `${formattedLat}, ${formattedLong}`;
  } else {
    return '';
  }
}
