import {
  Icon,
  IconProps,
  Tooltip
} from '@org-crowley/enterprise-react-component-library';
import { MouseEventHandler } from 'react';
import { VesselStatus } from '../../models/Vessel';

export interface StatusIconProps {
  vesselStatus: VesselStatus;
  className?: string;
  heading?: number;
  tooltipText?: string;
  onClick?: () => void;
}

interface Properties {
  borderColor: string;
  iconName: IconProps['iconName'];
  iconColor: string;
  tooltipText?: string;
}

export function getIconNameFromStatus(
  vesselStatus: VesselStatus
): IconProps['iconName'] | undefined {
  switch (vesselStatus) {
    case VesselStatus.Moving:
      return 'Moving';
    case VesselStatus.Stopped:
      return 'Stopped';
    case VesselStatus.OutOfService:
      return 'OutOfService';
    case VesselStatus.NA:
      return 'OutOfService';
    default:
      return;
  }
}

export function StatusIcon({
  vesselStatus,
  className = '',
  heading,
  tooltipText,
  onClick
}: StatusIconProps) {
  let properties: Properties = {
    borderColor: '',
    iconName: getIconNameFromStatus(vesselStatus),
    iconColor: '',
    tooltipText
  };

  if (vesselStatus === VesselStatus.Moving) {
    properties = {
      ...properties,
      borderColor: 'border-green-50',
      iconColor: 'text-green-80'
    };
  } else if (vesselStatus === VesselStatus.Stopped) {
    properties = {
      ...properties,
      borderColor: 'border-red-50',
      iconColor: 'text-red-90'
    };
  } else if (vesselStatus === VesselStatus.OutOfService || VesselStatus.NA) {
    properties = {
      ...properties,
      borderColor: 'border-yellow-70',
      iconColor: 'text-yellow-95'
    };
  }

  const icon = (
    <Icon
      iconName={properties.iconName}
      className={`${properties.iconColor} w-4 h-4`}
      iconStyles={
        heading && vesselStatus === VesselStatus.Moving
          ? {
              transform: `rotate(${heading}deg)`
            }
          : undefined
      }
    />
  );

  const onIconClick: MouseEventHandler = (e) => {
    if (onClick) {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    }
  };

  return (
    <span
      className={`border rounded-full p-2 ${properties.borderColor} ${className}`}
      onClick={onIconClick}
      data-testid="status-icon"
    >
      {/* Tooltip with Icon inside if tooltipText is passed, or just the Icon if not */}
      {!!properties.tooltipText ? (
        <Tooltip tooltipText={properties.tooltipText}>{icon}</Tooltip>
      ) : (
        icon
      )}
    </span>
  );
}
