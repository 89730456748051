import {
  Feature,
  FeatureCollection,
  LineString,
  Point,
  Polygon,
  Properties
} from '@turf/turf';
import { DateTime } from 'luxon';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useMemo, useState } from 'react';
import { Layer, MapLayerMouseEvent, MapRef, Popup, Source } from 'react-map-gl';
import { Vessel } from '../../../../models/Vessel';
import {
  createForecastVesselInfo,
  ForecastVesselInfo,
  generateForecastLineFeatures,
  generateVesselForecastIntersectAssetNumbers,
  generateVesselForecastIntersectLineFeatures,
  generateOuter34KtCircleData,
  generateCircleIntersectData,
  generateVesselForecastPointFeatures,
  generateVesselForecastIntersectPointFeatures,
  generateFinalVesselForecastIntersectFeatures,
  HOUR_MAX
} from '../../../../utils/cycloneIntersectionUtils';
import { getIsoDateTime } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';
import { BATHYMETRY_LAYER, LIGHT_LAYER } from '../../Map';
import VesselDangerAreaMarker from './VesselDangerAreaMarker';
import { Noaa123RulePolygonData } from './WindFieldLayer';

export interface Outer34KtCircleData {
  cycloneShortName: string;
  circles: Feature<Polygon, Properties>[];
}

interface DangerPointPopupInfo {
  latitude: number;
  longitude: number;
  dateTime: string;
  hoursInDangerArea: number;
  messagePrefix: string;
}

interface CycloneIntersectionLayerProps {
  map: MapRef | undefined;
  selectedLayerName: string;
  symbolColor: string;
  paint:
    | {
        'text-color': string;
        'text-halo-color'?: undefined;
        'text-halo-width'?: undefined;
      }
    | {
        'text-color': string;
        'text-halo-color': string;
        'text-halo-width': number;
      };
  noaa123RulePolygonData: Noaa123RulePolygonData;
  vessels: Vessel[];
}

const CycloneIntersectionLayer = ({
  map,
  selectedLayerName,
  symbolColor,
  paint,
  noaa123RulePolygonData,
  vessels
}: CycloneIntersectionLayerProps) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupInfo, setPopupInfo] = useState<DangerPointPopupInfo | null>(null);

  const forecastVessels: ForecastVesselInfo[] = useMemo(() => {
    const results: ForecastVesselInfo[] = [];

    const startingDateTime = DateTime.now();

    vessels.forEach((vessel) => {
      results.push(createForecastVesselInfo(vessel, startingDateTime));
    });

    return results;
  }, [vessels]);

  const vesselForecastLineData = useMemo(() => {
    const results: FeatureCollection<LineString, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    results.features.push(...generateForecastLineFeatures(forecastVessels));

    return results;
  }, [forecastVessels]);

  const vesselForecastIntersectAssetNumbers = useMemo(() => {
    const results: number[] = [];

    results.push(
      ...generateVesselForecastIntersectAssetNumbers(
        forecastVessels,
        noaa123RulePolygonData
      )
    );

    return results;
  }, [forecastVessels, noaa123RulePolygonData]);

  const vesselForecastIntersectLines = useMemo(() => {
    const results: FeatureCollection<LineString, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    results.features.push(
      ...generateVesselForecastIntersectLineFeatures(
        vesselForecastLineData,
        vesselForecastIntersectAssetNumbers
      )
    );

    return results;
  }, [vesselForecastLineData, vesselForecastIntersectAssetNumbers]);

  const outer34KtCircleData = useMemo(() => {
    const results: Outer34KtCircleData[] = [];

    results.push(
      ...generateOuter34KtCircleData(
        noaa123RulePolygonData.cycloneOuter34KtData
      )
    );

    return results;
  }, [noaa123RulePolygonData.cycloneOuter34KtData]);

  const circleIntersectData = useMemo(() => {
    const lines: FeatureCollection<LineString, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    const points: FeatureCollection<Point, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    const assetNumbersInDanger: number[] = [];

    const intersectData = generateCircleIntersectData(
      vesselForecastIntersectLines,
      outer34KtCircleData
    );

    lines.features.push(...intersectData.lineFeatures);
    points.features.push(...intersectData.pointFeatures);
    assetNumbersInDanger.push(...intersectData.assetNumbersInDanger);

    return { lines, points, assetNumbersInDanger };
  }, [outer34KtCircleData, vesselForecastIntersectLines]);

  const vesselForecastPointData = useMemo(() => {
    const results: FeatureCollection<Point, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    results.features.push(
      ...generateVesselForecastPointFeatures(forecastVessels)
    );

    return results;
  }, [forecastVessels]);

  const vesselForecastIntersectPoints = useMemo(() => {
    const results: FeatureCollection<Point, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    results.features.push(
      ...generateVesselForecastIntersectPointFeatures(
        vesselForecastPointData,
        vesselForecastIntersectAssetNumbers
      )
    );

    return results;
  }, [vesselForecastPointData, vesselForecastIntersectAssetNumbers]);

  const finalVesselForecastIntersectData = useMemo(() => {
    const lines: FeatureCollection<LineString, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    const points: FeatureCollection<Point, Properties> = {
      type: 'FeatureCollection',
      features: []
    };

    const intersectData = generateFinalVesselForecastIntersectFeatures(
      vesselForecastIntersectLines.features,
      vesselForecastIntersectPoints.features,
      circleIntersectData.assetNumbersInDanger
    );

    lines.features.push(...intersectData.lineFeatures);
    points.features.push(...intersectData.pointFeatures);

    return { lines, points };
  }, [
    vesselForecastIntersectLines.features,
    vesselForecastIntersectPoints.features,
    circleIntersectData.assetNumbersInDanger
  ]);

  const onShowPopup = (pointFeature: Feature<Point, Properties>) => {
    setPopupInfo({
      longitude:
        pointFeature.geometry.type === 'Point'
          ? pointFeature.geometry.coordinates[0]
          : 0,
      latitude:
        pointFeature.geometry.type === 'Point'
          ? pointFeature.geometry.coordinates[1]
          : 0,
      dateTime: getIsoDateTime(pointFeature.properties?.dateTime),
      hoursInDangerArea: pointFeature.properties?.hoursInDangerArea,
      messagePrefix: pointFeature.properties?.messagePrefix
    });
    setShowPopup(true);
  };

  const onHidePopup = () => {
    setPopupInfo(null);
    setShowPopup(false);
  };

  const circleIntersectionMarkers: JSX.Element[] = useMemo(() => {
    const results: JSX.Element[] = [];

    if (
      circleIntersectData.points &&
      circleIntersectData.points.features &&
      circleIntersectData.points.features.length > 0
    ) {
      circleIntersectData.points.features.forEach((pt, index) => {
        results.push(
          <VesselDangerAreaMarker
            key={`vesselDangerMarker-${index}`}
            selectedLayerName={selectedLayerName}
            pointFeature={pt}
            onShowPopup={onShowPopup}
            onHidePopup={onHidePopup}
          />
        );
      });
    }

    return results;
  }, [circleIntersectData.points, selectedLayerName]);

  const popup = useMemo(() => {
    return new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: 'left',
      offset: [25, 0]
    });
  }, []);

  useEffect(() => {
    let hoverId: string | number | undefined;

    const mouseMove = (e: MapLayerMouseEvent) => {
      if (
        map &&
        e.features &&
        e.features.length > 0 &&
        e.features[0].geometry.type === 'LineString'
      ) {
        map.getCanvas().style.cursor = 'pointer';

        if (hoverId !== undefined) {
          map.removeFeatureState({
            source: MapSourceId.VesselForecastLine,
            id: hoverId
          });
        }

        hoverId = e.features[0].id;

        map.setFeatureState(
          {
            source: MapSourceId.VesselForecastLine,
            id: e.features[0].id
          },
          { hover: true }
        );

        const featureProps = e.features[0].properties;
        let html = '';
        html += '<div style="text-align:center">';
        html += `<h2>Vessel ${featureProps?.vesselName}</h2>`;
        html += `<p>${HOUR_MAX}-hour dead reckoning projection</p>`;
        html += '</div>';
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(html)
          .addTo(map.getMap());
      }
    };

    const mouseLeave = () => {
      if (map) {
        map.getCanvas().style.cursor = '';

        if (hoverId !== undefined) {
          map.setFeatureState(
            {
              source: MapSourceId.VesselForecastLine,
              id: hoverId
            },
            { hover: false }
          );
          hoverId = undefined;
        }
      }
      popup.remove();
    };

    map?.on('mousemove', MapLayerId.VesselForecastLine, mouseMove);
    map?.on('mouseleave', MapLayerId.VesselForecastLine, mouseLeave);

    return () => {
      map?.off('mousemove', MapLayerId.VesselForecastLine, mouseMove);
      map?.off('mouseleave', MapLayerId.VesselForecastLine, mouseLeave);
    };
  }, [map, popup]);

  return (
    <>
      <Source
        id={MapSourceId.VesselForecastLine}
        type="geojson"
        data={finalVesselForecastIntersectData.lines}
        generateId={true}
      >
        <Layer
          id={MapLayerId.VesselForecastLine}
          type="line"
          layout={{ 'line-join': 'round', 'line-cap': 'round' }}
          paint={{
            'line-color': symbolColor,
            'line-opacity': 1.0,
            'line-width': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              ['case', ['boolean', ['feature-state', 'hover'], false], 12, 4],
              10,
              ['case', ['boolean', ['feature-state', 'hover'], false], 3, 1]
            ],
            'line-dasharray': [0, 2, 0, 2]
          }}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.VesselForecastPoint}
        type="geojson"
        data={finalVesselForecastIntersectData.points}
      >
        <Layer
          id={MapLayerId.VesselForecastPoint}
          type="symbol"
          layout={{
            'icon-image': `${
              selectedLayerName === LIGHT_LAYER.name ||
              selectedLayerName === BATHYMETRY_LAYER.name
                ? 'black-circle'
                : 'white-circle'
            }`,
            'icon-size': 0.75,
            'icon-allow-overlap': true,
            'text-field': ['get', 'dateTime'],
            'text-allow-overlap': false,
            'text-anchor': 'top',
            'text-radial-offset': 2
          }}
          paint={paint}
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.CircleIntersectLines}
        type="geojson"
        data={circleIntersectData.lines}
      >
        <Layer
          id={MapLayerId.CircleIntersectLines}
          type="line"
          layout={{ 'line-join': 'round', 'line-cap': 'round' }}
          paint={{
            'line-color': '#FF0000',
            'line-opacity': 1.0,
            'line-width': ['interpolate', ['linear'], ['zoom'], 0, 6, 10, 2]
          }}
        ></Layer>
      </Source>

      {circleIntersectionMarkers}

      {showPopup && popupInfo && (
        <Popup
          longitude={popupInfo.longitude}
          latitude={popupInfo.latitude}
          anchor="bottom"
          offset={15}
          closeButton={false}
          focusAfterOpen={false}
          closeOnClick={false}
          closeOnMove={false}
          maxWidth="none"
        >
          <div>{popupInfo.messagePrefix}</div>
          <div>{popupInfo.dateTime}</div>
          {popupInfo.hoursInDangerArea && (
            <div>{`Time spent in danger area: ${popupInfo.hoursInDangerArea} hours`}</div>
          )}
        </Popup>
      )}
    </>
  );
};

export default CycloneIntersectionLayer;
