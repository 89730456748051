import { Typography } from '@org-crowley/enterprise-react-component-library';
import { Activity } from '../../models/Voyage';

interface ActivityItem {
  description: Activity;
  icon: React.ReactNode;
}

export interface Activities {
  [key: string]: ActivityItem;
}

interface ActivityColProps {
  quantity: number;
  activityItem: ActivityItem;
}

export default function ActivityColumn({
  quantity,
  activityItem
}: ActivityColProps) {
  return (
    <div className="block m-auto" data-testid="activity-col">
      {activityItem.icon}
      <Typography variant="h100" className="block mb-3 m-auto text-center">
        {activityItem.description}
      </Typography>
      <Typography
        variant="h900"
        className="text-blue-80 block m-auto text-center"
      >
        {quantity}
      </Typography>
    </div>
  );
}
