import { Typography } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { MdError, MdInfo, MdWarning } from 'react-icons/md';

export enum MessageSeverity {
  ERROR,
  WARNING,
  INFO
}

export interface MapMessageProps {
  messageText: string;
  messageSeverity?: MessageSeverity;
}

export function MapMessage({
  messageText,
  messageSeverity = MessageSeverity.INFO
}: MapMessageProps) {
  return (
    <div
      className={cn(
        'z-20',
        'absolute',
        'rounded-md',
        `${
          messageSeverity === MessageSeverity.ERROR
            ? 'bg-red-30'
            : messageSeverity === MessageSeverity.WARNING
            ? 'bg-yellow-30'
            : 'bg-blue-10'
        }`,
        'bg-opacity-80',
        'p-2',
        'm-5'
      )}
    >
      {messageSeverity === MessageSeverity.ERROR && (
        <MdError size={25} className="inline align-middle" />
      )}
      {messageSeverity === MessageSeverity.WARNING && (
        <MdWarning size={25} className="inline align-middle" />
      )}
      {messageSeverity === MessageSeverity.INFO && (
        <MdInfo size={25} className="inline align-middle" />
      )}
      <Typography variant="h500" as="h5" className="inline ml-2 align-middle">
        {messageText}
      </Typography>
    </div>
  );
}
