import {
  CollapsibleContainer,
  SpecSheet,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import { useEffect, useMemo, useReducer } from 'react';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { Loading } from '../Loading/Loading';
import { useOktaAuth } from '../../contexts/OktaContext';
import { ArrivalDepartureStatus, VoyageLeg } from '../../models/Voyage';
import { formatDateTime, formatISODate } from '../../utils/formatDateTime';
import { isEmpty } from 'lodash';
import { VoyageStatusTabs, TABS } from '../VoyageStatusTabs/VoyageStatusTabs';
import { MdDirectionsBoat } from 'react-icons/md';
import {
  VoyageActionType,
  voyageReducer
} from '../../reducers/voyageOverviewReducer';
import { useVoyageSummaries } from '../../utils/useVoyageSummaries';
import { VoyageLegCard } from '../VoyageLegCard/VoyageLegCard';
import { useVoyageLegs } from '../../utils/useVoyageLegs';

interface Props {
  vesselAssetNumber?: number;
}

export const getFullDate = (timestamp?: number | string | null): string => {
  const date =
    typeof timestamp === 'string'
      ? formatISODate(timestamp)
      : formatDateTime(timestamp);

  if (isEmpty(date.formattedDate) || isEmpty(date.formattedZone))
    return 'Not Available';
  return `${date.formattedTime} on ${date.formattedDate} (${date.formattedZone})`;
};

export function VoyageOverview({ vesselAssetNumber }: Props) {
  const { authState } = useOktaAuth();
  const [voyageState, voyageDispatch] = useReducer(voyageReducer, {
    allVoyages: undefined,
    currentVoyageLegContainers: undefined,
    filteredVoyages: undefined,
    activeIndex: 0,
    selectedVoyageLegContainer: undefined,
    selectedVoyage: undefined,
    tabName: TABS.Commenced
  });

  const {
    selectedVoyageLegContainer,
    selectedVoyage,
    tabName,
    filteredVoyages
  } = voyageState;

  const accessToken = authState?.accessToken?.accessToken;

  const currentVoyageNumber = selectedVoyage?.VoyageNumber;

  const { data: currentVoyage, error: voyageError } = useVoyageLegs({
    currentVoyageNumber,
    accessToken,
    assetNumber: vesselAssetNumber
  });

  const { data: voyageSchedule, error: voyageScheduleError } =
    useVoyageSummaries({
      shouldFetch: true,
      assetNumber: vesselAssetNumber,
      accessToken: accessToken
    });

  const voyageLegContainers = useMemo(() => {
    return currentVoyage?.voyageLegContainers || [];
  }, [currentVoyage?.voyageLegContainers]);

  let errorMessage = null;

  if (!vesselAssetNumber) {
    errorMessage = 'Missing Vessel Data';
  } else if (voyageScheduleError) {
    errorMessage = 'There was a problem retrieving voyage summary';
  } else if (voyageError) {
    errorMessage = 'There was a problem retrieving voyage legs';
  } else if (voyageSchedule && voyageSchedule.voyageSummaries?.length === 0) {
    errorMessage = 'No voyages available';
  }

  const currentVoyageLegIndex = useMemo(() => {
    let foundIndex = voyageLegContainers.findIndex(({ voyageLegs }) =>
      voyageLegs.find(
        (voyageLeg) =>
          voyageLeg.ArrivalDepartureStatus === ArrivalDepartureStatus.AR
      )
    );

    //If there is no current port, show the next port
    if (foundIndex < 0) {
      foundIndex = voyageLegContainers.findIndex(({ voyageLegs }) =>
        voyageLegs.find(
          (voyageLeg) =>
            voyageLeg.ArrivalDepartureStatus ===
            ArrivalDepartureStatus.Estimated
        )
      );
    }

    // if there is no current or next port, show the last port information
    if (foundIndex < 0) {
      foundIndex = voyageLegContainers.length;
    }
    return foundIndex;
  }, [voyageLegContainers]);

  useEffect(() => {
    voyageDispatch({
      type: VoyageActionType.SET_VOYAGES_AND_LEGS,
      payload: {
        allVoyages: voyageSchedule,
        currentVoyageLegContainers: voyageLegContainers,
        selectedVoyageLegContainer: voyageLegContainers[currentVoyageLegIndex]
      }
    });
  }, [voyageLegContainers, currentVoyageLegIndex, voyageSchedule]);

  useEffect(() => {
    if (
      tabName === TABS.Commenced &&
      filteredVoyages &&
      filteredVoyages.length === 1
    ) {
      voyageDispatch({
        type: VoyageActionType.SET_VOYAGE,
        payload: { selectedVoyage: filteredVoyages[0] }
      });
    }
  }, [filteredVoyages, tabName]);

  if (errorMessage) {
    return (
      <div className="h-screen flex flex-col items-center justify-start ">
        <ErrorMessage errorMessage={errorMessage} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center gap-x-16 px-8 pb-8 lg:flex-row lg:items-start bg-silver-5">
      {voyageSchedule ? (
        <>
          <VoyageStatusTabs
            voyageState={voyageState}
            voyageDispatch={voyageDispatch}
          />
          {filteredVoyages?.length === 0 ? (
            <div className="flex flex-col w-full h-[30vh] center items-center justify-center">
              <MdDirectionsBoat size={60} />
              <Typography variant="h500" className="mt-4">
                There are no {tabName?.toLowerCase()} voyages available
              </Typography>
            </div>
          ) : selectedVoyage ? (
            <div className="flex-1 w-full lg:overflow-y-auto lg:h-detail-tab">
              <CollapsibleContainer heading="Voyage">
                <SpecSheet
                  statBlockVariant="sideBySide"
                  statBlocks={[
                    {
                      stats: [
                        {
                          key: 'Voyage Number',
                          value: selectedVoyage.VoyageNumber
                        },
                        {
                          key: 'Voyage Status',
                          value: selectedVoyage.VoyageStatus
                        },
                        {
                          key: 'Customer',
                          value: selectedVoyage.Charterer
                        },
                        {
                          key: 'Opr Type',
                          value: selectedVoyage.OprType
                        }
                      ]
                    }
                  ]}
                />
              </CollapsibleContainer>
              <CollapsibleContainer heading="Schedule" className="py-4">
                <div className="flex flex-row flex-wrap sm:gap-x-4 xl:gap-x-8 gap-y-4 justify-center sm:justify-start px-4">
                  {selectedVoyageLegContainer?.voyageLegs.map(
                    (voyageLeg: VoyageLeg) => {
                      return (
                        <VoyageLegCard
                          key={voyageLeg.SK}
                          voyageLeg={voyageLeg}
                        />
                      );
                    }
                  )}
                </div>
              </CollapsibleContainer>
            </div>
          ) : (
            <div className="flex flex-col w-full h-[30vh] center items-center justify-center">
              <MdDirectionsBoat size={60} />
              <Typography
                variant="h500"
                className="mt-4"
                dataTestId={`choose-${tabName}-voyage`}
              >
                Choose a {tabName} Voyage
              </Typography>
              <Typography className="mt-4 text-center max-w-[450px]">
                {`This vessel is scheduled for multiple ${tabName?.toLocaleLowerCase()} voyages.`}
              </Typography>
              <Typography className="mt-4 text-center max-w-[450px]">
                Please select a voyage for more details.
              </Typography>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
