import { Dispatch, SetStateAction } from 'react';
import { MapRef } from 'react-map-gl';
import { Vessel } from '../../../../models/Vessel';
import BathymetryLayer from '../BathymetryLayer/BathymetryLayer';
import CycloneLayer from '../CycloneLayer/CycloneLayer';
import NoaaChartLayer from '../NoaaChartLayer/NoaaChartLayer';

export interface AllLayersProps {
  showNoaaChartLayer: boolean;
  showBathymetryLayer: boolean;
  showCycloneLayer: boolean;
  mapRef: MapRef | undefined;
  selectedLayerName: string;
  showCycloneWindFields: boolean;
  vessels: Vessel[];
  windFieldOpacity: number;
}

const AllLayers = ({
  showNoaaChartLayer,
  showBathymetryLayer,
  showCycloneLayer,
  mapRef,
  selectedLayerName,
  showCycloneWindFields,
  vessels,
  windFieldOpacity
}: AllLayersProps) => {
  return (
    <>
      {showNoaaChartLayer && <NoaaChartLayer />}

      {showBathymetryLayer && (
        <BathymetryLayer showNoaaChart={showNoaaChartLayer} />
      )}

      {showCycloneLayer && (
        <CycloneLayer
          map={mapRef}
          selectedLayerName={selectedLayerName}
          showCycloneWindFields={showCycloneWindFields}
          vessels={vessels}
          windFieldOpacity={windFieldOpacity}
        />
      )}
    </>
  );
};

export default AllLayers;
