import { Typography } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { useMemo, useState } from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { searchVoyageTime } from '../../utils/searchVoyageTime';
import { NOT_AVAILABLE_TEXT } from '../../utils/textCopy';
import { Loading } from '../Loading/Loading';
import { VoyageLeg } from '../../models/Voyage';
import ActivityColumn from './ActivityColumn';
import CargoItemsTable from './CargoItemsTable';
import { ACTIVITY_VARIANTS, ICON_SIZE, NO_ACTIVITY_VARIANT } from './constants';
import TimeCol, { Time } from './TimeColumn';

interface Props {
  voyageLeg: VoyageLeg | undefined;
}

const getVoyageLegActivities = (voyageLeg: VoyageLeg | undefined) => {
  if (!voyageLeg) return;
  const cargoItems = voyageLeg.cargoItems;
  const voyageLegActivity = voyageLeg?.Activity;

  //Group the activities
  //Validates if no cargo activity, show VoyageLeg activity
  const activities = cargoItems?.length
    ? cargoItems.reduce(function (r, a) {
        r[a.FunctionCode] = r[a.FunctionCode] || [];
        r[a.FunctionCode].push(a.CargoItinerarySeqNumber);
        return r;
      }, Object.create(null))
    : voyageLegActivity
    ? { [voyageLegActivity]: [voyageLeg.Order] }
    : { activity: [] };

  return activities;
};

export function VoyageLegCard({ voyageLeg }: Props) {
  const [showCargoItems, setShowCargoItems] = useState(false);
  const cargoItems = voyageLeg?.cargoItems;
  const activities = useMemo(
    () => getVoyageLegActivities(voyageLeg),
    [voyageLeg]
  );
  const { arrivalTime, departureTime } = useMemo(
    () => searchVoyageTime(voyageLeg),
    [voyageLeg]
  );

  if (!voyageLeg) return <Loading />;

  const toggleCaret = () => {
    setShowCargoItems((prevState) => !prevState);
  };

  return (
    <div className="relative md:m-[4px]">
      <div className="drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)] w-[80vw] max-w-[336px] sm:w-[336px] pb-[10px]">
        {activities && (
          <div className="bg-silver-5 grid grid-flow-col auto-cols-max gap-2 place-content-evenly rounded-t-md border-b border-silver-30">
            {Object.keys(activities).map((key, index) => {
              const activityItem =
                ACTIVITY_VARIANTS[key] || NO_ACTIVITY_VARIANT;
              if (!activityItem) return;
              return (
                <ActivityColumn
                  key={`activity-col-${index}`}
                  activityItem={activityItem}
                  quantity={activities[key].length}
                />
              );
            })}
          </div>
        )}

        <div className="bg-white text-center h-10 flex items-center justify-center">
          <Typography
            variant="h100"
            className="text-silver-50 font-normal pt-3"
          >
            Berth:
            <span className="text-silver-100 pl-1">
              {voyageLeg.Berth || NOT_AVAILABLE_TEXT}
            </span>
          </Typography>
        </div>

        <div
          data-testid="times-row"
          className={cn(
            'bg-white grid grid-cols-7 gap-2 place-content-evenly px-2',
            { 'rounded-b-md': !showCargoItems }
          )}
        >
          <TimeCol time={arrivalTime} type={Time.Arrival} />
          <div className="flex items-end">
            {cargoItems && cargoItems.length > 0 && (
              <MdOutlineKeyboardArrowDown
                data-testid="cargo-caret"
                size={ICON_SIZE}
                className={cn('mx-auto', 'cursor-pointer', {
                  'rotate-180': showCargoItems
                })}
                onClick={toggleCaret}
              />
            )}
          </div>
          <TimeCol time={departureTime} type={Time.Departure} />
        </div>

        {showCargoItems && cargoItems && cargoItems.length > 0 && (
          <div className="bg-white rounded-b-md">
            <div className="w-full border-b border-silver-30 p-4 flex flex-col justify-center items-center">
              <Typography variant="h100" className="text-silver-50">
                Cargo Items
              </Typography>
            </div>
            <CargoItemsTable cargoItems={cargoItems} />
          </div>
        )}
      </div>
    </div>
  );
}
