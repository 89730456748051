export interface CycloneData {
  PK: string;
  SK: string;
  UpdatedAt: number;
  UpdatedBy: string;
  CycloneData: Cyclone[] | null;
}

export interface Cyclone {
  id: string;
  profile: {
    basinOrigin: Basin;
    basinCurrent: Basin;
    basins: Basin[];
    name: string;
    year: number;
    event: number;
    isActive: boolean;
    lifespan: {
      startTimestamp: number;
      startDateTimeISO: string; // Ex: '2017-10-04T10:00:00-05:00'
      endTimestamp: null;
      endDateTimeISO: null;
    };
    maxStormType: string;
    maxStormCat: StormCat;
    maxStormName: string;
    windSpeed: {
      maxKTS: number;
      maxKPH: number;
      maxMPH: number;
      maxTimestamp: number;
      maxDateTimeISO: string; // Ex: '2017-10-04T10:00:00-05:00'
    };
    pressure: {
      minMB: number | null;
      minIN: number | null;
      minTimestamp: number | null;
      minDateTimeISO: string | null; // Ex: '2017-10-04T10:00:00-05:00'
    };
    boundingBox: number[];
    tz: string;
  };
  position: {
    location: { type: string; coordinates: number[] };
    details: {
      basin: Basin;
      stormType: string;
      stormCat: StormCat;
      stormName: string;
      stormShortName: string;
      advisoryNumber: string;
      movement: {
        directionDEG: number;
        direction: string;
        speedKTS: number;
        speedKPH: number;
        speedMPH: number;
      };
      windSpeedKTS: number;
      windSpeedKPH: number;
      windSpeedMPH: number;
      gustSpeedKTS: number | null;
      gustSpeedKPH: number | null;
      gustSpeedMPH: number | null;
      pressureMB: number | null;
      pressureIN: number | null;
      windRadii: WindRadii[] | null;
    };
    timestamp: number;
    dateTimeISO: string; // Ex: '2017-10-04T10:00:00-05:00'
    loc: {
      long: number;
      lat: number;
    };
  };
  track: TrackPoint[];
  forecast: ForecastPoint[] | null;
  breakPointAlerts: BreakPointAlert[] | null;
  errorCone: { type: string; coordinates: Array<Array<number[]>> } | null;
}

/**
 * AL = Atlantic
 * EP = Eastern Pacific
 * CP = Central Pacific
 * WP = Western Pacific
 * IO = Indian Ocean
 * SH = Southern Hemisphere
 */
export enum Basin {
  AL = 'AL',
  EP = 'EP',
  CP = 'CP',
  WP = 'WP',
  IO = 'IO',
  SH = 'SH'
}

/**
 * TD = Tropical Depression
 * TS = Tropical Storm
 * H1 = Category 1 Hurricane
 * H2 = Category 2 Hurricane
 * H3 = Category 3 Hurricane
 * H4 = Category 4 Hurricane
 * H5 = Category 5 Hurricane
 * TY = Typhoon
 * STY = Super Typhoon
 */
export enum StormCat {
  TD = 'TD',
  TS = 'TS',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  TY = 'TY',
  STY = 'STY'
}

/**
 * TR.A = Tropical Storm Watch
 * TR.W = Tropical Storm Warning
 * HU.A = Hurricane Watch
 * HU.W = Hurricane Warning
 */
export enum AlertType {
  TROPICAL_STORM_WATCH = 'TR.A',
  TROPICAL_STORM_WARNING = 'TR.W',
  HURRICANE_WATCH = 'HU.A',
  HURRICANE_WARNING = 'HU.W'
}

export interface TrackPoint {
  location: {
    type: string;
    coordinates: number[];
  };
  details: {
    basin: Basin;
    stormType: string;
    stormCat: StormCat;
    stormName: string;
    stormShortName: string;
    advisoryNumber: string;
    movement: {
      directionDEG: number;
      direction: string;
      speedKTS: number;
      speedKPH: number;
      speedMPH: number;
    };
    windSpeedKTS: number;
    windSpeedKPH: number;
    windSpeedMPH: number;
    gustSpeedKTS: number | null;
    gustSpeedKPH: number | null;
    gustSpeedMPH: number | null;
    pressureMB: number | null;
    pressureIN: number | null;
    windRadii: WindRadii[] | null;
  };
  timestamp: number;
  dateTimeISO: string; // Ex: '2017-10-04T10:00:00-05:00'
  loc: {
    long: number;
    lat: number;
  };
}

export interface ForecastPoint {
  location: {
    type: string;
    coordinates: number[];
  };
  details: {
    basin: Basin;
    stormType: string;
    stormCat: StormCat;
    stormName: string;
    stormShortName: string;
    advisoryNumber: string;
    movement: string | null;
    windSpeedKTS: number;
    windSpeedKPH: number;
    windSpeedMPH: number;
    gustSpeedKTS: number;
    gustSpeedKPH: number;
    gustSpeedMPH: number;
    pressureMB: number | null;
    pressureIN: number | null;
    windRadii: WindRadii[] | null;
  };
  timestamp: number;
  dateTimeISO: string; // Ex: '2017-10-04T10:00:00-05:00'
  loc: {
    long: number;
    lat: number;
  };
}

interface BreakPointAlert {
  alertType: AlertType;
  coords: {
    type: string;
    coordinates: Array<number[]>;
  };
}

interface WindQuadrantInfo {
  loc: {
    long: number;
    lat: number;
  };
  distanceKM: number;
  distanceNM: number;
  distanceMI: number;
}

interface WindQuadrant {
  nw: WindQuadrantInfo;
  se: WindQuadrantInfo;
  sw: WindQuadrantInfo;
  ne: WindQuadrantInfo;
}

export interface WindRadii {
  windSpeedKPH: number;
  windSpeedMPH: number;
  windSpeedKTS: number;
  quadrants: WindQuadrant;
}
