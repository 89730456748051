import { Typography } from '@org-crowley/enterprise-react-component-library';
import { isNil } from 'lodash';

interface VesselValuesDisplayProps {
  values: { [key: string]: string | number | undefined }[];
}

export const VesselValuesDisplay = ({ values }: VesselValuesDisplayProps) => {
  return (
    <>
      {values.map((column, index) => (
        <div key={index}>
          {Object.keys(column).map((key) => (
            <div key={key}>
              <Typography variant="body-small" className="text-silver-90">
                <strong>{key}: </strong>
                {isNil(column[key]) ? 'N/A' : column[key]}
              </Typography>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};
