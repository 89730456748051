import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useState
} from 'react';
import { MdOutlineLayers } from 'react-icons/md';
import cn from 'classnames';
import {
  Typography,
  RESPONSIVE_BREAKPOINTS
} from '@org-crowley/enterprise-react-component-library';
import { MapLayer, MapOptions, MAP_LAYERS } from '../../Map';
import { Toggle } from '../../../Toggle/Toggle';
import { FeatureFlagContext } from '../../../../contexts/FeatureFlagContext';

export interface LayerControlProps {
  mapLayerProperties: MapLayer;
  onMapLayerPropertiesChange: Dispatch<SetStateAction<MapLayer>>;
  windowWidth: number;
  mapOptionProperties: MapOptions;
  setMapOptionProperties: Dispatch<SetStateAction<MapOptions>>;
}

const LayerControl = ({
  mapLayerProperties,
  onMapLayerPropertiesChange,
  windowWidth,
  mapOptionProperties,
  setMapOptionProperties
}: LayerControlProps) => {
  const [layerControlVisible, setLayerControlVisible] = useState(false);

  const {
    showVesselNames,
    showCyclones,
    showCycloneWindFields,
    showNoaaChart
  } = mapOptionProperties;

  const { featureCyclonesEnabled } = useContext(FeatureFlagContext);

  const onShowToggleLayerControl = () => {
    setLayerControlVisible(true);
  };

  const onHideToggleLayerControl = () => {
    setLayerControlVisible(false);
  };

  const onChangeLayer = (event: ChangeEvent<HTMLInputElement>) => {
    onMapLayerPropertiesChange((prev) => {
      return {
        ...prev,
        id: event.target.id,
        name: event.target.name,
        value: event.target.value
      };
    });
  };

  const onChangeShowVesselNames = () => {
    setMapOptionProperties((prevState) => ({
      ...prevState,
      showVesselNames: !prevState.showVesselNames
    }));
  };

  const onChangeShowCyclones = () => {
    setMapOptionProperties((prevState) => ({
      ...prevState,
      showCyclones: !prevState.showCyclones
    }));
  };

  const onChangeShowCycloneWindFields = () => {
    setMapOptionProperties((prevState) => ({
      ...prevState,
      showCycloneWindFields: !prevState.showCycloneWindFields
    }));
  };

  const onChangeShowNoaaChart = () => {
    setMapOptionProperties((prevState) => ({
      ...prevState,
      showNoaaChart: !prevState.showNoaaChart
    }));
  };

  const sharedClasses: Array<string> = [
    'mapboxgl-ctrl',
    'mapboxgl-ctrl-group',
    'right-2.5',
    `${
      windowWidth <= RESPONSIVE_BREAKPOINTS.medium ? 'bottom-28' : 'bottom-20'
    }`,
    'absolute'
  ];

  return (
    <>
      {layerControlVisible && (
        <div
          className={cn(sharedClasses, 'p-2', 'flex', 'flex-col', 'z-20')}
          onMouseLeave={onHideToggleLayerControl}
        >
          <div>
            <Typography variant="h400" className="text-silver-100">
              Layer
            </Typography>
          </div>
          {MAP_LAYERS.map((layer, index) => (
            <div key={index}>
              <input
                id={`layer-${index}`}
                type="radio"
                name={layer.name}
                value={layer.value}
                checked={mapLayerProperties.id === layer.id}
                onChange={onChangeLayer}
                className="align-middle p-1"
              />
              <label htmlFor={`layer-${index}`} className="align-middle p-1">
                <Typography variant="h200" className="text-silver-100">
                  {layer.name}
                </Typography>
              </label>
            </div>
          ))}

          <div className="w-full border-t text-silver-40 mt-3 py-2">
            <Typography variant="h400" className="text-silver-100">
              Options
            </Typography>
          </div>
          <div>
            <Toggle
              id="toggle-vessel-names"
              checked={showVesselNames}
              onChange={onChangeShowVesselNames}
            >
              <Typography variant="h200" className="text-silver-100">
                <span data-testid="toggle-vessel-names-text">
                  Show Vessel Names
                </span>
              </Typography>
            </Toggle>
          </div>
          {featureCyclonesEnabled && (
            <div>
              <Toggle
                id="toggle-cyclones"
                checked={showCyclones}
                onChange={onChangeShowCyclones}
              >
                <Typography variant="h200" className="text-silver-100">
                  <span data-testid="toggle-cyclones-text">Show Cyclones</span>
                </Typography>
              </Toggle>
            </div>
          )}
          {featureCyclonesEnabled && showCyclones && (
            <div>
              <Toggle
                id="toggle-cyclone-wind-fields"
                checked={showCycloneWindFields}
                onChange={onChangeShowCycloneWindFields}
              >
                <Typography variant="h200" className="text-silver-100">
                  <span data-testid="toggle-cyclone-wind-fields-text">
                    Show Wind Fields
                  </span>
                </Typography>
              </Toggle>
            </div>
          )}
          <div>
            <Toggle
              id="toggle-noaa-chart"
              checked={showNoaaChart}
              onChange={onChangeShowNoaaChart}
            >
              <Typography variant="h200" className="text-silver-100">
                <span data-testid="toggle-noaa-chart-text">
                  Show Nautical Chart
                </span>
              </Typography>
            </Toggle>
          </div>
        </div>
      )}
      <div
        className={cn(sharedClasses, 'z-10')}
        onMouseOver={onShowToggleLayerControl}
      >
        <button data-testid="map-hover-button">
          <div className="flex justify-center content-center">
            <MdOutlineLayers size={20} />
          </div>
        </button>
      </div>
    </>
  );
};

export default React.memo(LayerControl);
