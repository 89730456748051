import {
  MdOutlineFileDownload,
  MdOutlineFileUpload,
  MdLocalGasStation,
  MdConstruction,
  MdScience,
  MdAnchor,
  MdAccessTimeFilled,
  MdOutlineErrorOutline
} from 'react-icons/md';
import { Activity, FunctionCode } from '../../models/Voyage';
import { Activities } from './ActivityColumn';

export const ICON_SIZE = 24;

const LOAD_VARIANT = {
  description: Activity.Load,
  icon: (
    <MdOutlineFileDownload
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

const DISCHARGE_VARIANT = {
  description: Activity.Discharge,
  icon: (
    <MdOutlineFileUpload
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

const FUELING_VARIANT = {
  description: Activity.Fueling,
  icon: (
    <MdLocalGasStation
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

const REPAIR_VARIANT = {
  description: Activity.Repair,
  icon: (
    <MdConstruction
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

const SEA_BUOY_VARIANT = {
  description: Activity.SeaBuoy,
  icon: (
    <MdScience
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

const SHIPYARD_VARIANT = {
  description: Activity.Shipyard,
  icon: (
    <MdAnchor
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

const WAITING_VARIANT = {
  description: Activity.Waiting,
  icon: (
    <MdAccessTimeFilled
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

export const NO_ACTIVITY_VARIANT = {
  description: 'Activity',
  icon: (
    <MdOutlineErrorOutline
      size={ICON_SIZE}
      className="text-sky-50 bg-blue-0 rounded-xl my-3 m-auto"
    />
  )
};

export const ACTIVITY_VARIANTS: Activities = {
  [FunctionCode.L]: LOAD_VARIANT,
  [Activity.Load]: LOAD_VARIANT,
  [FunctionCode.D]: DISCHARGE_VARIANT,
  [Activity.Discharge]: DISCHARGE_VARIANT,
  [Activity.Fueling]: FUELING_VARIANT,
  [Activity.Repair]: REPAIR_VARIANT,
  [Activity.SeaBuoy]: SEA_BUOY_VARIANT,
  [Activity.Shipyard]: SHIPYARD_VARIANT,
  [Activity.Waiting]: WAITING_VARIANT
};
