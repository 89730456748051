import { Layer, Source } from 'react-map-gl';
import { CycloneMapData } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

export interface CycloneErrorConeLayerProps {
  cycloneMapData: CycloneMapData;
  symbolColor: '#000000' | '#FFFFFF';
}

const CycloneErrorConeLayer = ({
  cycloneMapData,
  symbolColor
}: CycloneErrorConeLayerProps) => {
  return (
    <Source
      id={MapSourceId.CycloneErrorCone}
      type="geojson"
      data={cycloneMapData.errorConeData}
    >
      <Layer
        id={MapLayerId.CycloneErrorCone}
        type="fill"
        paint={{
          'fill-color': symbolColor,
          'fill-opacity': 0.25
        }}
      ></Layer>
    </Source>
  );
};

export default CycloneErrorConeLayer;
