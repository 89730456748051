import { Typography } from '@org-crowley/enterprise-react-component-library';
import { Dispatch, ReactNode, useEffect } from 'react';
import cn from 'classnames';
import { VoyageLegExplorer } from '../VoyageLegExplorer/VoyageLegExplorer';
import { VoyageSummary } from '../../models/Voyage';
import { formatVoyageTime } from '../../utils/formatDateTime';
import { MdArrowRightAlt } from 'react-icons/md';
import { TabContent } from '../TabContent/TabContent';
import {
  VoyageAction,
  VoyageActionType,
  VoyageState
} from '../../reducers/voyageOverviewReducer';
import { searchVoyageTime } from '../../utils/searchVoyageTime';

export interface VoyageExplorerItemProps {
  children: ReactNode;
  selectVoyage: () => void;
}

interface VoyageStatusTabsProps {
  voyageState: VoyageState;
  voyageDispatch: Dispatch<VoyageAction>;
}

const Li = ({ children, selectVoyage }: VoyageExplorerItemProps) => (
  <li
    className={cn(
      'relative',
      'm-0',
      'py-4',
      'pl-11',
      'last:pb-0',
      'flex',
      'items-center',
      'hover:cursor-pointer'
    )}
    onClick={selectVoyage}
  >
    {children}
  </li>
);

export enum TABS {
  Commenced = 'Commenced',
  Forecasted = 'Forecasted',
  Completed = 'Completed'
}

export const VoyageStatusTabs = ({
  voyageState,
  voyageDispatch
}: VoyageStatusTabsProps) => {
  const { allVoyages, activeIndex, selectedVoyage, tabName } = voyageState;

  const tabNameToVoyageStatus =
    tabName === TABS.Forecasted ? 'Forecast' : tabName;

  const { filteredVoyages } = voyageState;

  useEffect(() => {
    //This is for catching Scheduled voyages as Forecasted and Closed voyages as Completed
    const similarStatus = (status: string | undefined) =>
      status === 'Forecast'
        ? 'Scheduled'
        : status === TABS.Completed
        ? 'Closed'
        : undefined;

    const filterVoyages = (
      status: string | undefined
    ): VoyageSummary[] | undefined => {
      return allVoyages?.voyageSummaries.filter(
        (voyage) =>
          voyage?.VoyageStatus === status ||
          voyage?.VoyageStatus == similarStatus(status)
      );
    };

    voyageDispatch({
      type: VoyageActionType.SET_FILTERED_VOYAGES,
      payload: { filteredVoyages: filterVoyages(tabNameToVoyageStatus) }
    });
  }, [allVoyages?.voyageSummaries, tabNameToVoyageStatus, voyageDispatch]);

  const handleSelectVoyage = (voyage: VoyageSummary) =>
    voyageDispatch({
      type: VoyageActionType.SET_VOYAGE,
      payload: { selectedVoyage: voyage }
    });

  const handleTabChange = (tabIndex: number) =>
    voyageDispatch({
      type: VoyageActionType.CHANGE_TAB,
      payload: {
        activeIndex: tabIndex,
        tabName: Object.values(TABS)[tabIndex]
      }
    });

  return allVoyages ? (
    <>
      <TabContent
        tabs={Object.values(TABS)}
        onChange={(tabIndex) => handleTabChange(tabIndex)}
        activeTabIndex={activeIndex}
        className="md:min-w-[425px] lg:w-[512px]"
      >
        {selectedVoyage ? (
          <div className="px-6 py-3">
            <VoyageLegExplorer
              voyageState={voyageState}
              voyageDispatch={voyageDispatch}
            />
          </div>
        ) : (
          <div
            className={cn(
              'py-3 overflow-y-auto scroll-smooth',
              `max-h-[calc(100vh_-_360px_-_5.25rem)]`
            )}
          >
            {filteredVoyages && filteredVoyages?.length > 0 ? (
              <ul data-testid={`voyages-list-${tabName}`}>
                {filteredVoyages?.map(
                  (voyage: VoyageSummary, index: number) => (
                    <Li
                      key={index}
                      selectVoyage={() => handleSelectVoyage(voyage)}
                    >
                      <div className="flex flex-col">
                        <Typography variant="h200" className="text-silver-100">
                          Voyage #{voyage?.VoyageNumber}
                        </Typography>
                        <Typography
                          variant="body-small"
                          className="text-silver-100"
                        >
                          {formatVoyageTime(
                            searchVoyageTime(voyage).departureTime.value
                          )}
                          <MdArrowRightAlt size={20} className="inline mb-1" />
                          {formatVoyageTime(
                            searchVoyageTime(voyage).arrivalTime.value
                          )}
                        </Typography>
                        <Typography
                          variant="body-small"
                          className="text-silver-90"
                        >
                          {voyage?.Charterer}
                        </Typography>
                      </div>
                    </Li>
                  )
                )}
              </ul>
            ) : (
              <Typography className="ml-4">
                There are no {tabName?.toLowerCase()} voyages available
              </Typography>
            )}
          </div>
        )}
      </TabContent>
    </>
  ) : (
    <div className="flex flex-col w-full h-[50vh] center items-center justify-center">
      <Typography variant="h500" className="mt-4">
        No Voyages found.
      </Typography>
      <Typography className="mt-4 text-center max-w-[450px]">
        We have no Voyages for this vessel.
      </Typography>
    </div>
  );
};
