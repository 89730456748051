import { CollapsibleContainer } from '@org-crowley/enterprise-react-component-library';
import { Vessel } from '../../../models/Vessel';
import {
  formatDirection,
  formatUnixTimestamp
} from '../../../utils/formatVesselInfo';
import { formatBoolean, formatValue } from '../../../utils/vesselRegistryUtils';
import { VesselPosition } from '../../VesselPosition/VesselPosition';
import { VesselValuesDisplay } from '../../VesselValuesDisplay/VesselValuesDisplay';

interface CollapsibleMovementInfoProps {
  vessel: Vessel;
}

const CollapsibleMovementInfo = ({ vessel }: CollapsibleMovementInfoProps) => {
  const movementValues: { [key: string]: string | number | undefined }[] = [
    {
      'Detailed Status': vessel.StatusDetail,
      'Course Over Ground': formatValue(
        formatDirection,
        vessel.CourseOverGround
      ),
      Draught: vessel.Draught,
      'Dead weight tonnage': vessel.DWT,
      Heading: formatValue(formatDirection, vessel.Heading),
      'Speed over ground': vessel.SpeedOverGround,
      'On berth': formatValue(formatBoolean, vessel.OnBerth),
      'Previous Port ADT': formatValue(
        formatUnixTimestamp,
        vessel.PreviousPortADT
      ),
      'Previous Port Name': vessel.PreviousPortName,
      'Previous Port Country': vessel.PreviousPortCountry,
      'Next Port Name': vessel.NextPort,
      'Next Port ETA': formatValue(formatUnixTimestamp, vessel.ETA),
      Width: vessel.Width
    }
  ];

  return (
    <CollapsibleContainer
      heading="Movement details"
      className="absolute z-10 bg-white rounded-md w-full md:w-64 md:m-2"
    >
      <>
        <VesselPosition
          latitude={vessel.Latitude}
          longitude={vessel.Longitude}
          AISLastUpdated={vessel.AISLastUpdated}
        />
        <hr className="text-silver-30 py-1" />
        <div className="max-h-80 md:max-h-96 overflow-auto px-2">
          <VesselValuesDisplay values={movementValues} />
        </div>
      </>
    </CollapsibleContainer>
  );
};

export default CollapsibleMovementInfo;
