export enum MapSourceId {
  NOAAChart = 'noaa-chart-source',
  GebcoBathymetry = 'gebco-bathymetry-source',
  GebcoFeatureNames = 'gebco-feature-names-source',
  CycloneTrack = 'cyclone-track-source',
  CycloneForecast = 'cyclone-forecast-source',
  CycloneTrackLine = 'cyclone-track-line-source',
  CycloneForecastLine = 'cyclone-forecast-line-source',
  CycloneBreakPointAlerts = 'cyclone-breakpoint-alerts-source',
  CycloneErrorCone = 'cyclone-error-cone-source',
  Noaa123RulePolygons = 'noaa-123-rule-polygons-source',
  WindQuadrant34KtPolygons = 'wind-quadrant-34kt-polygons-source',
  WindQuadrant50KtPolygons = 'wind-quadrant-50kt-polygons-source',
  WindQuadrant64KtPolygons = 'wind-quadrant-64kt-polygons-source',
  WindQuadrant34KtLines = 'wind-quadrant-34kt-lines-source',
  WindQuadrant50KtLines = 'wind-quadrant-50kt-lines-source',
  WindQuadrant64KtLines = 'wind-quadrant-64kt-lines-source',
  VesselForecastLine = 'vessel-forecast-line-source',
  VesselForecastPoint = 'vessel-forecast-point-source',
  CircleIntersectLines = 'circle-intersect-lines-source',
  VesselCircle = 'vessel-circle-source'
}

export enum MapLayerId {
  NOAAChart = 'noaa-chart-layer',
  GebcoBathymetry = 'gebco-bathymetry-layer',
  GebcoFeatureNames = 'gebco-feature-names-layer',
  CycloneTrack = 'cyclone-track-layer',
  CycloneForecast = 'cyclone-forecast-layer',
  CycloneTrackLine = 'cyclone-track-line-layer',
  CycloneForecastLine = 'cyclone-forecast-line-layer',
  CycloneBreakPointAlerts = 'cyclone-breakpoint-alerts-layer',
  CycloneErrorCone = 'cyclone-error-cone-layer',
  Noaa123RulePolygons = 'noaa-123-rule-polygons-layer',
  WindQuadrant34KtPolygons = 'wind-quadrant-34kt-polygons-layer',
  WindQuadrant50KtPolygons = 'wind-quadrant-50kt-polygons-layer',
  WindQuadrant64KtPolygons = 'wind-quadrant-64kt-polygons-layer',
  WindQuadrant34KtLines = 'wind-quadrant-34kt-lines-layer',
  WindQuadrant50KtLines = 'wind-quadrant-50kt-lines-layer',
  WindQuadrant64KtLines = 'wind-quadrant-64kt-lines-layer',
  VesselForecastLine = 'vessel-forecast-line-layer',
  VesselForecastPoint = 'vessel-forecast-point-layer',
  CircleIntersectLines = 'circle-intersect-lines-layer',
  VesselCircle = 'vessel-circle-layer'
}
