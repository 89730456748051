import { Routes } from '../../../models/Route';
import { MapLoading } from './MapLoading/MapLoading';
import { MapMessage, MessageSeverity } from './MapMessage/MapMessage';

export interface MapInfoProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routeError: any;
  routeErrorText: string | null | undefined;
  routeData: Routes | null | undefined;
  shouldFetchRoutes: boolean | undefined;
  selectedLayerName: string;
}

const MapInfo = ({
  routeError,
  routeErrorText,
  routeData,
  shouldFetchRoutes,
  selectedLayerName
}: MapInfoProps) => {
  return (
    <>
      {(routeError || routeErrorText) && (
        <>
          <MapMessage
            messageText={routeErrorText ?? 'An unknown error occurred'}
            messageSeverity={MessageSeverity.ERROR}
          />
        </>
      )}

      {!routeError && !routeData && shouldFetchRoutes && (
        <MapLoading
          loadingText="Fetching routes..."
          selectedLayerName={selectedLayerName}
        />
      )}
    </>
  );
};

export default MapInfo;
