import { MouseEvent } from 'react';
import styles from '../Map.module.css';
import { Vessel } from '../../../models/Vessel';
import { BATHYMETRY_LAYER, LIGHT_LAYER, PopupInfoState } from '../Map';
import MobileSelectedPopupContent from './MobileSelectedPopupContent/MobileSelectedPopupContent';
import { RESPONSIVE_BREAKPOINTS } from '@org-crowley/enterprise-react-component-library';
import { RouteAction, RouteState } from '../../../reducers/routeReducer';
import { Dispatch, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import PinMarker from './PinMarker/PinMarker';
import { Location } from '../VesselMarkers/VesselMarker/VesselMarker';
import DefaultPopup from './DefaultPopup/DefaultPopup';

export const POPUP_VERTICAL_OFFSET_PX = 15;

export interface MapPopupsProps {
  showVesselNames: boolean;
  vessels: Vessel[];
  windowWidth: number;
  selectedPopupInfo: PopupInfoState | undefined;
  routeState: RouteState;
  routeDispatch: Dispatch<RouteAction>;
  showHoverPopup: boolean;
  hoverPopupInfo: PopupInfoState | undefined;
  clickMarker: Location | undefined;
  setClickMarker: Dispatch<SetStateAction<Location | undefined>>;
  selectedLayerName: string;
}

const MapPopups = ({
  showVesselNames,
  vessels,
  windowWidth,
  selectedPopupInfo,
  routeState,
  routeDispatch,
  showHoverPopup,
  hoverPopupInfo,
  clickMarker,
  setClickMarker,
  selectedLayerName
}: MapPopupsProps) => {
  const removePin = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setClickMarker(undefined);
  };

  return (
    <>
      {showVesselNames &&
        vessels.map((vessel) => (
          <DefaultPopup
            key={vessel.AssetNumber}
            longitude={vessel.Longitude}
            latitude={vessel.Latitude}
          >
            {vessel.VesselName}
          </DefaultPopup>
        ))}

      {
        // Selected Vessel Popup
        !!selectedPopupInfo && (
          <DefaultPopup
            longitude={selectedPopupInfo.longitude}
            latitude={selectedPopupInfo.latitude}
          >
            {windowWidth <= RESPONSIVE_BREAKPOINTS.medium &&
            selectedPopupInfo.vessel ? (
              <MobileSelectedPopupContent
                vesselInfo={selectedPopupInfo.vessel}
                routeState={routeState}
                routeDispatch={routeDispatch}
              />
            ) : (
              selectedPopupInfo.message
            )}
          </DefaultPopup>
        )
      }

      {
        // Hover Popup. Only show's after delay, and if info is not the same as the selected ship
        !!showHoverPopup &&
          !!hoverPopupInfo &&
          hoverPopupInfo.id !== selectedPopupInfo?.id && (
            <DefaultPopup
              longitude={hoverPopupInfo.longitude}
              latitude={hoverPopupInfo.latitude}
              className={styles.dark}
            >
              {hoverPopupInfo.message}
            </DefaultPopup>
          )
      }

      {!isEmpty(clickMarker) && (
        <PinMarker
          pinMarker={clickMarker}
          removePin={removePin}
          variant={
            selectedLayerName === LIGHT_LAYER.name ||
            selectedLayerName === BATHYMETRY_LAYER.name
              ? 'dark'
              : 'light'
          }
        />
      )}
    </>
  );
};

export default MapPopups;
