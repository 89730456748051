import { FeatureCollection, Geometry, GeoJsonProperties } from 'geojson';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Layer, Source } from 'react-map-gl';
import { CycloneProximityFlag, Vessel } from '../../../../models/Vessel';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

/**
 * Hours from current time into the past to allow
 * cyclone flag to be valid.
 */
const LAST_UPDATED_THRESHOLD_HOURS = 3;

export const createVesselPoints = (vessels: Vessel[], timestamp: DateTime) => {
  const results: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };

  const earliestTime = timestamp.minus({
    hours: LAST_UPDATED_THRESHOLD_HOURS
  });

  if (vessels && vessels.length > 0) {
    vessels
      .filter(
        (vessel) =>
          CycloneProximityFlag.Yes === vessel.CycloneProximityFlag &&
          vessel.CycloneFlagLastUpdatedUTC &&
          vessel.CycloneFlagLastUpdatedUTC >= earliestTime.toMillis()
      )
      .forEach((vessel) => {
        results.features.push({
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [vessel.Longitude, vessel.Latitude]
          }
        });
      });
  }

  return results;
};

interface VesselCircleLayerProps {
  vessels: Vessel[];
}

const VesselCircleLayer = ({ vessels }: VesselCircleLayerProps) => {
  const vesselPoints = useMemo(() => {
    return createVesselPoints(vessels, DateTime.now());
  }, [vessels]);

  return (
    <Source id={MapSourceId.VesselCircle} type="geojson" data={vesselPoints}>
      <Layer
        id={MapLayerId.VesselCircle}
        type="circle"
        paint={{
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 10, 10, 20],
          'circle-opacity': 0,
          'circle-stroke-width': 2,
          'circle-stroke-color': '#C50004'
        }}
      />
    </Source>
  );
};

export default VesselCircleLayer;
