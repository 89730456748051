import { isNil } from 'lodash';
import { formatDateTime } from '../../utils/formatDateTime';
import { Typography } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';

interface LastPositionUpdateProps {
  updatedAt: number | undefined;
  updatedBy: string | undefined;
  className?: string;
}

const LastUpdate = ({
  updatedAt,
  updatedBy,
  className
}: LastPositionUpdateProps) => {
  let lastUpdated = 'Updated: No data';
  if (!isNil(updatedAt)) {
    const { formattedTime, formattedDate, formattedZone } =
      formatDateTime(updatedAt);
    const updatedByText = updatedBy ? ` by ${updatedBy}` : '';
    lastUpdated = `Last Updated on ${formattedDate} ${formattedTime} ${formattedZone}${updatedByText}`;
  }
  return (
    <Typography className={cn('text-h100 text-silver-90', className)}>
      {lastUpdated}
    </Typography>
  );
};

export default LastUpdate;
