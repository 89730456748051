import { Dispatch, SetStateAction, useMemo } from 'react';
import { Vessel } from '../../../models/Vessel';
import VesselMarker from './VesselMarker/VesselMarker';
import { PopupInfoState } from '../Map';

export interface VesselMarkersProps {
  vessels: Vessel[];
  selectedLayerName: string;
  setHoverPopupInfo: Dispatch<SetStateAction<PopupInfoState | undefined>>;
}

const VesselMarkers = ({
  vessels,
  selectedLayerName,
  setHoverPopupInfo
}: VesselMarkersProps) => {
  // Build Markers When Vessels is Updated
  const markers = useMemo(() => {
    return vessels.map((vesselInfo) => {
      return (
        <VesselMarker
          key={`${vesselInfo.PK}_${vesselInfo.SK}`}
          id={vesselInfo.AssetNumber}
          status={vesselInfo.Status}
          location={{
            latitude: vesselInfo.Latitude,
            longitude: vesselInfo.Longitude
          }}
          heading={vesselInfo.Heading}
          vesselName={vesselInfo.VesselName}
          hoverPopupInfoSetter={setHoverPopupInfo}
          mapLayer={selectedLayerName}
        />
      );
    });
  }, [vessels, selectedLayerName, setHoverPopupInfo]);

  return <>{markers}</>;
};

export default VesselMarkers;
