import { RESPONSIVE_BREAKPOINTS } from '@org-crowley/enterprise-react-component-library';
import { Dispatch, SetStateAction } from 'react';
import {
  AttributionControl,
  NavigationControl,
  ScaleControl
} from 'react-map-gl';
import { Route, Routes } from '../../../models/Route';
import LayerControl from './LayerControl/LayerControl';
import MapLegend from './MapLegend/MapLegend';
import SpeedOverGroundLegend from './SpeedOverGroundLegend/SpeedOverGroundLegend';
import WindFieldLegend from './WindFieldLegend/WindFieldLegend';
import {
  HIGH_SPEED,
  LOW_SPEED,
  MapLayer,
  MapOptions,
  MIDDLE_SPEED
} from '../Map';

export interface MapControlsProps {
  currentRouteData?: Routes | null | undefined;
  selectedRoute?: Route | null | undefined;
  showRouteHeatMap?: boolean | undefined;
  windowWidth: number;
  mapLayerProperties: MapLayer;
  setMapLayerProperties: Dispatch<SetStateAction<MapLayer>>;
  mapOptionProperties: MapOptions;
  setMapOptionProperties: Dispatch<SetStateAction<MapOptions>>;
  windFieldOpacity: number;
  setWindFieldOpacity: Dispatch<SetStateAction<number>>;
}

const MapControls = ({
  currentRouteData,
  selectedRoute,
  showRouteHeatMap,
  windowWidth,
  mapLayerProperties,
  setMapLayerProperties,
  mapOptionProperties,
  setMapOptionProperties,
  windFieldOpacity,
  setWindFieldOpacity
}: MapControlsProps) => {
  return (
    <>
      {((currentRouteData && showRouteHeatMap && !!selectedRoute) ||
        (mapOptionProperties.showCyclones &&
          mapOptionProperties.showCycloneWindFields)) && (
        <MapLegend>
          {currentRouteData && showRouteHeatMap && !!selectedRoute && (
            <SpeedOverGroundLegend
              minSpeedOverGround={selectedRoute.lowSpeedOverGround ?? -1}
              maxSpeedOverGround={selectedRoute.highSpeedOverGround ?? -1}
              lowGradient={LOW_SPEED}
              middleGradient={MIDDLE_SPEED}
              highGradient={HIGH_SPEED}
            />
          )}
          {mapOptionProperties.showCyclones &&
            mapOptionProperties.showCycloneWindFields && (
              <WindFieldLegend
                windFieldOpacity={windFieldOpacity}
                setWindFieldOpacity={setWindFieldOpacity}
              />
            )}
        </MapLegend>
      )}

      <ScaleControl position="bottom-left" unit="nautical" />

      <AttributionControl
        compact={true}
        position="bottom-left"
        customAttribution={[
          'Imagery reproduced from the GEBCO_2021 Grid, GEBCO Compilation Group (2021) GEBCO 2021 Grid (doi:10.5285/c6612cbe-50b3-0cff-e053-6c86abc09f8f)',
          'IHO-IOC GEBCO Gazetteer of Undersea Feature Names, www.gebco.net'
        ]}
        style={{ maxWidth: '90%', cursor: 'default' }}
      />

      {windowWidth <= RESPONSIVE_BREAKPOINTS.medium && (
        <NavigationControl showCompass={true} position="bottom-right" />
      )}

      {windowWidth > RESPONSIVE_BREAKPOINTS.medium && (
        <NavigationControl showCompass={false} position="bottom-right" />
      )}

      <LayerControl
        mapLayerProperties={mapLayerProperties}
        onMapLayerPropertiesChange={setMapLayerProperties}
        windowWidth={windowWidth}
        mapOptionProperties={mapOptionProperties}
        setMapOptionProperties={setMapOptionProperties}
      />
    </>
  );
};

export default MapControls;
