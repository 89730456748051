import {
  Tooltip,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { RangeSlider } from '../../../RangeSlider/RangeSlider';

interface WindFieldLegendProps {
  windFieldOpacity: number;
  setWindFieldOpacity: Dispatch<SetStateAction<number>>;
}

const WindFieldLegend = ({
  windFieldOpacity,
  setWindFieldOpacity
}: WindFieldLegendProps) => {
  const onSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWindFieldOpacity(Number(e.target.value));
  };

  return (
    <>
      <div className="text-center">
        <Typography variant="h300" as="h4" className="text-silver-100">
          Wind Field
        </Typography>
      </div>

      <div className="grid grid-cols-3 justify-items-center items-center gap-1">
        <div className="w-8 h-8 rounded-full bg-[#AB0004] opacity-75"></div>
        <Typography variant="body-small" className="text-silver-90 col-span-2">
          <Tooltip tooltipText="Estimated Danger Area" placement="bottom-start">
            Est. Danger Area
          </Tooltip>
        </Typography>

        <div className="w-8 h-8 rounded-full bg-[#9C70FF]"></div>
        <Typography variant="body-small" className="text-silver-90 col-span-2">
          34 Knots
        </Typography>

        <div className="w-8 h-8 rounded-full bg-[#FFCC00]"></div>
        <Typography variant="body-small" className="text-silver-90 col-span-2">
          50 Knots
        </Typography>

        <div className="w-8 h-8 rounded-full bg-[#00A300]"></div>
        <Typography variant="body-small" className="text-silver-90 col-span-2">
          64 Knots
        </Typography>

        <Typography
          variant="body-small"
          className="text-silver-90 col-span-3 mt-2"
        >
          Opacity
        </Typography>
        <div className="col-span-3">
          <RangeSlider
            id="wind-field-opacity-slider"
            limits={{ min: 0, max: 100 }}
            labels={{ min: 0, max: 100 }}
            value={windFieldOpacity}
            onChange={(e) => onSliderChange(e)}
          />
        </div>
      </div>
    </>
  );
};

export default WindFieldLegend;
