import {
  Spinner,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { BATHYMETRY_LAYER, LIGHT_LAYER } from '../../Map';

export interface MapLoadingProps {
  loadingText?: string;
  selectedLayerName: string;
}

export function MapLoading({
  loadingText,
  selectedLayerName
}: MapLoadingProps) {
  return (
    <div
      className={cn(
        'z-20',
        'absolute',
        'flex',
        'w-full',
        'h-full',
        'items-center',
        'justify-center',
        'bg-blue-0',
        'bg-opacity-10'
      )}
    >
      <Spinner />
      <Typography
        variant="h500"
        as="h5"
        className={cn(
          'ml-2',
          `${
            selectedLayerName === LIGHT_LAYER.name ||
            selectedLayerName === BATHYMETRY_LAYER.name
              ? 'text-black'
              : 'text-silver-10'
          }`
        )}
      >
        {`${loadingText ?? 'One moment please...'}`}
      </Typography>
    </div>
  );
}
