import { isNil } from 'lodash';
import { CardStatus } from '../components/VesselRegistry/VesselRegistryCard/VesselRegistryCard';

export enum VESSEL_TYPE {
  TANKER = 'Tanker',
  ATB = 'ATB',
  CONTAINER = 'Container',
  BARGE = 'Barge',
  ANY = 'Any'
}

export const getMatchingVesselType = (
  vesselType?: string | null | undefined
): VESSEL_TYPE => {
  const matchingType = Object.values(VESSEL_TYPE).find((type) =>
    vesselType?.toLowerCase().includes(type.toLowerCase())
  );
  return (matchingType as VESSEL_TYPE) || VESSEL_TYPE.ANY;
};

export const formatValue = (
  formatter: (value: any) => string | number,
  value: any
) => {
  if (!(isNil(value) || isNaN(value))) {
    return formatter(value);
  } else {
    return undefined;
  }
};

export const formatBoolean = (value: boolean) => (value ? 'Yes' : 'No');

export const getStatus = (isActive: boolean): CardStatus => {
  if (!isActive) {
    return CardStatus.DEACTIVATED;
  }
  /**
   * TODO: Validate MISSING_INFO status
   * This function will depend on the
   * model that we are validating
   */
  return CardStatus.FILLED_IN;
};
