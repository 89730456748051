import {
  Typography,
  Tooltip
} from '@org-crowley/enterprise-react-component-library';
import { CargoItem } from '../../models/Voyage';
import { ACTIVITY_VARIANTS } from './constants';

interface CargoItemsTableProps {
  cargoItems: CargoItem[];
}

export default function CargoItemsTable({ cargoItems }: CargoItemsTableProps) {
  return (
    <table className="w-full" data-testid="cargo-table">
      <thead>
        <tr className="bg-silver-5 border-b border-collapse border-blue-0 h-8">
          <th>
            <Typography variant="h100" className="text-silver-50">
              Name
            </Typography>
          </th>
          <th>
            <Typography variant="h100" className="text-silver-50">
              Activity
            </Typography>
          </th>
          <th>
            <Typography variant="h100" className="text-silver-50">
              Lift Qty
            </Typography>
          </th>
        </tr>
      </thead>
      <tbody>
        {cargoItems.map((cargoItem) => (
          <tr
            className="border-b border-collapse border-blue-0 h-8"
            key={cargoItem.SK}
          >
            <td className="text-center">
              <Typography variant="h100" className="font-normal">
                {cargoItem.CargoShortName}
              </Typography>
            </td>
            <td className="text-center">
              <Typography variant="h100" className="font-normal">
                {ACTIVITY_VARIANTS[cargoItem.FunctionCode].description}
              </Typography>
            </td>
            <td className="text-center">
              <Typography variant="h100" className="font-normal">
                {cargoItem.BLProductNet ? (
                  <Tooltip
                    tooltipText={`Estimated Cargo: ${cargoItem.NominatedLiftQuantity}`}
                    placement={'right'}
                  >
                    {cargoItem.BLProductNet}
                  </Tooltip>
                ) : (
                  cargoItem.NominatedLiftQuantity
                )}
              </Typography>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
