import cn from 'classnames';
import React, { ReactNode } from 'react';

export interface MapLegendProps {
  children?: ReactNode;
}

const MapLegend = ({ children }: MapLegendProps) => {
  const actualChildren: ReactNode[] = [];
  React.Children.forEach(children, (child) => {
    if (child) {
      actualChildren.push(child);
    }
  });

  return (
    <div
      className={cn(
        'mapboxgl-ctrl',
        'mapboxgl-ctrl-group',
        'z-10',
        'absolute',
        'top-2.5',
        'right-2.5',
        'p-2'
      )}
    >
      {actualChildren.map((child, index) => {
        return (
          <div
            key={`maplegend-${index}`}
            className={`${index > 0 ? 'mt-2' : ''}`}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default MapLegend;
