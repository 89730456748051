import cn from 'classnames';
import React from 'react';

interface Props {
  id: string;
  checked: boolean;
  onChange: () => void;
  children: React.ReactNode;
  title?: string;
  toggleClassName?: string;
}

export function Toggle({
  id,
  checked,
  onChange,
  children,
  title,
  toggleClassName
}: Props) {
  return (
    <label
      htmlFor={id}
      className="inline-flex relative items-center cursor-pointer"
      title={title}
    >
      <input
        type="checkbox"
        value=""
        id={id}
        className="sr-only peer"
        checked={checked}
        onChange={onChange}
        title={title}
      />
      <div
        className={cn(
          'w-8',
          'h-4',
          'bg-gray-50',
          'peer-focus:outline-none',
          'rounded-full',
          'peer',
          'peer-checked:after:translate-x-full',
          "peer-checked:after:border-white after:content-['']",
          'after:absolute',
          'after:top-[4px]',
          'after:left-[0px]',
          'after:bg-white',
          'after:border-gray-50',
          'after:border',
          'after:rounded-full',
          'after:h-4',
          'after:w-4',
          'after:transition-all',
          'peer-checked:bg-blue-80',
          `${toggleClassName ?? ''}`
        )}
      ></div>
      <span className="ml-2">{children}</span>
    </label>
  );
}
