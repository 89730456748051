import { Vessel } from 'models/Vessel';
import { formatDateTime } from './formatDateTime';

//This array is ordered, 0th member is North and moves clockwise
const CARDINAL_DISPLAYS = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];

export const formatString = (value: string): string => value;
export const formatNumber = (value: number): number => value;
export const formatBoolean = (value: boolean): string => String(value);
// export const formatDirection = (value: number): string =>
//   `${Math.round(value * 1000) / 1000}°`;
export const formatDirection = (value: number): string =>
  `${
    CARDINAL_DISPLAYS[
      Math.round(value / (360 / CARDINAL_DISPLAYS.length)) %
        CARDINAL_DISPLAYS.length
    ]
  } (${value}°)`;
export const formatUnixTimestamp = (value: number): string => {
  const dateTime = formatDateTime(value);
  return dateTime.formattedTime &&
    dateTime.formattedDate &&
    dateTime.formattedZone
    ? `${dateTime.formattedTime} on ${dateTime.formattedDate} (${dateTime.formattedZone})`
    : '';
};

export const VESSEL_INFO_DISPLAY: Record<
  keyof Vessel,
  {
    formatter: (value: any) => string | number;
    displayName: string;
    displayUnit?: string;
  }
> = {
  Active: { formatter: formatBoolean, displayName: 'Active' },
  ActivityCode: { formatter: formatNumber, displayName: 'Activity Code' },
  AISLastUpdated: {
    formatter: formatUnixTimestamp,
    displayName: 'AIS Last Updated'
  },
  AssetNumber: { formatter: formatNumber, displayName: 'Asset Number' },
  Beam: { formatter: formatNumber, displayName: 'Beam', displayUnit: 'ft' },
  CallSign: { formatter: formatString, displayName: 'Call Sign' },
  Coast: { formatter: formatString, displayName: 'Coast' },
  CreatedBy: { formatter: formatString, displayName: 'Created By' },
  CourseOverGround: {
    formatter: formatDirection,
    displayName: 'Course Over Ground'
  },
  CycloneFlagLastUpdatedUTC: {
    formatter: formatUnixTimestamp,
    displayName: 'Cyclone Flag Last Updated'
  },
  CycloneProximityFlag: {
    formatter: formatString,
    displayName: 'Cyclone Proximity Flag'
  },
  NextPort: { formatter: formatString, displayName: 'Next Port' },
  DateOfBuild: { formatter: formatUnixTimestamp, displayName: 'Date of Build' },
  Draught: { formatter: formatNumber, displayName: 'Draught' },
  DWT: {
    formatter: formatNumber,
    displayName: 'Dead Weight Tonnage'
  },
  ETA: { formatter: formatUnixTimestamp, displayName: 'Next Port ETA' },
  Flag: { formatter: formatString, displayName: 'Flag' },
  Fleet: { formatter: formatString, displayName: 'Fleet' },
  GrossTonnage: { formatter: formatNumber, displayName: 'Gross Tonnage' },
  Heading: { formatter: formatDirection, displayName: 'Heading' },
  IMONumber: { formatter: formatNumber, displayName: 'IMO Number' },
  IHSETA: { formatter: formatString, displayName: 'IHS ETA' },
  IHSNextPort: { formatter: formatString, displayName: 'IHS Next Port' },
  LatestComment: { formatter: formatString, displayName: 'Latest Comment' },
  Latitude: { formatter: formatNumber, displayName: 'Latitude' },
  LOA: { formatter: formatNumber, displayName: 'Length Over All' },
  Longitude: { formatter: formatNumber, displayName: 'Longitude' },
  MessageTimestamp: {
    formatter: formatUnixTimestamp,
    displayName: 'Message Date Time'
  },
  MMSINumber: { formatter: formatNumber, displayName: 'MMSI Number' },
  NetTonnage: { formatter: formatNumber, displayName: 'Net Tonnage' },
  OfficialNumber: { formatter: formatString, displayName: 'Official Number' },
  OnBerth: { formatter: formatBoolean, displayName: 'On Berth' },
  Ownership: { formatter: formatString, displayName: 'Ownership' },
  PK: { formatter: formatString, displayName: 'Dynamo Partition Key' },
  PreviousPortADT: {
    formatter: formatUnixTimestamp,
    displayName: 'Previous Port ADT'
  },
  PreviousPortCountry: {
    formatter: formatString,
    displayName: 'Previous Port Country'
  },
  PreviousPortName: {
    formatter: formatString,
    displayName: 'Previous Port Name'
  },
  ReceivedDate: { formatter: formatNumber, displayName: 'Received Date' },
  SK: { formatter: formatString, displayName: 'Dynamo Sort Key' },
  SpeedOverGround: {
    formatter: formatNumber,
    displayName: 'Speed Over Ground',
    displayUnit: 'knots'
  },
  Status: { formatter: formatString, displayName: 'Status' },
  StatusDetail: { formatter: formatString, displayName: 'Detailed Status' },
  Team: { formatter: formatString, displayName: 'Team' },
  TEU: {
    formatter: formatNumber,
    displayName: 'Twenty Foot Equivalent Unit'
  },
  UpdatedAt: { formatter: formatUnixTimestamp, displayName: 'Updated At' },
  UpdatedBy: { formatter: formatString, displayName: 'Updated By' },
  UpdatedByUser: { formatter: formatString, displayName: 'Updated By User' },
  VesselClass: { formatter: formatString, displayName: 'Class' },
  VesselGroup: { formatter: formatString, displayName: 'Group' },
  VesselName: { formatter: formatString, displayName: 'Vessel Name' },
  VesselOperator: { formatter: formatString, displayName: 'Operator' },
  VesselOwner: { formatter: formatString, displayName: 'Owner' },
  VesselType: { formatter: formatString, displayName: 'Type' },
  VoyageNumber: { formatter: formatNumber, displayName: 'Voyage Number' },
  Width: { formatter: formatNumber, displayName: 'Width', displayUnit: 'm' }
};
