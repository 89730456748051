import { Route, Routes } from '../models/Route';

export enum RouteActionType {
  UPDATE_ALL = 'UPDATE_ALL',
  FETCH_ROUTES_AND_ROUTE_DATA = 'FETCH_ROUTES_AND_ROUTE_DATA',
  FETCH_ROUTES_AND_ERROR_TEXT = 'FETCH_ROUTES_AND_ERROR_TEXT',
  TOGGLE_ROUTE_HEAT_MAP = 'TOGGLE_ROUTE_HEAT_MAP',
  UPDATE_ROUTE_TIMELINE = 'UPDATE_ROUTE_TIMELINE'
}
export interface RouteState {
  shouldFetchRoutes?: boolean;
  currentRouteData?: Routes | null;
  routeErrorText?: string | null;
  selectedRoute?: Route | null;
  showRouteHeatMap?: boolean;
  currentTime?: number | null;
  routeStartTimestamp?: number | null;
}

export interface RouteAction {
  type: RouteActionType;
  payload: RouteState;
}

export const routeReducer = (
  state: RouteState,
  { type, payload }: RouteAction
): RouteState => {
  switch (type) {
    case 'UPDATE_ALL':
      return {
        ...state,
        shouldFetchRoutes: payload.shouldFetchRoutes,
        currentRouteData: payload.currentRouteData,
        routeErrorText: payload.routeErrorText,
        selectedRoute: payload.selectedRoute,
        currentTime: payload.currentTime,
        showRouteHeatMap: payload.showRouteHeatMap,
        routeStartTimestamp: payload.routeStartTimestamp
      };
    case 'FETCH_ROUTES_AND_ROUTE_DATA':
      return {
        ...state,
        shouldFetchRoutes: payload.shouldFetchRoutes,
        currentRouteData: payload.currentRouteData,
        selectedRoute: payload.selectedRoute
      };
    case 'FETCH_ROUTES_AND_ERROR_TEXT':
      return {
        ...state,
        shouldFetchRoutes: payload.shouldFetchRoutes,
        routeErrorText: payload.routeErrorText,
        selectedRoute: payload.selectedRoute,
        routeStartTimestamp: payload.routeStartTimestamp
      };
    case 'TOGGLE_ROUTE_HEAT_MAP':
      return {
        ...state,
        showRouteHeatMap: !state.showRouteHeatMap
      };
    case 'UPDATE_ROUTE_TIMELINE':
      return {
        ...state,
        currentTime: payload.currentTime
      };
    default:
      return state;
  }
};
