import { useWindowWidth } from '@react-hook/window-size';
import { Popup } from 'react-map-gl';
import { POPUP_VERTICAL_OFFSET_PX } from '../MapPopups';
import cn from 'classnames';
import styles from '../../Map.module.css';
import { ReactNode } from 'react';

export interface DefaultPopupProps {
  children: ReactNode;
  latitude: number;
  longitude: number;
  className?: string;
}

const DefaultPopup = ({
  children,
  latitude,
  longitude,
  className = ''
}: DefaultPopupProps) => {
  const windowWidth = useWindowWidth();

  return (
    <Popup
      longitude={longitude}
      latitude={latitude}
      anchor="bottom"
      closeButton={false}
      className={cn(styles.popup, className)}
      offset={POPUP_VERTICAL_OFFSET_PX}
      focusAfterOpen={false}
      closeOnClick={false}
      closeOnMove={false}
      maxWidth={`${windowWidth * 0.8}px`}
    >
      {children}
    </Popup>
  );
};

export default DefaultPopup;
