import axios from 'axios';
import useSWR from 'swr';
import { CycloneData } from '../models/Cyclone';

export const cycloneFetcher = (url: string, token: string) =>
  axios
    .get<{ data: CycloneData }>(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      if (error.response) {
        switch (error.response.status) {
          case 404: {
            throw new Error('Cyclone data not found');
          }
          case 500: {
            throw new Error('Unexpected error occurred');
          }
          default: {
            throw new Error('Unexpected error occurred');
          }
        }
      } else if (error.request) {
        throw new Error('Error occurred sending request');
      } else {
        throw new Error('Unexpected error occurred');
      }
    });

export interface UseCyclonesProps {
  accessToken: string | undefined;
  timestamp?: number;
}

export const useCyclones = ({ accessToken }: UseCyclonesProps) => {
  const { data: cycloneData, error: cycloneError } = useSWR(
    () => {
      return `${process.env.NEXT_PUBLIC_API_BASE}/v2/cyclones`;
    },
    (key) => {
      if (accessToken) {
        return cycloneFetcher(key, accessToken);
      }
      return null;
    }
  );

  return { cycloneData, cycloneError };
};
