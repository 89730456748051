import { MapRef } from 'react-map-gl';
import {
  Feature,
  FeatureCollection,
  Geometry,
  GeoJsonProperties
} from 'geojson';
import mapboxgl, {
  GeoJSONSource,
  LngLatBoundsLike,
  LngLatLike,
  MapLayerMouseEvent
} from 'mapbox-gl';
import { bbox, distance } from '@turf/turf';
import { formatDirection } from './formatVesselInfo';
import { getFullDate } from '../components/VoyageOverview/VoyageOverview';
import {
  BATHYMETRY_LAYER,
  LIGHT_LAYER,
  MapOptions
} from '../components/Map/Map';
import { FeatureCoord, Layer, Route, Source } from '../models/Route';

const safeRemoveLayerAndSource = (
  map: MapRef | undefined,
  layerId: string,
  sourceId: string
) => {
  if (map?.getMap().getLayer(layerId)) {
    map.getMap().removeLayer(layerId);
  }

  if (map?.getMap().getSource(sourceId)) {
    map.getMap().removeSource(sourceId);
  }
};

const findFurthestDistance = (featureCoords: FeatureCoord[]) => {
  let maxDistance = 0;
  const origin: FeatureCoord = featureCoords[0];
  let currentDistance;
  featureCoords.forEach((featureCoord) => {
    if (
      origin.coords[0] !== featureCoord.coords[0] &&
      origin.coords[1] !== featureCoord.coords[1]
    ) {
      currentDistance = distance(origin.coords, featureCoord.coords, {
        units: 'nauticalmiles'
      });
      if (currentDistance > maxDistance) {
        maxDistance = currentDistance;
      }
    }
  });
  return maxDistance;
};

const animateRoute = (
  map: MapRef | undefined,
  featureCoords: FeatureCoord[],
  lineData: FeatureCollection<Geometry, GeoJsonProperties>
) => {
  const furthestDistance = findFurthestDistance(featureCoords);

  /* Create batches of points which have their combined
  distance between them up to 5% of the furthest
  distance for the entire route */
  let distanceIncrement = furthestDistance * 0.05;
  if (distanceIncrement < 1) {
    distanceIncrement = 1;
  }
  let currentDistance = 0;
  const lineFeatureBatches: Array<Array<FeatureCoord>> = [];
  let lineFeatures: Array<FeatureCoord> = [featureCoords[0]];
  for (let i = 1; i < featureCoords.length; i++) {
    currentDistance += distance(
      featureCoords[i - 1].coords,
      featureCoords[i].coords,
      { units: 'nauticalmiles' }
    );

    lineFeatures.push(featureCoords[i]);

    if (currentDistance >= distanceIncrement) {
      currentDistance = 0;
      lineFeatureBatches.push(lineFeatures);
      lineFeatures = [];
    }
  }

  if (lineFeatures.length > 0) {
    lineFeatureBatches.push(lineFeatures);
  }

  let previousLineFeature: FeatureCoord = featureCoords[0];
  map?.getMap().once('idle', () => {
    let i = 0;
    const lineTimer = setInterval(() => {
      if (i < lineFeatureBatches.length) {
        lineFeatureBatches[i].forEach((lineFeature) => {
          lineData.features.push({
            type: 'Feature',
            properties: {
              timestampMillisecondsUtc: lineFeature.timestampMillisecondsUtc
            },
            geometry: {
              type: 'LineString',
              coordinates: [previousLineFeature.coords, lineFeature.coords]
            }
          });
          previousLineFeature = lineFeature;
        });

        (
          map?.getMap().getSource(Source.HistoricalRoute) as GeoJSONSource
        )?.setData(lineData);
        i++;
      } else {
        clearInterval(lineTimer);
      }
    }, 50);
  });
};

const createTooltips = (map: MapRef | undefined, showRouteHeatMap: boolean) => {
  const popup = new mapboxgl.Popup({
    closeButton: false,
    closeOnClick: false
  });

  const popupLayer = showRouteHeatMap
    ? Layer.RouteHeatMap
    : Layer.HistoricalPoints;

  map?.getMap().on('mouseenter', popupLayer, (e: MapLayerMouseEvent) => {
    if (
      e &&
      e.features &&
      e.features.length > 0 &&
      e.features[0].geometry.type === 'Point'
    ) {
      map.getMap().getCanvas().style.cursor = 'pointer';
      const coordinates: LngLatLike =
        e.features[0].geometry.coordinates.slice() as LngLatLike;
      const featureProps = e.features[0].properties;
      const html = `Timestamp: ${featureProps?.timestamp}<br />Heading: ${featureProps?.heading}
        <br />Speed over ground: ${featureProps?.speedOverGroundDisplay}`;
      popup.setLngLat(coordinates).setHTML(html).addTo(map.getMap());
    }
  });

  map?.getMap().on('mouseleave', popupLayer, () => {
    map.getMap().getCanvas().style.cursor = '';
    popup.remove();
  });
};

const buildRoute = (
  map: MapRef | undefined,
  currentRoute: Route | undefined,
  selectedLayerName: string,
  showRouteHeatMap: boolean,
  lowGradient: string,
  middleGradient: string,
  highGradient: string,
  mapOptionProperties: MapOptions
) => {
  const featureCoords: FeatureCoord[] = [];

  if (currentRoute) {
    currentRoute.routePoints.forEach((routePoint) => {
      featureCoords.push({
        timestamp: getFullDate(routePoint.MessageTimestamp),
        timestampMillisecondsUtc: routePoint.MessageTimestamp,
        heading: formatDirection(routePoint.Heading),
        speedOverGround: routePoint.SpeedOverGround,
        speedOverGroundDisplay: `${routePoint.SpeedOverGround.toFixed(1)} Knot${
          routePoint.SpeedOverGround === 1 ? '' : 's'
        }`,
        coords: [routePoint.Longitude, routePoint.Latitude]
      });
    });

    const lineData: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: []
    };

    if (!showRouteHeatMap) {
      map?.getMap().addSource(Source.HistoricalRoute, {
        type: 'geojson',
        data: lineData,
        tolerance: 0.1
      });

      map?.getMap().addLayer({
        id: Layer.HistoricalRoute,
        type: 'line',
        source: Source.HistoricalRoute,
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': `${
            (selectedLayerName === LIGHT_LAYER.name ||
              selectedLayerName === BATHYMETRY_LAYER.name) &&
            !mapOptionProperties.showNoaaChart
              ? '#000000'
              : mapOptionProperties.showNoaaChart
              ? '#008000'
              : '#FFFFFF'
          }`,
          'line-width': ['interpolate', ['linear'], ['zoom'], 0, 4, 10, 1]
        }
      });
    }

    /* All points */
    const historicalPoints: Array<Feature<Geometry, GeoJsonProperties>> = [];
    featureCoords.forEach((featureCoord) => {
      historicalPoints.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: featureCoord.coords
        },
        properties: {
          timestamp: featureCoord.timestamp,
          timestampMillisecondsUtc: featureCoord.timestampMillisecondsUtc,
          heading: featureCoord.heading,
          speedOverGround: featureCoord.speedOverGround,
          speedOverGroundDisplay: featureCoord.speedOverGroundDisplay
        }
      });
    });

    const pointData: FeatureCollection<Geometry, GeoJsonProperties> = {
      type: 'FeatureCollection',
      features: []
    };

    map?.getMap().addSource(Source.HistoricalPoints, {
      type: 'geojson',
      data: pointData
    });

    if (!showRouteHeatMap) {
      map?.getMap().addLayer({
        id: Layer.HistoricalPoints,
        source: Source.HistoricalPoints,
        type: 'symbol',
        layout: {
          'icon-image': `${
            selectedLayerName === LIGHT_LAYER.name ||
            (selectedLayerName === BATHYMETRY_LAYER.name &&
              !mapOptionProperties.showNoaaChart)
              ? 'black-circle'
              : 'white-circle'
          }`,
          'icon-size': 0.5
        }
      });
    }

    pointData.features.push(...historicalPoints);
    (map?.getMap().getSource(Source.HistoricalPoints) as GeoJSONSource).setData(
      pointData
    );

    const boundingBox = bbox({
      type: 'FeatureCollection',
      features: pointData.features
    });

    const BOUNDING_BOX_PADDING = 150;
    map?.getMap().fitBounds(boundingBox as LngLatBoundsLike, {
      padding: BOUNDING_BOX_PADDING
    });

    if (!showRouteHeatMap) {
      animateRoute(map, featureCoords, lineData);
    }

    createTooltips(map, showRouteHeatMap);

    if (showRouteHeatMap) {
      const lowSpeed = currentRoute.lowSpeedOverGround ?? -1;
      const highSpeed = currentRoute.highSpeedOverGround ?? -1;

      map?.getMap().addLayer({
        id: Layer.RouteHeatMap,
        type: 'circle',
        source: Source.HistoricalPoints,
        paint: {
          'circle-color': {
            property: 'speedOverGround',
            stops: [
              [lowSpeed, `${lowGradient}`],
              [(lowSpeed + highSpeed) / 2, `${middleGradient}`],
              [highSpeed, `${highGradient}`]
            ]
          },
          'circle-radius': ['interpolate', ['linear'], ['zoom'], 0, 4, 10, 7],
          'circle-blur': ['interpolate', ['linear'], ['zoom'], 0, 2, 15, 0]
        }
      });
    }
  }
};

export const cleanupPreviousRoutes = (map: MapRef | undefined) => {
  map?.getMap().fire('closeAllPopups');
  safeRemoveLayerAndSource(map, Layer.RouteHeatMap, Source.RouteHeatMap);
  safeRemoveLayerAndSource(map, Layer.HistoricalRoute, Source.HistoricalRoute);
  safeRemoveLayerAndSource(
    map,
    Layer.HistoricalPoints,
    Source.HistoricalPoints
  );
};

export const addRouteToMap = (
  map: MapRef | undefined,
  currentRoute: Route | undefined,
  selectedLayerName: string,
  showRouteHeatMap: boolean,
  lowGradient: string,
  middleGradient: string,
  highGradient: string,
  mapOptionProperties: MapOptions
) => {
  if (map && map.getMap() && map.getMap().getStyle() && currentRoute) {
    cleanupPreviousRoutes(map);
    buildRoute(
      map,
      currentRoute,
      selectedLayerName,
      showRouteHeatMap,
      lowGradient,
      middleGradient,
      highGradient,
      mapOptionProperties
    );
  }
};

/** Updates the visual style of the current route on
 * the map based on the timestamp provided
 */
export const updateCurrentRoute = (
  map: MapRef | undefined,
  currentRoute: Route | undefined,
  timestampMillisecondsUtc: number,
  popup: mapboxgl.Popup
) => {
  const OPACITY_RESTRAINED = 0.1;
  const OPACITY_NORMAL = 1.0;
  const SHARED_CASE = [
    'case',
    ['>=', ['get', 'timestampMillisecondsUtc'], timestampMillisecondsUtc],
    OPACITY_RESTRAINED,
    OPACITY_NORMAL
  ];

  if (map?.getMap().getLayer(Layer.HistoricalPoints)) {
    map
      ?.getMap()
      .setPaintProperty(Layer.HistoricalPoints, 'icon-opacity', SHARED_CASE);
  }
  if (map?.getMap().getLayer(Layer.HistoricalRoute)) {
    map
      ?.getMap()
      .setPaintProperty(Layer.HistoricalRoute, 'line-opacity', SHARED_CASE);
  }
  if (map?.getMap().getLayer(Layer.RouteHeatMap)) {
    map
      ?.getMap()
      .setPaintProperty(Layer.RouteHeatMap, 'circle-opacity', SHARED_CASE);
  }

  const reversedPoints = currentRoute?.routePoints.slice().reverse();
  const lastPoint = reversedPoints?.at(0);
  const foundPoint = reversedPoints?.find(
    (point) => point.MessageTimestamp <= timestampMillisecondsUtc
  );
  if (map && foundPoint) {
    if (lastPoint?.MessageTimestamp === timestampMillisecondsUtc) {
      popup.remove();
    } else {
      const html = `${getFullDate(timestampMillisecondsUtc)}`;
      popup
        .setLngLat([foundPoint.Longitude, foundPoint.Latitude])
        .setHTML(html)
        .addTo(map.getMap());
    }
  }
};
