import { Layer, Source } from 'react-map-gl';
import { CycloneMapData } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

export interface CycloneBreakPointAlertsLayerProps {
  cycloneMapData: CycloneMapData;
}

const CycloneBreakPointAlertsLayer = ({
  cycloneMapData
}: CycloneBreakPointAlertsLayerProps) => {
  return (
    <Source
      id={MapSourceId.CycloneBreakPointAlerts}
      type="geojson"
      data={cycloneMapData.breakpointAlertData}
      generateId={true}
    >
      <Layer
        id={MapLayerId.CycloneBreakPointAlerts}
        type="line"
        layout={{ 'line-join': 'round', 'line-cap': 'round' }}
        paint={{
          'line-color': ['get', 'color'],
          'line-width': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            10,
            4
          ]
        }}
      ></Layer>
    </Source>
  );
};

export default CycloneBreakPointAlertsLayer;
