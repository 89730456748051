import { Layer, Source } from 'react-map-gl';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';
import { DEFAULT_BEFORE_LAYER_ID } from '../../Map';

const GEBCO_BATHYMETRY_TILES = [
  'https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=gebco_latest&WIDTH=256&HEIGHT=256&SRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}'
];

const GEBCO_FEATURE_NAME_TILES = [
  'https://gis.ngdc.noaa.gov/arcgis/services/IHO/undersea_features/MapServer/WMSServer?SERVICE=WMS&VERSION=1.1.1&REQUEST=GetMap&FORMAT=image/png&TRANSPARENT=true&LAYERS=0,1,2&WIDTH=256&HEIGHT=256&SRS=EPSG:3857&STYLES=&BBOX={bbox-epsg-3857}'
];

const NOAA_BEFORE_LAYER_ID = 'NOAA_CHART';

export interface BathymetryLayerProps {
  showNoaaChart: boolean;
}

const BathymetryLayer = ({ showNoaaChart }: BathymetryLayerProps) => {
  return (
    <>
      <Source
        id={MapSourceId.GebcoBathymetry}
        type="raster"
        tileSize={256}
        tiles={GEBCO_BATHYMETRY_TILES}
      >
        <Layer
          id={MapLayerId.GebcoBathymetry}
          type="raster"
          paint={{}}
          beforeId={
            showNoaaChart ? NOAA_BEFORE_LAYER_ID : DEFAULT_BEFORE_LAYER_ID
          }
        ></Layer>
      </Source>

      <Source
        id={MapSourceId.GebcoFeatureNames}
        type="raster"
        tileSize={256}
        tiles={GEBCO_FEATURE_NAME_TILES}
      >
        <Layer
          id={MapLayerId.GebcoFeatureNames}
          type="raster"
          paint={{}}
          beforeId={
            showNoaaChart ? NOAA_BEFORE_LAYER_ID : DEFAULT_BEFORE_LAYER_ID
          }
        ></Layer>
      </Source>
    </>
  );
};

export default BathymetryLayer;
