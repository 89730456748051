import { Layer, Source } from 'react-map-gl';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';
import { DEFAULT_BEFORE_LAYER_ID } from '../../Map';

const NOAA_CHART_TILES = [
  'https://gis.charttools.noaa.gov/arcgis/rest/services/MCS/NOAAChartDisplay/MapServer/exts/MaritimeChartService/WMSServer?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256'
];

const NoaaChartLayer = () => {
  return (
    <Source
      id={MapSourceId.NOAAChart}
      type="raster"
      tileSize={256}
      tiles={NOAA_CHART_TILES}
    >
      <Layer
        id={MapLayerId.NOAAChart}
        type="raster"
        paint={{}}
        beforeId={DEFAULT_BEFORE_LAYER_ID}
      ></Layer>
    </Source>
  );
};

export default NoaaChartLayer;
