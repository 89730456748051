import {
  Feature,
  FeatureCollection,
  Geometry,
  GeoJsonProperties
} from 'geojson';
import iconLowStorm from '../icons/weather/icon-trop-low.png';
import iconTropicalDepression from '../icons/weather/icon-trop-td.png';
import iconTropicalStorm from '../icons/weather/icon-trop-ts.png';
import iconHurricane1 from '../icons/weather/icon-trop-hurr1.png';
import iconHurricane2 from '../icons/weather/icon-trop-hurr2.png';
import iconHurricane3 from '../icons/weather/icon-trop-hurr3.png';
import iconHurricane4 from '../icons/weather/icon-trop-hurr4.png';
import iconHurricane5 from '../icons/weather/icon-trop-hurr5.png';
import iconTyphoon from '../icons/weather/icon-trop-ty.png';
import iconSuperTyphoon from '../icons/weather/icon-trop-sty.png';
import { formatISODate } from './formatDateTime';
import { AlertType, Cyclone, CycloneData, StormCat } from '../models/Cyclone';

/**
 * Based on National Weather Service colors https://www.weather.gov/help-map
 */
export enum StormColor {
  TROPICAL_STORM_WARNING = '#B22222',
  TROPICAL_STORM_WATCH = '#F08080',
  HURRICANE_WARNING = '#DC143C',
  HURRICANE_WATCH = '#FF00FF'
}

export enum AlertTypeDescription {
  TROPICAL_STORM_WARNING = 'Tropical Storm Warning',
  TROPICAL_STORM_WATCH = 'Tropical Storm Watch',
  HURRICANE_WARNING = 'Hurricane Warning',
  HURRICANE_WATCH = 'Hurricane Watch'
}

export enum TropicalCycloneIcon {
  LOW = 'icon-trop-low',
  DEPRESSION = 'icon-trop-td',
  STORM = 'icon-trop-ts',
  HURRICANE_1 = 'icon-trop-hurr1',
  HURRICANE_2 = 'icon-trop-hurr2',
  HURRICANE_3 = 'icon-trop-hurr3',
  HURRICANE_4 = 'icon-trop-hurr4',
  HURRICANE_5 = 'icon-trop-hurr5',
  TYPHOON = 'icon-trop-ty',
  SUPER_TYPHOON = 'icon-trop-sty'
}

export interface CycloneMapData {
  trackData: FeatureCollection<Geometry, GeoJsonProperties>;
  forecastData: FeatureCollection<Geometry, GeoJsonProperties>;
  trackLineData: FeatureCollection<Geometry, GeoJsonProperties>;
  forecastLineData: FeatureCollection<Geometry, GeoJsonProperties>;
  breakpointAlertData: FeatureCollection<Geometry, GeoJsonProperties>;
  errorConeData: FeatureCollection<Geometry, GeoJsonProperties>;
}

export const determineImageSource = (iconName: string): string | null => {
  let result = null;
  switch (iconName) {
    case TropicalCycloneIcon.LOW:
      result = iconLowStorm.src;
      break;
    case TropicalCycloneIcon.DEPRESSION:
      result = iconTropicalDepression.src;
      break;
    case TropicalCycloneIcon.STORM:
      result = iconTropicalStorm.src;
      break;
    case TropicalCycloneIcon.HURRICANE_1:
      result = iconHurricane1.src;
      break;
    case TropicalCycloneIcon.HURRICANE_2:
      result = iconHurricane2.src;
      break;
    case TropicalCycloneIcon.HURRICANE_3:
      result = iconHurricane3.src;
      break;
    case TropicalCycloneIcon.HURRICANE_4:
      result = iconHurricane4.src;
      break;
    case TropicalCycloneIcon.HURRICANE_5:
      result = iconHurricane5.src;
      break;
    case TropicalCycloneIcon.TYPHOON:
      result = iconTyphoon.src;
      break;
    case TropicalCycloneIcon.SUPER_TYPHOON:
      result = iconSuperTyphoon.src;
      break;
  }
  return result;
};

export const getIsoDateTime = (isoDateTime: string) => {
  const formattedDate = formatISODate(isoDateTime);
  return `${formattedDate.formattedDate} ${formattedDate.formattedTime} (${formattedDate.formattedZone})`;
};

export const determineIcon = (stormCat: StormCat) => {
  let icon = '';
  switch (stormCat) {
    case StormCat.TD:
      icon = TropicalCycloneIcon.DEPRESSION;
      break;
    case StormCat.TS:
      icon = TropicalCycloneIcon.STORM;
      break;
    case StormCat.H1:
      icon = TropicalCycloneIcon.HURRICANE_1;
      break;
    case StormCat.H2:
      icon = TropicalCycloneIcon.HURRICANE_2;
      break;
    case StormCat.H3:
      icon = TropicalCycloneIcon.HURRICANE_3;
      break;
    case StormCat.H4:
      icon = TropicalCycloneIcon.HURRICANE_4;
      break;
    case StormCat.H5:
      icon = TropicalCycloneIcon.HURRICANE_5;
      break;
    case StormCat.TY:
      icon = TropicalCycloneIcon.TYPHOON;
      break;
    case StormCat.STY:
      icon = TropicalCycloneIcon.SUPER_TYPHOON;
      break;
    default:
      icon = TropicalCycloneIcon.LOW;
  }
  return icon;
};

export const determineAlertProps = (alertType: string) => {
  const result = { alertTypeDescription: '?', color: '#FFFFFF' };

  switch (alertType) {
    case AlertType.TROPICAL_STORM_WATCH:
      result.alertTypeDescription = AlertTypeDescription.TROPICAL_STORM_WATCH;
      result.color = StormColor.TROPICAL_STORM_WATCH;
      break;
    case AlertType.TROPICAL_STORM_WARNING:
      result.alertTypeDescription = AlertTypeDescription.TROPICAL_STORM_WARNING;
      result.color = StormColor.TROPICAL_STORM_WARNING;
      break;
    case AlertType.HURRICANE_WATCH:
      result.alertTypeDescription = AlertTypeDescription.HURRICANE_WATCH;
      result.color = StormColor.HURRICANE_WATCH;
      break;
    case AlertType.HURRICANE_WARNING:
      result.alertTypeDescription = AlertTypeDescription.HURRICANE_WARNING;
      result.color = StormColor.HURRICANE_WARNING;
      break;
  }

  return result;
};

export const formatWindSpeed = (windSpeedKTS: number, windSpeedMPH: number) => {
  return `Maximum Sustained Winds: ${windSpeedKTS} knots; ${windSpeedMPH} mph`;
};

export const formatWindGustSpeed = (
  gustSpeedKTS: number | null,
  gustSpeedMPH: number | null
) => {
  return gustSpeedKTS && gustSpeedMPH
    ? `Gust Winds: ${gustSpeedKTS} knots; ${gustSpeedMPH} mph`
    : null;
};

export const formatPressure = (pressureMB: number) => {
  return `Minimum Central Pressure: ${pressureMB} mb`;
};

export const formatMovement = (
  direction: string,
  speedKTS: number,
  speedMPH: number
) => {
  return `Movement: ${direction} at ${speedKTS} knots; ${speedMPH} mph`;
};

const addTrackPoints = (
  response: Cyclone,
  trackPoints: Array<Feature<Geometry, GeoJsonProperties>>,
  trackLineData: FeatureCollection<Geometry, GeoJsonProperties>
) => {
  const trackLineFeature: Feature<Geometry, GeoJsonProperties> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: []
    }
  };

  response.track.forEach((trackPoint) => {
    if (
      trackPoint.location.coordinates[0] !==
        response.position.location.coordinates[0] &&
      trackPoint.location.coordinates[1] !==
        response.position.location.coordinates[1]
    ) {
      let properties = {};
      if (trackPoint.details.pressureMB) {
        properties = {
          dateTimeISORaw: trackPoint.dateTimeISO,
          dateTimeISO: getIsoDateTime(trackPoint.dateTimeISO),
          icon: determineIcon(trackPoint.details.stormCat),
          stormName: trackPoint.details.stormName,
          windSpeed: formatWindSpeed(
            trackPoint.details.windSpeedKTS,
            trackPoint.details.windSpeedMPH
          ),
          windGustSpeed: formatWindGustSpeed(
            trackPoint.details.gustSpeedKTS,
            trackPoint.details.gustSpeedMPH
          ),
          pressure: formatPressure(trackPoint.details.pressureMB),
          movement: formatMovement(
            trackPoint.details.movement?.direction,
            trackPoint.details.movement?.speedKTS,
            trackPoint.details.movement?.speedMPH
          )
        };
      } else {
        properties = {
          dateTimeISORaw: trackPoint.dateTimeISO,
          dateTimeISO: getIsoDateTime(trackPoint.dateTimeISO),
          icon: determineIcon(trackPoint.details.stormCat),
          stormName: trackPoint.details.stormName,
          windSpeed: formatWindSpeed(
            trackPoint.details.windSpeedKTS,
            trackPoint.details.windSpeedMPH
          ),
          windGustSpeed: formatWindGustSpeed(
            trackPoint.details.gustSpeedKTS,
            trackPoint.details.gustSpeedMPH
          ),
          movement: formatMovement(
            trackPoint.details.movement?.direction,
            trackPoint.details.movement?.speedKTS,
            trackPoint.details.movement?.speedMPH
          )
        };
      }
      trackPoints.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: trackPoint.location.coordinates
        },
        properties: properties
      });

      if (trackLineFeature.geometry.type === 'LineString') {
        trackLineFeature.geometry.coordinates.push(
          trackPoint.location.coordinates
        );
      }
    }
  });

  let properties = {};
  if (response.position.details.pressureMB) {
    properties = {
      dateTimeISORaw: response.position.dateTimeISO,
      dateTimeISO: getIsoDateTime(response.position.dateTimeISO),
      icon: determineIcon(response.position.details.stormCat),
      stormName: response.position.details.stormName,
      stormShortName: response.position.details.stormShortName,
      windSpeed: formatWindSpeed(
        response.position.details.windSpeedKTS,
        response.position.details.windSpeedMPH
      ),
      windGustSpeed: formatWindGustSpeed(
        response.position.details.gustSpeedKTS,
        response.position.details.gustSpeedMPH
      ),
      pressure: formatPressure(response.position.details.pressureMB),
      movement: formatMovement(
        response.position.details.movement?.direction,
        response.position.details.movement?.speedKTS,
        response.position.details.movement?.speedMPH
      )
    };
  } else {
    properties = {
      dateTimeISORaw: response.position.dateTimeISO,
      dateTimeISO: getIsoDateTime(response.position.dateTimeISO),
      icon: determineIcon(response.position.details.stormCat),
      stormName: response.position.details.stormName,
      stormShortName: response.position.details.stormShortName,
      windSpeed: formatWindSpeed(
        response.position.details.windSpeedKTS,
        response.position.details.windSpeedMPH
      ),
      windGustSpeed: formatWindGustSpeed(
        response.position.details.gustSpeedKTS,
        response.position.details.gustSpeedMPH
      ),
      movement: formatMovement(
        response.position.details.movement?.direction,
        response.position.details.movement?.speedKTS,
        response.position.details.movement?.speedMPH
      )
    };
  }

  trackPoints.push({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: response.position.location.coordinates
    },
    properties: properties
  });

  if (trackLineFeature.geometry.type === 'LineString') {
    trackLineFeature.geometry.coordinates.push(
      response.position.location.coordinates
    );
  }

  trackLineData.features.push(trackLineFeature);
};

const addForecastPoints = (
  response: Cyclone,
  forecastPoints: Array<Feature<Geometry, GeoJsonProperties>>,
  forecastLineData: FeatureCollection<Geometry, GeoJsonProperties>
) => {
  const forecastLineFeature: Feature<Geometry, GeoJsonProperties> = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: []
    }
  };

  if (
    response.forecast &&
    response.forecast.length > 0 &&
    forecastLineFeature.geometry.type === 'LineString'
  ) {
    forecastLineFeature.geometry.coordinates.push(
      response.position.location.coordinates
    );
  }

  response.forecast?.forEach((forecastPoint) => {
    let properties = {};
    if (forecastPoint.details.pressureMB) {
      properties = {
        dateTimeISORaw: forecastPoint.dateTimeISO,
        dateTimeISO: getIsoDateTime(forecastPoint.dateTimeISO),
        icon: determineIcon(forecastPoint.details.stormCat),
        stormName: forecastPoint.details.stormName,
        windSpeed: formatWindSpeed(
          forecastPoint.details.windSpeedKTS,
          forecastPoint.details.windSpeedMPH
        ),
        windGustSpeed: formatWindGustSpeed(
          forecastPoint.details.gustSpeedKTS,
          forecastPoint.details.gustSpeedMPH
        ),
        pressure: formatPressure(forecastPoint.details.pressureMB)
      };
    } else {
      properties = {
        dateTimeISORaw: forecastPoint.dateTimeISO,
        dateTimeISO: getIsoDateTime(forecastPoint.dateTimeISO),
        icon: determineIcon(forecastPoint.details.stormCat),
        stormName: forecastPoint.details.stormName,
        windSpeed: formatWindSpeed(
          forecastPoint.details.windSpeedKTS,
          forecastPoint.details.windSpeedMPH
        ),
        windGustSpeed: formatWindGustSpeed(
          forecastPoint.details.gustSpeedKTS,
          forecastPoint.details.gustSpeedMPH
        )
      };
    }

    forecastPoints.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: forecastPoint.location.coordinates
      },
      properties: properties
    });

    if (forecastLineFeature.geometry.type === 'LineString') {
      forecastLineFeature.geometry.coordinates.push(
        forecastPoint.location.coordinates
      );
    }
  });

  if (forecastLineFeature.geometry.type === 'LineString') {
    const shiftedCoordinates = mapLineOverAntimeridian(
      forecastLineFeature.geometry.coordinates
    );
    forecastLineFeature.geometry.coordinates = [];
    forecastLineFeature.geometry.coordinates.push(...shiftedCoordinates);
  }

  forecastLineData.features.push(forecastLineFeature);
};

const addBreakpointAlertPoints = (
  response: Cyclone,
  breakpointAlertPoints: Array<Feature<Geometry, GeoJsonProperties>>
) => {
  response.breakPointAlerts?.forEach((breakpointAlert) => {
    const props = determineAlertProps(breakpointAlert.alertType);
    breakpointAlertPoints.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: breakpointAlert.coords.coordinates
      },
      properties: {
        alertTypeDescription: props.alertTypeDescription,
        color: props.color
      }
    });
  });
};

export const crossesAntimeridian = (
  endLng: number,
  startLng: number
): boolean => {
  return endLng - startLng <= -180 || endLng - startLng >= 180;
};

export const crossesAntimeridianWestToEast = (
  endLng: number,
  startLng: number
): boolean => {
  return crossesAntimeridian(endLng, startLng) && endLng < 0 && startLng > 0;
};

export const crossesAntimeridianEastToWest = (
  endLng: number,
  startLng: number
): boolean => {
  return crossesAntimeridian(endLng, startLng) && endLng > 0 && startLng < 0;
};

/**
 * Polygon wrapper for mapLineOverMeridian. Expects single polygon.
 *
 * @param polyCoords
 * @returns
 */
export const mapPolygonOverAntimeridian = (
  polyCoords: number[][][]
): number[][][] => {
  const results: number[][][] = [];

  if (polyCoords.length > 0) {
    const coordinates: number[][] = mapLineOverAntimeridian(polyCoords[0]);
    results.push(coordinates);
  }

  return results;
};

/**
 * Shifts coordinates so Mapbox can properly map lines segments
 * over the antimeridian (180th meridian). Handles crossing
 * the antimeridian multiple times. Expects input to have at least
 * two coordinates.
 *
 * @param coordinates
 * @returns
 */
export const mapLineOverAntimeridian = (
  coordinates: number[][]
): number[][] => {
  const lineSegment: number[][] = [];

  let shiftCoordsEast = false;
  let shiftCoordsWest = false;

  if (coordinates.length > 1) {
    lineSegment.push(coordinates[0]);

    for (let i = 1; i < coordinates.length; i++) {
      const startLng = coordinates[i - 1][0];
      let endLng = coordinates[i][0];

      if (crossesAntimeridianWestToEast(endLng, startLng)) {
        if (!shiftCoordsWest) {
          shiftCoordsEast = true;
        }
        shiftCoordsWest = false;
      } else if (crossesAntimeridianEastToWest(endLng, startLng)) {
        if (!shiftCoordsEast) {
          shiftCoordsWest = true;
        }
        shiftCoordsEast = false;
      }

      if (shiftCoordsWest) {
        endLng -= 360;
      } else if (shiftCoordsEast) {
        endLng += 360;
      }

      lineSegment.push([endLng, coordinates[i][1]]);
    }
  }

  return lineSegment;
};

const addErrorConePoints = (
  response: Cyclone,
  errorConePoints: Array<Feature<Geometry, GeoJsonProperties>>
) => {
  if (response.errorCone) {
    errorConePoints.push({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: mapPolygonOverAntimeridian(response.errorCone.coordinates)
      },
      properties: {}
    });
  }
};

export const createCycloneMapData = (
  cycloneData: CycloneData | null | undefined
): CycloneMapData => {
  const trackData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };
  const forecastData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };
  const trackLineData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };
  const forecastLineData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };
  const breakpointAlertData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };
  const errorConeData: FeatureCollection<Geometry, GeoJsonProperties> = {
    type: 'FeatureCollection',
    features: []
  };

  const trackPoints: Array<Feature<Geometry, GeoJsonProperties>> = [];
  const forecastPoints: Array<Feature<Geometry, GeoJsonProperties>> = [];
  const breakpointAlertPoints: Array<Feature<Geometry, GeoJsonProperties>> = [];
  const errorConePoints: Array<Feature<Geometry, GeoJsonProperties>> = [];

  cycloneData?.CycloneData?.forEach((response) => {
    addTrackPoints(response, trackPoints, trackLineData);
    addForecastPoints(response, forecastPoints, forecastLineData);
    addBreakpointAlertPoints(response, breakpointAlertPoints);
    addErrorConePoints(response, errorConePoints);
  });

  trackData.features.push(...trackPoints);
  forecastData.features.push(...forecastPoints);
  breakpointAlertData.features.push(...breakpointAlertPoints);
  errorConeData.features.push(...errorConePoints);

  return {
    trackData,
    forecastData,
    trackLineData,
    forecastLineData,
    breakpointAlertData,
    errorConeData
  };
};
