import React from 'react';
import { Marker } from 'react-map-gl';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { determineImageSource } from '../../../../utils/cycloneUtils';
import classNames from 'classnames';

interface CycloneTrackMarkerProps {
  trackFeature: Feature<Geometry, GeoJsonProperties>;
  onShowPopup: (trackFeature: Feature<Geometry, GeoJsonProperties>) => void;
  onHidePopup: () => void;
}

const CycloneTrackMarker = ({
  trackFeature,
  onShowPopup,
  onHidePopup
}: CycloneTrackMarkerProps) => {
  return (
    <Marker
      longitude={
        trackFeature.geometry.type === 'Point'
          ? trackFeature.geometry.coordinates[0]
          : 0
      }
      latitude={
        trackFeature.geometry.type === 'Point'
          ? trackFeature.geometry.coordinates[1]
          : 0
      }
    >
      <img
        src={determineImageSource(trackFeature.properties?.icon) ?? undefined}
        alt={trackFeature.properties?.icon}
        className={classNames(
          { 'w-9': trackFeature.properties?.stormShortName },
          { 'h-9': trackFeature.properties?.stormShortName },
          { 'w-7': !trackFeature.properties?.stormShortName },
          { 'h-7': !trackFeature.properties?.stormShortName },
          'cursor-pointer',
          { 'opacity-50': !trackFeature.properties?.stormShortName },
          {
            'animate-spin-slow-left': trackFeature.properties?.stormShortName
          },
          'hover:opacity-100',
          'hover:w-12',
          'hover:h-12'
        )}
        onMouseEnter={() => onShowPopup(trackFeature)}
        onMouseLeave={onHidePopup}
      />
    </Marker>
  );
};

export default CycloneTrackMarker;
