import {
  along,
  booleanPointInPolygon,
  booleanPointOnLine,
  circle,
  destination,
  distance,
  Feature,
  FeatureCollection,
  lineIntersect,
  lineSlice,
  lineString,
  LineString,
  Point,
  point,
  polygon,
  Polygon,
  Position,
  Properties
} from '@turf/turf';
import { DateTime } from 'luxon';
import { Outer34KtCircleData } from '../components/Map/Layers/CycloneLayer/CycloneIntersectionLayer';
import {
  CycloneOuter34KtData,
  Noaa123RulePolygonData,
  Outer34KtData
} from '../components/Map/Layers/CycloneLayer/WindFieldLayer';
import { Vessel } from '../models/Vessel';
import { getIsoDateTime } from './cycloneUtils';

/**
 * Space between markers in a particular vessel's
 * forecast path. Must be able to be divided
 * evenly into HOUR_MAX.
 */
const HOUR_INCREMENT = 12;
/**
 * Max hours to calculate vessel forecast paths.
 */
export const HOUR_MAX = 72;

export interface VesselPoint {
  longitude: number;
  latitude: number;
  timestamp: DateTime;
}

export interface ForecastVesselInfo {
  assetNumber: number;
  vesselName: string;
  totalDistance: number;
  vesselPoints: VesselPoint[];
}

export interface VesselCycloneDangerLine {
  assetNumber: number;
  cycloneShortName: string;
  lineSlice: Feature<LineString, Properties>;
  startDateTime: DateTime;
  endDateTime: DateTime;
}

export const createForecastVesselInfo = (
  vessel: Vessel,
  startingDateTime: DateTime
): ForecastVesselInfo => {
  const vesselPoints = [
    {
      longitude: vessel.Longitude,
      latitude: vessel.Latitude,
      timestamp: startingDateTime
    }
  ];

  let totalDistance = 0;

  if (
    vessel.SpeedOverGround !== undefined &&
    vessel.SpeedOverGround > 0 &&
    vessel.Heading !== undefined
  ) {
    const vesselPoint = point([vessel.Longitude, vessel.Latitude]);

    for (let i = HOUR_INCREMENT; i <= HOUR_MAX; i += HOUR_INCREMENT) {
      const currentHours = i;
      const currentDateTime = startingDateTime.plus({
        hours: currentHours
      });
      /**
       * nautical miles traversed from starting point
       */
      const distanceFromStart = currentHours * vessel.SpeedOverGround;
      totalDistance = distanceFromStart;
      const destinationPoint = destination(
        vesselPoint,
        distanceFromStart,
        vessel.Heading,
        {
          units: 'nauticalmiles'
        }
      );
      const longitude = destinationPoint.geometry.coordinates[0];
      const latitude = destinationPoint.geometry.coordinates[1];

      vesselPoints.push({
        longitude,
        latitude,
        timestamp: currentDateTime
      });
    }
  }

  const vesselInfo: ForecastVesselInfo = {
    assetNumber: vessel.AssetNumber,
    vesselName: vessel.VesselName,
    totalDistance,
    vesselPoints
  };

  return vesselInfo;
};

const createCircleFromPoint = (
  nextPoint: Position,
  radius: number,
  dateTime: DateTime
) => {
  return createCircleFromOuter34Kt({
    hoursFromCurrent: 0,
    longitude: nextPoint[0],
    latitude: nextPoint[1],
    dateTime: dateTime,
    outer34KtRadiusNM: radius
  });
};

const createCircleFromOuter34Kt = (positionData: Outer34KtData) => {
  return circle(
    [positionData.longitude, positionData.latitude],
    positionData.outer34KtRadiusNM,
    {
      units: 'nauticalmiles',
      properties: {
        dateTime: positionData.dateTime.toISO()
      }
    }
  );
};

/**
 * Creates circles to be used later for vessel and cyclone
 * intersection calculations. Input expected is usually
 * four center points (current cyclone position and 1st,
 * 2nd, 3rd forecast cyclone positions at either
 * 12/24/36 hour increments or 24/48/72 hour increments),
 * but could be as small as one point (current cyclone
 * position).
 *
 * @param outer34KtData
 * @returns
 */
export const createIncrementalOuter34KtCircles = (
  outer34KtData: Outer34KtData[]
) => {
  const results = [];

  if (outer34KtData.length > 0) {
    const currentPosition = outer34KtData[0];
    results.push(createCircleFromOuter34Kt(currentPosition));
  }

  if (outer34KtData.length > 1) {
    for (let i = 1; i < outer34KtData.length; i++) {
      const previousPosition = outer34KtData[i - 1];
      const nextPosition = outer34KtData[i];
      /**
       * Total hours between previous position and current position
       */
      const INTERVAL_HOURS =
        nextPosition.hoursFromCurrent - previousPosition.hoursFromCurrent;
      /**
       * Hours between each drawn circle
       */
      const INCREMENT_HOURS = 1;
      /**
       * Number of circles to draw between previous position
       * and current position
       */
      const STEP_HOURS = INTERVAL_HOURS / INCREMENT_HOURS;
      /**
       * Radius to increase/decrease each circle from the
       * previous circle
       */
      const INCREMENT_RADIUS =
        (nextPosition.outer34KtRadiusNM - previousPosition.outer34KtRadiusNM) /
        STEP_HOURS;
      const previousPositionPoint = [
        previousPosition.longitude,
        previousPosition.latitude
      ];
      const nextPositionPoint = [nextPosition.longitude, nextPosition.latitude];
      /**
       * Distance in nautical miles between the previous position
       * and the current position
       */
      const TOTAL_DISTANCE = distance(
        previousPositionPoint,
        nextPositionPoint,
        {
          units: 'nauticalmiles'
        }
      );
      /**
       * Distance to increase between each center point
       * of each circle starting from the previous position
       */
      const INCREMENT_DISTANCE = TOTAL_DISTANCE / STEP_HOURS;

      let radius = previousPosition.outer34KtRadiusNM;
      let dateTime = previousPosition.dateTime;
      const line = lineString([previousPositionPoint, nextPositionPoint]);
      let nextDistance = INCREMENT_DISTANCE;
      for (let j = 1; j < STEP_HOURS; j += INCREMENT_HOURS) {
        radius += INCREMENT_RADIUS;
        dateTime = dateTime.plus({ hours: INCREMENT_HOURS });
        const nextPoint = along(line, nextDistance, {
          units: 'nauticalmiles'
        });
        results.push(
          createCircleFromPoint(
            nextPoint.geometry.coordinates,
            radius,
            dateTime
          )
        );
        nextDistance += INCREMENT_DISTANCE;
      }

      results.push(createCircleFromOuter34Kt(nextPosition));
    }
  }

  return results;
};

export const calculateIntersectDateTime = (
  intersectionPoint: Position,
  vesselForecastLine: Feature<LineString, Properties>
): DateTime | undefined => {
  if (
    booleanPointOnLine(intersectionPoint, vesselForecastLine, {
      ignoreEndVertices: true,
      epsilon: 0.0001
    })
  ) {
    const firstPoint: Position = vesselForecastLine.geometry.coordinates[0];
    const firstPointTimestamp: DateTime = DateTime.fromISO(
      vesselForecastLine.properties?.startDateTime
    );
    const secondPoint: Position =
      vesselForecastLine.geometry.coordinates[
        vesselForecastLine.geometry.coordinates.length - 1
      ];
    const secondPointTimestamp: DateTime = DateTime.fromISO(
      vesselForecastLine.properties?.endDateTime
    );

    const distanceOnLine = distance(firstPoint, intersectionPoint, {
      units: 'nauticalmiles'
    });
    const lineSegmentDistance = distance(firstPoint, secondPoint, {
      units: 'nauticalmiles'
    });
    const ratio = distanceOnLine / lineSegmentDistance;
    const difference = secondPointTimestamp.diff(firstPointTimestamp, 'hours');
    const hoursFromFirstPoint = difference.hours * ratio;
    const timestampOnLineSegment = firstPointTimestamp.plus({
      hours: hoursFromFirstPoint
    });

    return timestampOnLineSegment;
  }

  return undefined;
};

/**
 * Returns a point on the given line based on the line properties
 * startDateTime, endDateTime, and totalDistance (nautical miles).
 * This function expects startDateTime to occur before
 * endDateTime. If startDateTime and endDateTime are the same
 * or totalDistance is zero this will behave undesirably.
 *
 * @param vesselForecastLine
 * @param dateTime
 * @returns
 */
export const calculatePointFromDateTime = (
  vesselForecastLine: Feature<LineString, Properties>,
  dateTime: DateTime
): Feature<Point, Properties> | null => {
  const startTimeStamp = DateTime.fromISO(
    vesselForecastLine.properties?.startDateTime
  );
  const endTimeStamp = DateTime.fromISO(
    vesselForecastLine.properties?.endDateTime
  );
  const totalTimeDifference = endTimeStamp.diff(startTimeStamp, 'hours');
  const segmentTimeDifference = dateTime.diff(startTimeStamp, 'hours');

  if (
    segmentTimeDifference.hours >= 0 &&
    segmentTimeDifference.hours <= totalTimeDifference.hours
  ) {
    const ratio = segmentTimeDifference.hours / totalTimeDifference.hours;
    const distanceToPoint =
      vesselForecastLine.properties?.totalDistance * ratio;
    return along(vesselForecastLine, distanceToPoint, {
      units: 'nauticalmiles'
    });
  }

  return null;
};

/**
 * Finds any circles that intersect with the vessel
 * forecast line and returns the circle dateTime's
 * that occur between the start/end date times of
 * the vessel forecast line.
 *
 * @param vesselForecastLine
 * @param circle
 * @returns
 */
export const determineCircleIntersects = (
  vesselForecastLine: Feature<LineString, Properties>,
  circle: Feature<Polygon, Properties>
): DateTime[] => {
  const results: DateTime[] = [];

  const intersects = lineIntersect(vesselForecastLine, circle);

  if (intersects && intersects.features && intersects.features.length > 0) {
    let firstIntersectDateTime;
    let secondIntersectDateTime;

    if (intersects.features.length > 0) {
      firstIntersectDateTime = calculateIntersectDateTime(
        intersects.features[0].geometry.coordinates,
        vesselForecastLine
      );
    }
    if (intersects.features.length > 1) {
      secondIntersectDateTime = calculateIntersectDateTime(
        intersects.features[1].geometry.coordinates,
        vesselForecastLine
      );
    }

    const circleDateTime = DateTime.fromISO(circle.properties?.dateTime);
    /**
     * Forecasted vessel line starts and ends outside of cyclone
     */
    if (firstIntersectDateTime && secondIntersectDateTime) {
      if (
        firstIntersectDateTime.toMillis() <= circleDateTime.toMillis() &&
        secondIntersectDateTime.toMillis() >= circleDateTime.toMillis()
      ) {
        results.push(circleDateTime);
      }
    } else if (firstIntersectDateTime) {
      const endPosition =
        vesselForecastLine.geometry.coordinates[
          vesselForecastLine.geometry.coordinates.length - 1
        ];
      /**
       * Forecasted vessel line starts outside of cyclone, but
       * ends inside of cyclone
       */
      if (booleanPointInPolygon(endPosition, circle)) {
        secondIntersectDateTime = DateTime.fromISO(
          vesselForecastLine.properties?.endDateTime
        );
        if (
          firstIntersectDateTime.toMillis() <= circleDateTime.toMillis() &&
          secondIntersectDateTime.toMillis() >= circleDateTime.toMillis()
        ) {
          results.push(circleDateTime);
        }
        /**
         * Forecasted vessel line starts inside of cyclone, and
         * ends outside of cyclone
         */
      } else {
        secondIntersectDateTime = firstIntersectDateTime;
        firstIntersectDateTime = DateTime.fromISO(
          vesselForecastLine.properties?.startDateTime
        );
        if (
          firstIntersectDateTime.toMillis() <= circleDateTime.toMillis() &&
          secondIntersectDateTime.toMillis() >= circleDateTime.toMillis()
        ) {
          results.push(circleDateTime);
        }
      }
    }
  }

  return results;
};

/**
 * Creates results with the asset number of the vessel,
 * the min/max date times of the circles which the
 * vessel forecast line traverses through, the
 * cyclone short name associated to the
 * intersection, and a line slice of the
 * vessel forecast line where the min/max
 * date time intersections would occur.
 *
 * @param vesselForecastLine
 * @param circleData
 * @param circleDateTimes
 * @returns
 */
export const processCircleIntersects = (
  vesselForecastLine: Feature<LineString, Properties>,
  circleData: Outer34KtCircleData,
  circleDateTimes: DateTime[]
): VesselCycloneDangerLine[] => {
  const results: VesselCycloneDangerLine[] = [];

  if (circleDateTimes.length > 0) {
    const minDateTime = circleDateTimes[0];
    const maxDateTime = circleDateTimes[circleDateTimes.length - 1];
    const startPosition = calculatePointFromDateTime(
      vesselForecastLine,
      minDateTime
    );
    const endPosition = calculatePointFromDateTime(
      vesselForecastLine,
      maxDateTime
    );

    if (startPosition && endPosition) {
      results.push({
        assetNumber: vesselForecastLine.properties?.assetNumber,
        cycloneShortName: circleData.cycloneShortName,
        lineSlice: lineSlice(
          startPosition.geometry.coordinates,
          endPosition.geometry.coordinates,
          vesselForecastLine
        ),
        startDateTime: minDateTime,
        endDateTime: maxDateTime
      });
    }
  }

  return results;
};

/**
 * Calculate lineIntersect points between outer34KtCircles and vessel
 * forecast lines. Compare dateTime of vessel intersection points
 * (start and end) and 34 kt circle dateTime's. If dateTime's of
 * circles are between vessel and cyclone intersection points then
 * use all intersecting circles' dateTime's to calculate a danger
 * zone line subsegment of the original vessel forecast line.
 *
 * @param finalVesselForecastLineData
 * @param outer34KtCircleData
 * @returns
 */
export const generateDangerLines = (
  finalVesselForecastLineData: FeatureCollection<LineString, Properties>,
  outer34KtCircleData: Outer34KtCircleData[]
): VesselCycloneDangerLine[] => {
  const results: VesselCycloneDangerLine[] = [];

  finalVesselForecastLineData.features.forEach((vesselForecastLine) => {
    outer34KtCircleData.forEach((circleData) => {
      const circleDateTimes: DateTime[] = [];

      circleData.circles.forEach((circle) => {
        circleDateTimes.push(
          ...determineCircleIntersects(vesselForecastLine, circle)
        );
      });

      results.push(
        ...processCircleIntersects(
          vesselForecastLine,
          circleData,
          circleDateTimes
        )
      );
    });
  });

  return results;
};

export const generateForecastLineFeatures = (
  forecastVessels: ForecastVesselInfo[]
) => {
  const results: Feature<LineString, Properties>[] = [];

  if (forecastVessels && forecastVessels.length > 0) {
    forecastVessels.forEach((vesselInfo) => {
      if (vesselInfo.vesselPoints.length > 1) {
        const vesselForecastLineFeature: Feature<LineString, Properties> = {
          type: 'Feature',
          properties: {
            assetNumber: vesselInfo.assetNumber,
            vesselName: vesselInfo.vesselName,
            totalDistance: vesselInfo.totalDistance,
            startDateTime: vesselInfo.vesselPoints[0].timestamp.toISO(),
            endDateTime:
              vesselInfo.vesselPoints[
                vesselInfo.vesselPoints.length - 1
              ].timestamp.toISO()
          },
          geometry: {
            type: 'LineString',
            coordinates: []
          }
        };
        const vesselPoints = vesselInfo.vesselPoints;
        for (let i = 0; i < vesselPoints.length; i++) {
          if (vesselForecastLineFeature.geometry.type === 'LineString') {
            vesselForecastLineFeature.geometry.coordinates.push([
              vesselPoints[i].longitude,
              vesselPoints[i].latitude
            ]);
          }
        }
        results.push(vesselForecastLineFeature);
      }
    });
  }

  return results;
};

export const generateVesselForecastIntersectAssetNumbers = (
  forecastVessels: ForecastVesselInfo[],
  noaa123RulePolygonData: Noaa123RulePolygonData
) => {
  const results: number[] = [];

  if (forecastVessels && forecastVessels.length > 0) {
    forecastVessels.forEach((vesselInfo) => {
      if (vesselInfo.vesselPoints.length > 1) {
        const vesselPoints = vesselInfo.vesselPoints;
        const vesselForecastLine = lineString(
          vesselPoints.map((vesselPoint) => [
            vesselPoint.longitude,
            vesselPoint.latitude
          ])
        );

        noaa123RulePolygonData.noaa123RulePolygon.features.forEach(
          (dangerArea) => {
            if (dangerArea.geometry.type === 'Polygon') {
              const dangerPoly = polygon(dangerArea.geometry.coordinates);
              const intersectionPoints = lineIntersect(
                vesselForecastLine,
                dangerPoly
              );

              if (
                intersectionPoints &&
                intersectionPoints.features &&
                intersectionPoints.features.length > 0
              ) {
                results.push(vesselInfo.assetNumber);
              } else if (
                booleanPointInPolygon(
                  vesselForecastLine.geometry.coordinates[0],
                  dangerPoly
                ) ||
                booleanPointInPolygon(
                  vesselForecastLine.geometry.coordinates[
                    vesselForecastLine.geometry.coordinates.length - 1
                  ],
                  dangerPoly
                )
              ) {
                results.push(vesselInfo.assetNumber);
              }
            }
          }
        );
      }
    });
  }

  return results;
};

export const generateVesselForecastIntersectLineFeatures = (
  vesselForecastLineData: FeatureCollection<LineString, Properties>,
  vesselForecastIntersectAssetNumbers: number[]
) => {
  const results: Feature<LineString, Properties>[] = [];

  if (
    vesselForecastLineData &&
    vesselForecastIntersectAssetNumbers &&
    vesselForecastIntersectAssetNumbers.length > 0
  ) {
    results.push(
      ...vesselForecastLineData.features.filter((forecastLine) =>
        vesselForecastIntersectAssetNumbers.find(
          (assetNumber) => forecastLine.properties?.assetNumber === assetNumber
        )
      )
    );
  }

  return results;
};

export const generateOuter34KtCircleData = (
  cycloneOuter34KtData: CycloneOuter34KtData[]
) => {
  const results: Outer34KtCircleData[] = [];

  if (cycloneOuter34KtData.length > 0) {
    cycloneOuter34KtData
      .filter((cyclone) => cyclone.cycloneOuter34KtData.length > 0)
      .forEach((cyclone) => {
        results.push({
          cycloneShortName: cyclone.cycloneShortName,
          circles: createIncrementalOuter34KtCircles(
            cyclone.cycloneOuter34KtData
          )
        });
      });
  }

  return results;
};

export const generateCircleIntersectData = (
  vesselForecastIntersectLines: FeatureCollection<LineString, Properties>,
  outer34KtCircleData: Outer34KtCircleData[]
) => {
  const lineFeatures: Feature<LineString, Properties>[] = [];
  const pointFeatures: Feature<Point, Properties>[] = [];
  const assetNumbersInDanger: number[] = [];

  const vesselCycloneDangerLines: VesselCycloneDangerLine[] =
    generateDangerLines(vesselForecastIntersectLines, outer34KtCircleData);

  vesselCycloneDangerLines.forEach((dangerLine) => {
    lineFeatures.push(dangerLine.lineSlice);

    pointFeatures.push(
      point(dangerLine.lineSlice.geometry.coordinates[0], {
        dateTime: dangerLine.startDateTime.toISO(),
        messagePrefix: `Enter Danger Area for ${dangerLine.cycloneShortName}`
      })
    );

    const hoursInDangerArea = dangerLine.endDateTime.diff(
      dangerLine.startDateTime,
      'hours'
    );
    pointFeatures.push(
      point(
        dangerLine.lineSlice.geometry.coordinates[
          dangerLine.lineSlice.geometry.coordinates.length - 1
        ],
        {
          dateTime: dangerLine.endDateTime.toISO(),
          messagePrefix: `Exit Danger Area for ${dangerLine.cycloneShortName}`,
          hoursInDangerArea: hoursInDangerArea.hours
        }
      )
    );

    assetNumbersInDanger.push(dangerLine.assetNumber);
  });

  return { lineFeatures, pointFeatures, assetNumbersInDanger };
};

export const generateVesselForecastPointFeatures = (
  forecastVessels: ForecastVesselInfo[]
) => {
  const results: Feature<Point, Properties>[] = [];

  if (forecastVessels && forecastVessels.length > 0) {
    forecastVessels.forEach((vesselInfo) => {
      const vesselPoints = vesselInfo.vesselPoints;
      for (let i = 1; i < vesselPoints.length; i++) {
        const vesselForecastPointFeature: Feature<Point, Properties> = {
          type: 'Feature',
          properties: {
            assetNumber: vesselInfo.assetNumber,
            dateTime: getIsoDateTime(vesselPoints[i].timestamp.toISO())
          },
          geometry: {
            type: 'Point',
            coordinates: []
          }
        };
        if (
          vesselInfo.vesselPoints.length > 1 &&
          vesselForecastPointFeature.geometry.type === 'Point'
        ) {
          vesselForecastPointFeature.geometry.coordinates.push(
            vesselPoints[i].longitude,
            vesselPoints[i].latitude
          );
        }
        results.push(vesselForecastPointFeature);
      }
    });
  }

  return results;
};

export const generateVesselForecastIntersectPointFeatures = (
  vesselForecastPointData: FeatureCollection<Point, Properties>,
  vesselForecastIntersectAssetNumbers: number[]
) => {
  const results: Feature<Point, Properties>[] = [];

  if (
    vesselForecastPointData &&
    vesselForecastIntersectAssetNumbers &&
    vesselForecastIntersectAssetNumbers.length > 0
  ) {
    results.push(
      ...vesselForecastPointData.features.filter((forecastPoint) =>
        vesselForecastIntersectAssetNumbers.find(
          (assetNumber) => forecastPoint.properties?.assetNumber === assetNumber
        )
      )
    );
  }

  return results;
};

export const generateFinalVesselForecastIntersectFeatures = (
  vesselForecastIntersectLineFeatures: Feature<LineString, Properties>[],
  vesselForecastIntersectPointFeatures: Feature<Point, Properties>[],
  assetNumbersInDanger: number[]
) => {
  const lineFeatures: Feature<LineString, Properties>[] = [];
  const pointFeatures: Feature<Point, Properties>[] = [];

  if (
    vesselForecastIntersectLineFeatures.length > 0 &&
    vesselForecastIntersectPointFeatures.length > 0 &&
    assetNumbersInDanger.length > 0
  ) {
    lineFeatures.push(
      ...vesselForecastIntersectLineFeatures.filter((vesselForecastLine) =>
        assetNumbersInDanger.find(
          (assetNumber) =>
            vesselForecastLine.properties?.assetNumber === assetNumber
        )
      )
    );

    pointFeatures.push(
      ...vesselForecastIntersectPointFeatures.filter((vesselForecastPoint) =>
        assetNumbersInDanger.find(
          (assetNumber) =>
            vesselForecastPoint.properties?.assetNumber === assetNumber
        )
      )
    );
  }

  return { lineFeatures, pointFeatures };
};
