import { Typography } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import { ReactNode, useMemo } from 'react';
import { MdEdit, MdLock } from 'react-icons/md';
import { Loading } from '../../Loading/Loading';
import { ErrorMessage } from '../../ErrorMessage/ErrorMessage';
import { VesselValuesDisplay } from '../../VesselValuesDisplay/VesselValuesDisplay';
import LastUpdate from '../../LastUpdate/LastUpdate';

export enum CardStatus {
  FILLED_IN = 'Filled in',
  MISSING_INFO = 'Missing information',
  DEACTIVATED = 'Deactivated'
}

interface VesselRegistryCardProps {
  heading?: ReactNode;
  title?: string;
  isEditable: boolean;
  onEditClick?: () => void;
  /**
   * This is an array of objects in which
   *  each item of the array represents a
   * column of values on the card.
   */
  values?: { [key: string]: string | number | undefined }[];
  updatedAt?: number;
  updatedBy?: string;
  children?: ReactNode;
  /**
   * This status defines the color of the
   * top border of the card, it can be one
   * of these 3 options:
   * FILLED_IN: Required information filled
   * MISSING_INFO: Required information
   * is missing
   * DEACTIVATED: The vessel was deactivated
   */
  status?: CardStatus;
  className?: string;
  isLoading?: boolean;
  error?: string;
}

const VesselRegistryCard = ({
  heading,
  title,
  isEditable,
  onEditClick,
  values = [],
  updatedBy,
  updatedAt,
  children,
  status = CardStatus.FILLED_IN,
  className = '',
  isLoading,
  error
}: VesselRegistryCardProps) => {
  const icon = useMemo(
    () =>
      isEditable ? (
        <MdEdit
          size={22}
          className="text-silver-40 outline-none"
          role="button"
          aria-label="edit-section"
          tabIndex={1}
          onClick={onEditClick}
        />
      ) : (
        <MdLock
          size={22}
          className="text-silver-40"
          role="img"
          aria-label="edit-disabled"
        />
      ),
    [isEditable, onEditClick]
  );

  const componentTitle = useMemo(
    () =>
      title && (
        <>
          <div className="flex items-center justify-between pb-2">
            <Typography
              variant="h500"
              className="text-silver-100 w-11/12 text-ellipsis overflow-hidden"
            >
              {title}
            </Typography>
            {icon}
          </div>
          <hr className="text-silver-30 py-1" />
        </>
      ),
    [icon, title]
  );

  const columnsClassname = `grid-cols-${values.length}`;

  const componentContent = useMemo(() => {
    let content;
    if (!!error) {
      content = (
        <div className="flex justify-center items-center h-full w-full">
          <ErrorMessage errorMessage={error} />
        </div>
      );
    } else if (isLoading) {
      content = (
        <div className="flex justify-center items-center h-full w-full">
          <Loading />
        </div>
      );
    } else {
      content = (
        <>
          <div className={cn('grid', 'gap-x-6', 'relative', columnsClassname)}>
            {!title && <div className="absolute right-0 top-0">{icon}</div>}
            <VesselValuesDisplay values={values} />
          </div>
          {children}
        </>
      );
    }
    return content;
  }, [children, columnsClassname, error, icon, isLoading, title, values]);

  return (
    <div
      className={cn(
        'pt-2',
        'pb-8',
        'px-6',
        'rounded-lg',
        'bg-white',
        'border-t-4',
        'shadow-md',
        'relative',
        { 'border-green-50': status === CardStatus.FILLED_IN },
        { 'border-red-50': status === CardStatus.MISSING_INFO },
        { 'border-silver-30': status === CardStatus.DEACTIVATED },
        className
      )}
    >
      {heading}
      {componentTitle}
      {componentContent}
      {(updatedAt || updatedBy) && (
        <LastUpdate
          updatedAt={updatedAt}
          updatedBy={updatedBy}
          className="flex w-full absolute bottom-2 justify-center text-center pr-12"
        />
      )}
    </div>
  );
};

export default VesselRegistryCard;
