import { isNil } from 'lodash';
import { formatDateTime } from '../../utils/formatDateTime';
import {
  Tooltip,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import { MdInfoOutline } from 'react-icons/md';
import cn from 'classnames';

interface LastPositionUpdateProps {
  aisLastUpdated: number | undefined;
  className?: string;
}

const LastPositionUpdate = ({
  aisLastUpdated,
  className
}: LastPositionUpdateProps) => {
  let lastUpdated = 'Updated: No data';
  if (!isNil(aisLastUpdated)) {
    const { formattedTime, formattedDate, formattedZone } =
      formatDateTime(aisLastUpdated);
    lastUpdated = `Updated: ${formattedDate} ${formattedTime} ${formattedZone}`;
  }
  return (
    <Typography
      className={cn(
        'text-h100 text-silver-90 flex items-center gap-2 relative',
        className
      )}
    >
      {lastUpdated}
      <Tooltip
        tooltipText="AIS information is received via the IHS service. If any delay is noticed in the reporting of the Vessel position, please use the Provide Feedback button at the top of the screen to report."
        placement="bottom"
      >
        <MdInfoOutline size={18} className="text-silver-40" />
      </Tooltip>
    </Typography>
  );
};

export default LastPositionUpdate;
