import { Button, Modal } from '@org-crowley/enterprise-react-component-library';
import { Vessel } from '../../../models/Vessel';
import MiniMap from '../MiniMap/MiniMap';

export interface MapModalProps {
  vessel: Vessel;
  isOpen: boolean;
  onClose: () => void;
}

const MapModal = ({ vessel, isOpen, onClose }: MapModalProps) => {
  return (
    <Modal
      size="large"
      height="medium"
      isOpen={isOpen}
      onCloseButtonClick={onClose}
      hasFooter={false}
    >
      <MiniMap vessel={vessel} />
      <Button
        onClick={onClose}
        className="absolute left-32 right-20 bottom-6"
        variant="secondary"
      >
        Close
      </Button>
    </Modal>
  );
};

export default MapModal;
