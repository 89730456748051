import { Typography } from '@org-crowley/enterprise-react-component-library';
import { useMemo } from 'react';
import { MdLocationPin, MdDirectionsBoat } from 'react-icons/md';
import { formatISODate, formatDateTime } from '../../utils/formatDateTime';
import { TimeInfo } from '../../utils/searchVoyageTime';
import { NOT_AVAILABLE_TEXT } from '../../utils/textCopy';
import { ICON_SIZE } from './constants';

export enum Time {
  Arrival,
  Departure
}

interface TimeSectionProps {
  time: TimeInfo;
  type: Time;
}

const formatTime = (timeInfo?: TimeInfo) => {
  const timestamp = timeInfo?.value;
  const date =
    typeof timestamp === 'string'
      ? formatISODate(timestamp)
      : formatDateTime(timestamp);

  return {
    key: timeInfo?.key,
    time: date.formattedTime,
    date: date.formattedDate,
    timeZone: date.formattedZone
  };
};

export default function TimeColumn({ time, type: variant }: TimeSectionProps) {
  const formattedTime = useMemo(() => formatTime(time), [time]);
  const icon = useMemo(
    () =>
      variant === Time.Arrival ? (
        <MdLocationPin size={ICON_SIZE} className="text-sky-50 m-auto mb-1" />
      ) : (
        <MdDirectionsBoat
          size={ICON_SIZE}
          className="text-sky-50 m-auto mb-1"
        />
      ),
    [variant]
  );
  return (
    <div className="block my-4 sm:h-20 col-span-3">
      {icon}
      <Typography
        variant="h100"
        className="text-silver-50 font-medium text-center block sm:whitespace-nowrap"
      >
        {formattedTime.key}
      </Typography>
      {time.value ? (
        <>
          <Typography variant="h100" className="text-center block mt-2 mb-1">
            {formattedTime.date}
          </Typography>
          <Typography variant="h100" className="text-center block font-normal">
            {formattedTime.time} ({formattedTime.timeZone})
          </Typography>
        </>
      ) : (
        <Typography
          variant="h100"
          className="font-normal text-center block mt-5"
        >
          {NOT_AVAILABLE_TEXT}
        </Typography>
      )}
    </div>
  );
}
