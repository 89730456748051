import 'mapbox-gl/dist/mapbox-gl.css';
import { useRouter } from 'next/router';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction
} from 'react';
import ReactMapGLMap, { useMap, ViewState } from 'react-map-gl';
import { Location } from './VesselMarkers/VesselMarker/VesselMarker';
import { VesselContext } from '../../contexts/VesselContext';
import { Vessel } from '../../models/Vessel';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useWindowWidth } from '@react-hook/window-size';
import { addRouteToMap, cleanupPreviousRoutes } from '../../utils/routeMapper';
import { RoutePoint, RouteType } from '../../models/Route';
import { useOktaAuth } from '../../contexts/OktaContext';
import {
  RouteAction,
  RouteActionType,
  RouteState
} from '../../reducers/routeReducer';
import { MapLayerMouseEvent } from 'react-map-gl';
import { logRouteResults, useAisRoute } from '../../utils/useAisRoute';
import { FeatureFlagContext } from '../../contexts/FeatureFlagContext';
import AllLayers from './Layers/AllLayers/AllLayers';
import MapControls from './MapControls/MapControls';
import MapPopups from './MapPopups/MapPopups';
import VesselMarkers from './VesselMarkers/VesselMarkers';
import MapInfo from './MapInfo/MapInfo';

export const MAP_ID = 'theMap';
export const DEFAULT_MAP_OPTIONS: MapOptions = {
  showVesselNames: false,
  showCyclones: false,
  showCycloneWindFields: false,
  showNoaaChart: false
};
export const MAP_MAX_ZOOM = 19;
const HOVER_POPUP_DELAY_MS = 700;
const DEFAULT_ZOOM_LEVEL = 3.8;
export const DEFAULT_INITIAL_VIEW_STATE: ViewState = {
  latitude: 30.332184,
  longitude: -81.655647,
  zoom: DEFAULT_ZOOM_LEVEL,
  bearing: 0,
  pitch: 0,
  padding: { top: 0, bottom: 0, left: 0, right: 0 }
};

/**
 * Id of MapBox layer to put our custom layers behind
 */
export const DEFAULT_BEFORE_LAYER_ID = 'tunnel-street-minor-low';

export interface MapLayer {
  /** Index of layer for radio buttons */
  id: string;
  /** Human readable string for display */
  name: string;
  /** URL used for Mapbox mapStyle */
  value: string;
}

export const DEFAULT_LAYER: MapLayer = {
  id: 'layer-0',
  name: 'Default',
  value: 'mapbox://styles/crowley-enterprise/cl20junve000314ou7dkyv1fo'
};

export const SATELLITE_LAYER: MapLayer = {
  id: 'layer-1',
  name: 'Satellite',
  value: 'mapbox://styles/crowley-enterprise/cl3cx42vx000815o6lj0pji6b'
};

/**
 * The bathymetry layer currently uses the same style as the satellite layer. In the future we may
 * want to custom style the bathymetry layer by creating a Mapbox style using the Gebco data. It would
 * be a nice simplification on the current logic as the separate Gebco sources/layers would no longer be needed.
 */
export const BATHYMETRY_LAYER: MapLayer = {
  id: 'layer-2',
  name: 'Bathymetry',
  value: 'mapbox://styles/crowley-enterprise/cl3cx42vx000815o6lj0pji6b'
};

export const LIGHT_LAYER: MapLayer = {
  id: 'layer-3',
  name: 'Light',
  value: 'mapbox://styles/crowley-enterprise/cl4svqgmz003j14pr9ct3e8ia'
};

export const MAP_LAYERS: Array<MapLayer> = [
  DEFAULT_LAYER,
  SATELLITE_LAYER,
  BATHYMETRY_LAYER,
  LIGHT_LAYER
];

export interface MapOptions {
  showVesselNames: boolean;
  showCyclones: boolean;
  showCycloneWindFields: boolean;
  showNoaaChart: boolean;
}

/** Low speed over ground color (can be rgb, rgba, or hex) */
export const LOW_SPEED = 'rgb(255,0,0)';
/** Middle speed over ground color (can be rgb, rgba, or hex) */
export const MIDDLE_SPEED = 'rgb(255,255,0)';
/** High speed over ground color (can be rgb, rgba, or hex) */
export const HIGH_SPEED = 'rgb(0,255,0)';

export interface PopupInfoState {
  message: string;
  latitude: number;
  longitude: number;
  id: number;
  vessel?: Vessel;
}

export interface MapProps {
  routeState: RouteState;
  routeDispatch: Dispatch<RouteAction>;
  mapLayerProperties: MapLayer;
  setMapLayerProperties: Dispatch<SetStateAction<MapLayer>>;
  mapOptionProperties: MapOptions;
  setMapOptionProperties: Dispatch<SetStateAction<MapOptions>>;
}

export function Map({
  routeState,
  routeDispatch,
  mapLayerProperties,
  setMapLayerProperties,
  mapOptionProperties,
  setMapOptionProperties
}: MapProps) {
  // Used to fire specific map event listener to draw routes on layer change
  const previousLayerName = useRef<string>(mapLayerProperties.name);
  const previousShowNoaaChart = useRef<boolean>(
    mapOptionProperties.showNoaaChart
  );
  const [viewState, setViewState] = useState<ViewState>(
    DEFAULT_INITIAL_VIEW_STATE
  );
  const [hoverPopupInfo, setHoverPopupInfo] = useState<PopupInfoState>();
  const [showHoverPopup, setShowHoverPopup] = useState<boolean>(false);
  const [selectedPopupInfo, setSelectedPopupInfo] = useState<PopupInfoState>();
  const [isInitialMount, setIsInitialMount] = useState<boolean>(true);

  const [windFieldOpacity, setWindFieldOpacity] = useState<number>(100);

  const map = useMap()[MAP_ID];
  const {
    query: { summary }
  } = useRouter();
  const { filteredVessels } = useContext(VesselContext);
  const windowWidth = useWindowWidth();
  const { authState } = useOktaAuth();
  const {
    showVesselNames,
    showCyclones,
    showCycloneWindFields,
    showNoaaChart
  } = mapOptionProperties;
  const { featureCyclonesEnabled } = useContext(FeatureFlagContext);

  const assetNumber = selectedPopupInfo?.vessel?.AssetNumber;
  const accessToken = authState?.accessToken?.accessToken;

  const {
    shouldFetchRoutes,
    currentRouteData,
    routeErrorText,
    selectedRoute,
    showRouteHeatMap,
    routeStartTimestamp
  } = routeState;

  const { routeData, routeError } = useAisRoute({
    assetNumber,
    shouldFetchRoutes,
    accessToken,
    routeStartTimestamp
  });

  const [clickMarker, setClickMarker] = useState<Location>();

  const handleClick = ({ lngLat }: MapLayerMouseEvent) => {
    const { lng: longitude, lat: latitude } = lngLat;
    setClickMarker({ longitude, latitude });
  };

  // Update the selected ship status when the query params are updated (?summary=[assetNumber])
  useEffect(() => {
    if (!!summary) {
      const selectedVessel = filteredVessels.find(
        (vessel) => vessel.AssetNumber === Number(summary)
      );
      if (selectedVessel) {
        setSelectedPopupInfo({
          message: selectedVessel.VesselName,
          latitude: selectedVessel.Latitude,
          longitude: selectedVessel.Longitude,
          id: selectedVessel.AssetNumber,
          vessel: selectedVessel
        });
        if (isInitialMount) {
          setViewState({
            ...DEFAULT_INITIAL_VIEW_STATE,
            latitude: selectedVessel.Latitude,
            longitude: selectedVessel.Longitude
          });
        }
      }
    } else {
      setSelectedPopupInfo(undefined);
    }

    cleanupPreviousRoutes(map);
    routeDispatch({
      type: RouteActionType.UPDATE_ALL,
      payload: {
        shouldFetchRoutes: false,
        currentRouteData: null,
        routeErrorText: null,
        selectedRoute: null,
        currentTime: null,
        showRouteHeatMap: false,
        routeStartTimestamp: null
      }
    });

    setIsInitialMount(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summary]);

  // Delay the hover popup
  useEffect(() => {
    let timeout: number | undefined;
    setShowHoverPopup(false);
    if (!!hoverPopupInfo) {
      timeout = window.setTimeout(
        () => setShowHoverPopup(true),
        HOVER_POPUP_DELAY_MS
      );
    } else if (timeout !== undefined) {
      window.clearTimeout(timeout);
    }
    return () => window.clearTimeout(timeout);
  }, [hoverPopupInfo]);

  const onResize = useCallback(() => {
    if (map) {
      setTimeout(() => {
        map.resize();
      }, 0);
    }

    return null;
  }, [map]);

  const handleRouteResults = useCallback(() => {
    if (routeError) {
      cleanupPreviousRoutes(map);
      routeDispatch({
        type: RouteActionType.UPDATE_ALL,
        payload: {
          shouldFetchRoutes: false,
          currentRouteData: null,
          routeErrorText: routeError.message,
          selectedRoute: null,
          showRouteHeatMap: false,
          routeStartTimestamp: null
        }
      });
    } else if (routeData) {
      if (routeData.routes.length === 0) {
        routeDispatch({
          type: RouteActionType.FETCH_ROUTES_AND_ERROR_TEXT,
          payload: {
            shouldFetchRoutes: false,
            routeErrorText: 'No routes found',
            selectedRoute: null,
            routeStartTimestamp: null
          }
        });
      } else {
        const currentRoute = routeData.routes.find(
          (route) => RouteType.Current === route.routeType
        );

        if (currentRoute) {
          const minSpeed = Math.min(
            ...(currentRoute.routePoints as RoutePoint[]).map(
              (routePoint) => routePoint.SpeedOverGround
            )
          );

          const maxSpeed = Math.max(
            ...(currentRoute.routePoints as RoutePoint[]).map(
              (routePoint) => routePoint.SpeedOverGround
            )
          );

          currentRoute.lowSpeedOverGround = minSpeed;
          currentRoute.highSpeedOverGround = maxSpeed;
        }

        routeDispatch({
          type: RouteActionType.FETCH_ROUTES_AND_ROUTE_DATA,
          payload: {
            shouldFetchRoutes: false,
            currentRouteData: routeData,
            selectedRoute: currentRoute
          }
        });
        logRouteResults(routeData);
      }
    }
  }, [map, routeData, routeError, routeDispatch]);

  useEffect(() => {
    handleRouteResults();
  }, [handleRouteResults]);

  useEffect(() => {
    if (currentRouteData) {
      const currentRoute = currentRouteData.routes.find(
        (route) => RouteType.Current === route.routeType
      );

      if (
        mapLayerProperties.name !== previousLayerName.current ||
        mapOptionProperties.showNoaaChart !== previousShowNoaaChart.current
      ) {
        previousLayerName.current = mapLayerProperties.name;
        previousShowNoaaChart.current = mapOptionProperties.showNoaaChart;
        map?.getMap().once('idle', () => {
          addRouteToMap(
            map,
            currentRoute,
            mapLayerProperties.name,
            showRouteHeatMap ?? false,
            LOW_SPEED,
            MIDDLE_SPEED,
            HIGH_SPEED,
            mapOptionProperties
          );
        });
      } else {
        addRouteToMap(
          map,
          currentRoute,
          mapLayerProperties.name,
          showRouteHeatMap ?? false,
          LOW_SPEED,
          MIDDLE_SPEED,
          HIGH_SPEED,
          mapOptionProperties
        );
      }
    }
  }, [
    map,
    currentRouteData,
    mapLayerProperties.name,
    showRouteHeatMap,
    mapOptionProperties
  ]);

  return (
    <div className="flex-1">
      <AutoSizer>{onResize}</AutoSizer>
      <ReactMapGLMap
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapStyle={mapLayerProperties.value}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        id={MAP_ID}
        renderWorldCopies={false}
        dragRotate={false}
        maxZoom={MAP_MAX_ZOOM}
        touchPitch={false}
        attributionControl={false}
        onClick={handleClick}
      >
        <MapInfo
          routeError={routeError}
          routeErrorText={routeErrorText}
          routeData={routeData}
          shouldFetchRoutes={shouldFetchRoutes}
          selectedLayerName={mapLayerProperties.name}
        />

        <MapControls
          currentRouteData={currentRouteData}
          selectedRoute={selectedRoute}
          showRouteHeatMap={showRouteHeatMap}
          windowWidth={windowWidth}
          mapLayerProperties={mapLayerProperties}
          setMapLayerProperties={setMapLayerProperties}
          mapOptionProperties={mapOptionProperties}
          setMapOptionProperties={setMapOptionProperties}
          windFieldOpacity={windFieldOpacity}
          setWindFieldOpacity={setWindFieldOpacity}
        />

        <AllLayers
          showNoaaChartLayer={showNoaaChart}
          showBathymetryLayer={mapLayerProperties.id === BATHYMETRY_LAYER.id}
          showCycloneLayer={featureCyclonesEnabled && showCyclones}
          mapRef={map}
          selectedLayerName={mapLayerProperties.name}
          showCycloneWindFields={showCycloneWindFields}
          vessels={filteredVessels}
          windFieldOpacity={windFieldOpacity}
        />

        <MapPopups
          showVesselNames={showVesselNames}
          vessels={filteredVessels}
          windowWidth={windowWidth}
          selectedPopupInfo={selectedPopupInfo}
          routeState={routeState}
          routeDispatch={routeDispatch}
          showHoverPopup={showHoverPopup}
          hoverPopupInfo={hoverPopupInfo}
          clickMarker={clickMarker}
          setClickMarker={setClickMarker}
          selectedLayerName={mapLayerProperties.name}
        />

        <VesselMarkers
          vessels={filteredVessels}
          selectedLayerName={mapLayerProperties.name}
          setHoverPopupInfo={setHoverPopupInfo}
        />
      </ReactMapGLMap>
    </div>
  );
}

export default Map;
