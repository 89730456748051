import { Icon } from '@org-crowley/enterprise-react-component-library';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Marker, useMap } from 'react-map-gl';
import {
  BATHYMETRY_LAYER,
  LIGHT_LAYER,
  MAP_ID,
  PopupInfoState
} from '../../Map';
import styles from './VesselMarker.module.css';
import { VesselStatus } from '../../../../models/Vessel';
import { getIconNameFromStatus } from '../../../StatusIcon/StatusIcon';
import { HOME_PAGE_URL } from '../../../../utils/routes';

export interface VesselMarkerProps {
  id: number;
  location: Location;
  status: VesselStatus;
  heading?: number;
  vesselName: string;
  hoverPopupInfoSetter?: Dispatch<SetStateAction<PopupInfoState | undefined>>;
  mapLayer?: string;
}

export interface Location {
  latitude: number;
  longitude: number;
}

function shouldPan(
  targetLocation: Location,
  currentLocation: Location,
  currentZoom: number
): boolean {
  return (
    Math.abs(currentLocation.longitude - targetLocation.longitude) >=
      10 * Math.pow(currentZoom, -2.2) ||
    Math.abs(currentLocation.latitude - currentLocation.latitude) >=
      10 * Math.pow(currentZoom, -2.2)
  );
}

function VesselMarker({
  id,
  location,
  status,
  heading,
  vesselName,
  hoverPopupInfoSetter,
  mapLayer
}: VesselMarkerProps) {
  const {
    push,
    query: { summary }
  } = useRouter();
  const { latitude, longitude } = location;
  const map = useMap()[MAP_ID];
  const isSelected = useMemo(() => Number(summary) === id, [id, summary]);

  const onClick = useCallback(
    ({ originalEvent: e }) => {
      e?.preventDefault();
      e?.stopPropagation();
      if (!map) return;
      if (isSelected) {
        push(HOME_PAGE_URL);
      } else {
        push(`?summary=${id}`, undefined, {
          shallow: true
        });
        if (
          shouldPan(
            location,
            { latitude: map.getCenter().lat, longitude: map.getCenter().lng },
            map.getZoom()
          )
        )
          map.panTo([longitude, latitude]);
      }
    },
    [id, isSelected, latitude, location, longitude, map, push]
  );

  const statusColorClassName = useMemo(() => {
    if (
      status === VesselStatus.Moving &&
      (mapLayer === LIGHT_LAYER.name || mapLayer === BATHYMETRY_LAYER.name)
    )
      return 'text-blue-100';
    if (status === VesselStatus.Moving) return 'text-blue-10';
    if (status === VesselStatus.Stopped) return 'text-red-30';
    if (status === VesselStatus.OutOfService || status === VesselStatus.NA)
      return 'text-yellow-50';
  }, [status, mapLayer]);

  return (
    <Marker
      {...location}
      onClick={onClick}
      rotation={status === VesselStatus.Moving ? heading : 0}
    >
      <Icon
        iconName={getIconNameFromStatus(status)}
        className={classNames(
          'w-3',
          'h-3',
          'cursor-pointer',
          statusColorClassName,
          styles.vessel,
          {
            [styles.light_selected]:
              isSelected &&
              (mapLayer === LIGHT_LAYER.name ||
                mapLayer === BATHYMETRY_LAYER.name),
            [styles.light]:
              mapLayer === LIGHT_LAYER.name ||
              mapLayer === BATHYMETRY_LAYER.name,
            [styles.selected]: isSelected
          }
        )}
        onMouseEnter={() => {
          !isSelected &&
            hoverPopupInfoSetter &&
            hoverPopupInfoSetter({
              ...location,
              message: vesselName,
              id: id
            });
        }}
        onMouseLeave={() => {
          hoverPopupInfoSetter && hoverPopupInfoSetter(undefined);
        }}
      />
    </Marker>
  );
}

export default VesselMarker;
