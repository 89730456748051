import { Typography } from '@org-crowley/enterprise-react-component-library';
import React from 'react';

export interface SpeedOverGroundLegendProps {
  minSpeedOverGround: number;
  maxSpeedOverGround: number;
  /** Can be rgb, rgba, or hex color */
  lowGradient: string;
  /** Can be rgb, rgba, or hex color */
  middleGradient: string;
  /** Can be rgb, rgba, or hex color */
  highGradient: string;
}

const SpeedOverGroundLegend = ({
  minSpeedOverGround,
  maxSpeedOverGround,
  lowGradient,
  middleGradient,
  highGradient
}: SpeedOverGroundLegendProps) => {
  return (
    <>
      <div className="text-center">
        <Typography variant="h300" as="h4" className="text-silver-100">
          Speed Over Ground
        </Typography>
      </div>
      <div className="flex justify-between items-stretch gap-10">
        <div>
          <Typography variant="body-small" className="text-silver-90">
            {minSpeedOverGround.toFixed(1)} Knot
            {minSpeedOverGround === 1 ? '' : 's'}
          </Typography>
        </div>
        <div>
          <Typography variant="body-small" className="text-silver-90">
            {maxSpeedOverGround.toFixed(1)} Knot
            {maxSpeedOverGround === 1 ? '' : 's'}
          </Typography>
        </div>
      </div>
      <div
        className="w-full h-4 rounded-full"
        style={{
          backgroundImage: `linear-gradient(to right, ${lowGradient}, ${middleGradient}, ${highGradient})`
        }}
      ></div>
    </>
  );
};

export default SpeedOverGroundLegend;
