import 'mapbox-gl/dist/mapbox-gl.css';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useCallback, useContext, useEffect, useState } from 'react';
import ReactMapGLMap, { useMap, ViewState } from 'react-map-gl';
import {
  MAP_LAYER_PROPS_STORAGE_KEY,
  MAP_OPTION_PROPS_STORAGE_KEY
} from '../../../utils/localStorageKeys';
import { useLocalStorageBackedState } from '../../../utils/useLocalStorageBackedState';
import {
  BATHYMETRY_LAYER,
  DEFAULT_INITIAL_VIEW_STATE,
  DEFAULT_LAYER,
  DEFAULT_MAP_OPTIONS,
  MapLayer,
  MapOptions,
  MAP_MAX_ZOOM
} from '../Map';
import { useWindowWidth } from '@react-hook/window-size';
import { Vessel } from '../../../models/Vessel';
import VesselMarker from '../VesselMarkers/VesselMarker/VesselMarker';
import MapControls from '../MapControls/MapControls';
import AllLayers from '../Layers/AllLayers/AllLayers';
import { FeatureFlagContext } from '../../../contexts/FeatureFlagContext';
import DefaultPopup from '../MapPopups/DefaultPopup/DefaultPopup';
import { RESPONSIVE_BREAKPOINTS } from '@org-crowley/enterprise-react-component-library';
import cn from 'classnames';
import CollapsibleMovementInfo from '../CollapsibleMovementInfo/CollapsibleMovementInfo';

const MAP_ID = 'MiniMap';

export interface MiniMapProps {
  vessel: Vessel;
}

const MiniMap = ({ vessel }: MiniMapProps) => {
  const [windFieldOpacity, setWindFieldOpacity] = useState<number>(100);

  const { Latitude, Longitude, VesselName, Status, Heading, AssetNumber } =
    vessel;

  const mapRef = useMap()[MAP_ID];

  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < RESPONSIVE_BREAKPOINTS.medium;

  const [viewState, setViewState] = useState<ViewState>(
    DEFAULT_INITIAL_VIEW_STATE
  );

  const [mapLayerProperties, setMapLayerProperties] =
    useLocalStorageBackedState<MapLayer>({
      key: MAP_LAYER_PROPS_STORAGE_KEY,
      initialValue: DEFAULT_LAYER,
      persist: true
    });

  const [mapOptionProperties, setMapOptionProperties] =
    useLocalStorageBackedState<MapOptions>({
      key: MAP_OPTION_PROPS_STORAGE_KEY,
      initialValue: DEFAULT_MAP_OPTIONS,
      persist: true
    });

  const { featureCyclonesEnabled } = useContext(FeatureFlagContext);

  const { showCyclones, showCycloneWindFields, showNoaaChart } =
    mapOptionProperties;

  useEffect(() => {
    setViewState({
      ...DEFAULT_INITIAL_VIEW_STATE,
      latitude: Latitude,
      longitude: Longitude
    });
  }, [Latitude, Longitude]);

  const onResize = useCallback(() => {
    if (mapRef) {
      setTimeout(() => {
        mapRef.resize();
      }, 0);
    }

    return null;
  }, [mapRef]);

  return (
    <div
      className={cn({ 'w-screen': isMobile }, 'h-full', 'relative')}
      onClick={(event) => event.stopPropagation()}
    >
      <CollapsibleMovementInfo vessel={vessel} />
      <AutoSizer>{onResize}</AutoSizer>
      <ReactMapGLMap
        style={{ borderRadius: isMobile ? '0px' : '12px' }}
        {...viewState}
        onMove={(event) => setViewState(event.viewState)}
        mapStyle={mapLayerProperties.value}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        id={MAP_ID}
        renderWorldCopies={false}
        dragRotate={false}
        maxZoom={MAP_MAX_ZOOM}
        touchPitch={false}
        attributionControl={false}
      >
        <MapControls
          windowWidth={windowWidth}
          mapLayerProperties={mapLayerProperties}
          setMapLayerProperties={setMapLayerProperties}
          mapOptionProperties={mapOptionProperties}
          setMapOptionProperties={setMapOptionProperties}
          windFieldOpacity={windFieldOpacity}
          setWindFieldOpacity={setWindFieldOpacity}
        />

        <AllLayers
          showNoaaChartLayer={showNoaaChart}
          showBathymetryLayer={mapLayerProperties.id === BATHYMETRY_LAYER.id}
          showCycloneLayer={featureCyclonesEnabled && showCyclones}
          mapRef={mapRef}
          selectedLayerName={mapLayerProperties.name}
          showCycloneWindFields={showCycloneWindFields}
          vessels={[vessel]}
          windFieldOpacity={windFieldOpacity}
        />

        <VesselMarker
          id={AssetNumber}
          status={Status}
          location={{
            latitude: Latitude,
            longitude: Longitude
          }}
          heading={Heading}
          vesselName={VesselName}
          mapLayer={mapLayerProperties.name}
        />

        <DefaultPopup longitude={Longitude} latitude={Latitude}>
          {VesselName}
        </DefaultPopup>
      </ReactMapGLMap>
    </div>
  );
};

export default MiniMap;
