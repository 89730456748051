import React, { useEffect, useMemo, useState } from 'react';
import { MapRef, Popup } from 'react-map-gl';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import mapboxgl, { MapLayerMouseEvent } from 'mapbox-gl';
import { useCyclones } from '../../../../utils/useCyclones';
import { useOktaAuth } from '../../../../contexts/OktaContext';
import {
  MapMessage,
  MessageSeverity
} from '../../MapInfo/MapMessage/MapMessage';
import { BATHYMETRY_LAYER, LIGHT_LAYER } from '../../Map';
import { createCycloneMapData } from '../../../../utils/cycloneUtils';
import CycloneTrackMarker from './CycloneTrackMarker';
import ForecastTrackMarker from './ForecastTrackMarker';
import WindFieldLayer from './WindFieldLayer';
import { Vessel } from '../../../../models/Vessel';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';
import VesselCircleLayer from './VesselCircleLayer';
import CycloneTrackLineLayer from './CycloneTrackLineLayer';
import CycloneForecastLineLayer from './CycloneForecastLineLayer';
import CycloneBreakPointAlertsLayer from './CycloneBreakPointAlertsLayer';
import CycloneErrorConeLayer from './CycloneErrorConeLayer';
import CycloneTrackLayer from './CycloneTrackLayer';
import CycloneForecastLayer from './CycloneForecastLayer';

interface PopupInfo {
  longitude: number;
  latitude: number;
  stormName: string;
  dateTimeISO: string;
  windSpeed: string;
  windGustSpeed: string;
  pressure: string;
  movement: string;
}

interface CycloneLayerProps {
  map: MapRef | undefined;
  selectedLayerName: string;
  showCycloneWindFields: boolean;
  vessels: Vessel[];
  windFieldOpacity: number;
}

const CycloneLayer = ({
  map,
  selectedLayerName,
  showCycloneWindFields,
  vessels,
  windFieldOpacity
}: CycloneLayerProps) => {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const { cycloneData, cycloneError } = useCyclones({
    accessToken
  });

  const cycloneMapData = useMemo(() => {
    return createCycloneMapData(cycloneData);
  }, [cycloneData]);

  const onShowPopup = (trackFeature: Feature<Geometry, GeoJsonProperties>) => {
    setPopupInfo({
      longitude:
        trackFeature.geometry.type === 'Point'
          ? trackFeature.geometry.coordinates[0]
          : 0,
      latitude:
        trackFeature.geometry.type === 'Point'
          ? trackFeature.geometry.coordinates[1]
          : 0,
      stormName: trackFeature.properties?.stormName,
      dateTimeISO: trackFeature.properties?.dateTimeISO,
      windSpeed: trackFeature.properties?.windSpeed,
      windGustSpeed: trackFeature.properties?.windGustSpeed,
      pressure: trackFeature.properties?.pressure,
      movement: trackFeature.properties?.movement
    });
    setShowPopup(true);
  };

  const onHidePopup = () => {
    setPopupInfo(null);
    setShowPopup(false);
  };

  const trackMarkers = useMemo(() => {
    return cycloneMapData.trackData.features.map((trackFeature, index) => {
      return (
        <CycloneTrackMarker
          key={`trackFeature-${index}`}
          trackFeature={trackFeature}
          onShowPopup={onShowPopup}
          onHidePopup={onHidePopup}
        />
      );
    });
  }, [cycloneMapData]);

  const forecastMarkers = useMemo(() => {
    return cycloneMapData.forecastData.features.map(
      (forecastFeature, index) => {
        return (
          <ForecastTrackMarker
            key={`forecastFeature-${index}`}
            forecastFeature={forecastFeature}
            onShowPopup={onShowPopup}
            onHidePopup={onHidePopup}
          />
        );
      }
    );
  }, [cycloneMapData]);

  const popup = useMemo(() => {
    return new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
  }, []);

  useEffect(() => {
    let hoverId: string | number | undefined;

    const mouseMove = (e: MapLayerMouseEvent) => {
      if (
        map &&
        e &&
        e.features &&
        e.features.length > 0 &&
        e.features[0].geometry.type === 'LineString' &&
        map.getFeatureState({
          source: MapSourceId.CycloneBreakPointAlerts,
          id: e.features[0].id
        })
      ) {
        map.getCanvas().style.cursor = 'pointer';

        if (hoverId !== undefined) {
          map.removeFeatureState({
            source: MapSourceId.CycloneBreakPointAlerts,
            id: hoverId
          });
        }

        hoverId = e.features[0].id;

        map.setFeatureState(
          {
            source: MapSourceId.CycloneBreakPointAlerts,
            id: e.features[0].id
          },
          { hover: true }
        );

        const featureProps = e.features[0].properties;
        const html = `Alert Type: ${featureProps?.alertTypeDescription}`;
        popup
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML(html)
          .addTo(map.getMap());
      }
    };

    const mouseLeave = () => {
      if (map) {
        map.getCanvas().style.cursor = '';

        if (hoverId !== undefined) {
          map.setFeatureState(
            {
              source: MapSourceId.CycloneBreakPointAlerts,
              id: hoverId
            },
            { hover: false }
          );
          hoverId = undefined;
        }
      }
      popup.remove();
    };

    map?.on('mousemove', MapLayerId.CycloneBreakPointAlerts, mouseMove);

    map?.on('mouseleave', MapLayerId.CycloneBreakPointAlerts, mouseLeave);

    return () => {
      map?.off('mousemove', MapLayerId.CycloneBreakPointAlerts, mouseMove);
      map?.off('mouseleave', MapLayerId.CycloneBreakPointAlerts, mouseLeave);
    };
  }, [map, popup]);

  const symbolColor = useMemo(() => {
    return selectedLayerName === LIGHT_LAYER.name ||
      selectedLayerName === BATHYMETRY_LAYER.name
      ? '#000000'
      : '#FFFFFF';
  }, [selectedLayerName]);

  const paint = useMemo(() => {
    return selectedLayerName === LIGHT_LAYER.name
      ? {
          'text-color': symbolColor
        }
      : {
          'text-color': '#000000',
          'text-halo-color': 'white',
          'text-halo-width': 2
        };
  }, [selectedLayerName, symbolColor]);

  return (
    <>
      {cycloneError && (
        <MapMessage
          messageText={cycloneError.message ?? 'An unknown error occurred'}
          messageSeverity={MessageSeverity.ERROR}
        />
      )}
      {!cycloneError && cycloneData && cycloneMapData && (
        <>
          <CycloneTrackLineLayer cycloneMapData={cycloneMapData} />

          <CycloneForecastLineLayer
            cycloneMapData={cycloneMapData}
            symbolColor={symbolColor}
          />

          <CycloneBreakPointAlertsLayer cycloneMapData={cycloneMapData} />

          <CycloneErrorConeLayer
            cycloneMapData={cycloneMapData}
            symbolColor={symbolColor}
          />

          <CycloneTrackLayer cycloneMapData={cycloneMapData} paint={paint} />

          <CycloneForecastLayer cycloneMapData={cycloneMapData} paint={paint} />

          {showCycloneWindFields && (
            <WindFieldLayer
              map={map}
              selectedLayerName={selectedLayerName}
              symbolColor={symbolColor}
              paint={paint}
              beforeLayerId={MapLayerId.CycloneTrackLine}
              cycloneData={cycloneData}
              vessels={vessels}
              windFieldOpacity={windFieldOpacity}
            />
          )}

          <VesselCircleLayer vessels={vessels} />

          {showPopup && popupInfo && (
            <Popup
              longitude={popupInfo.longitude}
              latitude={popupInfo.latitude}
              anchor="bottom"
              offset={15}
              closeButton={false}
              focusAfterOpen={false}
              closeOnClick={false}
              closeOnMove={false}
              maxWidth="none"
            >
              <div>{popupInfo.stormName}</div>
              <div>As of {popupInfo.dateTimeISO}</div>
              <div>{popupInfo.windSpeed}</div>
              <div>{popupInfo.windGustSpeed}</div>
              <div>{popupInfo.pressure}</div>
              <div>{popupInfo.movement}</div>
            </Popup>
          )}

          {trackMarkers}
          {forecastMarkers}
        </>
      )}
    </>
  );
};

export default CycloneLayer;
