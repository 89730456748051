import { useState } from 'react';
import { Vessel } from '../../models/Vessel';
import MapModal from '../Map/MapModal/MapModal';
import { StatusIcon } from '../StatusIcon/StatusIcon';
import cn from 'classnames';

interface StatusBadgeProps {
  vessel: Vessel;
  enableMap?: boolean;
}

export function StatusBadge({ vessel, enableMap = false }: StatusBadgeProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const { Status, StatusDetail, Heading } = vessel;

  const onClick = () => {
    if (enableMap) {
      setIsModalOpen(true);
    }
  };

  const onCloseModal = () => {
    if (enableMap) {
      setIsModalOpen(false);
    }
  };

  return (
    <div
      className="flex"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <StatusIcon
        vesselStatus={Status}
        tooltipText={StatusDetail}
        heading={Heading}
        onClick={onClick}
        className={cn({ 'hover:cursor-pointer': enableMap })}
      />
      <MapModal vessel={vessel} isOpen={isModalOpen} onClose={onCloseModal} />
    </div>
  );
}
