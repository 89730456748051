import React, { useMemo } from 'react';
import { Marker } from 'react-map-gl';
import classNames from 'classnames';
import { Feature, Point, Properties } from '@turf/turf';
import warningRedWhite from '../../../../icons/alerts/warning-red-white.svg';
import warningRedBlack from '../../../../icons/alerts/warning-red-black.svg';
import { BATHYMETRY_LAYER, LIGHT_LAYER } from '../../Map';

interface VesselDangerAreaProps {
  selectedLayerName: string;
  pointFeature: Feature<Point, Properties>;
  onShowPopup: (pointFeature: Feature<Point, Properties>) => void;
  onHidePopup: () => void;
}

const VesselDangerAreaMarker = ({
  selectedLayerName,
  pointFeature,
  onShowPopup,
  onHidePopup
}: VesselDangerAreaProps) => {
  const imgSrc = useMemo(() => {
    return selectedLayerName === LIGHT_LAYER.name ||
      selectedLayerName === BATHYMETRY_LAYER.name
      ? warningRedBlack.src
      : warningRedWhite.src;
  }, [selectedLayerName]);

  return (
    <Marker
      longitude={pointFeature.geometry.coordinates[0]}
      latitude={pointFeature.geometry.coordinates[1]}
      color="#FF0000"
    >
      <img
        src={imgSrc}
        alt="Vessel Danger Marker"
        className={classNames(
          'w-8',
          'h-8',
          'cursor-pointer',
          'hover:w-12',
          'hover:h-12'
        )}
        onMouseEnter={() => onShowPopup(pointFeature)}
        onMouseLeave={onHidePopup}
      />
    </Marker>
  );
};

export default VesselDangerAreaMarker;
