import { Typography } from '@org-crowley/enterprise-react-component-library';
import { MdLocationOn } from 'react-icons/md';
import { formatLatitude, formatLongitude } from '../../utils/formatLatLong';
import { Vessel } from '../../models/Vessel';
import LastPositionUpdate from '../LastPositionUpdate/LastPositionUpdate';

interface VesselPositionProps {
  latitude: Vessel['Latitude'] | undefined;
  longitude: Vessel['Longitude'] | undefined;
  AISLastUpdated: Vessel['AISLastUpdated'];
}

export const VesselPosition = ({
  latitude,
  longitude,
  AISLastUpdated
}: VesselPositionProps) => {
  return (
    <>
      <div className="flex justify-center w-full my-2">
        <div className="flex flex-col">
          <Typography variant="h300">
            {latitude && formatLatitude(latitude)}
          </Typography>
          <Typography variant="body-small" className="text-silver-90">
            Latitude
          </Typography>
        </div>
        <MdLocationOn color="green" size={24} className="fill-green" />
        <div className="flex flex-col">
          <Typography variant="h300">
            {longitude && formatLongitude(longitude)}
          </Typography>
          <Typography variant="body-small" className="text-silver-90">
            Longitude
          </Typography>
        </div>
      </div>
      <LastPositionUpdate
        aisLastUpdated={AISLastUpdated}
        className="justify-center w-full pb-2"
      />
    </>
  );
};
