import { Layer, Source } from 'react-map-gl';
import { CycloneMapData } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

export interface CycloneTrackLayerProps {
  cycloneMapData: CycloneMapData;
  paint:
    | {
        'text-color': string;
        'text-halo-color'?: undefined;
        'text-halo-width'?: undefined;
      }
    | {
        'text-color': string;
        'text-halo-color': string;
        'text-halo-width': number;
      };
}

const CycloneTrackLayer = ({
  cycloneMapData,
  paint
}: CycloneTrackLayerProps) => {
  return (
    <Source
      id={MapSourceId.CycloneTrack}
      type="geojson"
      data={cycloneMapData.trackData}
    >
      <Layer
        id={MapLayerId.CycloneTrack}
        type="symbol"
        layout={{
          'text-field': ['get', 'stormShortName'],
          'text-allow-overlap': true,
          'text-anchor': 'left',
          'text-offset': [1.5, 0]
        }}
        paint={paint}
      ></Layer>
    </Source>
  );
};

export default CycloneTrackLayer;
