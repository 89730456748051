import {
  ActionDropdown,
  Button,
  CollapsibleContainer,
  IconButton,
  SpecSheet,
  Typography
} from '@org-crowley/enterprise-react-component-library';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Dispatch, useEffect, useState } from 'react';
import {
  MdClose,
  MdGpsFixed,
  MdOpenInNew,
  MdOutlineMoreHoriz,
  MdShare
} from 'react-icons/md';
import { useMap } from 'react-map-gl';
import {
  RouteAction,
  RouteActionType,
  RouteState
} from '../../../../reducers/routeReducer';
import { fetchRoutes } from '../../../../utils/fetchRoutes';
import { formatLatLong } from '../../../../utils/formatLatLong';
import { cleanupPreviousRoutes } from '../../../../utils/routeMapper';
import { DETAILS_PAGE_URL, HOME_PAGE_URL } from '../../../../utils/routes';
import { DATA_PENDING_TEXT } from '../../../../utils/textCopy';
import { useVoyageSummaries } from '../../../../utils/useVoyageSummaries';
import { DateDisplay } from '../../../DateDisplay/DateDisplay';
import { MAP_ID } from '../../Map';
import { useOktaAuth } from '../../../../contexts/OktaContext';
import { StackedText } from '../../../StackedText/StackedText';
import { StatusBadge } from '../../../StatusBadge/StatusBadge';
import { Toggle } from '../../../Toggle/Toggle';
import { Vessel } from '../../../../models/Vessel';
import LastPositionUpdate from '../../../LastPositionUpdate/LastPositionUpdate';

export interface MobileSelectedPopupProps {
  vesselInfo: Vessel;
  routeState: RouteState;
  routeDispatch: Dispatch<RouteAction>;
}

function MobileSelectedPopupContent({
  vesselInfo,
  routeState,
  routeDispatch
}: MobileSelectedPopupProps) {
  const { push } = useRouter();
  const map = useMap()[MAP_ID];
  const [shouldFetchVoyageSummaries, setShouldFetchVoyageSummaries] =
    useState<boolean>(false);
  const { authState } = useOktaAuth();
  const accessToken = authState?.accessToken?.accessToken;
  const { showRouteHeatMap, selectedRoute } = routeState;

  const onToggleCurrentRoute = () => {
    if (selectedRoute) {
      cleanupPreviousRoutes(map);
      routeDispatch({
        type: RouteActionType.UPDATE_ALL,
        payload: {
          shouldFetchRoutes: false,
          currentRouteData: null,
          routeErrorText: null,
          selectedRoute: null,
          currentTime: null,
          showRouteHeatMap: false,
          routeStartTimestamp: null
        }
      });
    } else {
      setShouldFetchVoyageSummaries(true);
    }
  };

  const { data: voyageData, error: voyageError } = useVoyageSummaries({
    shouldFetch: shouldFetchVoyageSummaries,
    assetNumber: vesselInfo?.AssetNumber,
    accessToken: accessToken
  });

  useEffect(() => {
    fetchRoutes({
      voyageData,
      voyageError,
      routeDispatch,
      setShouldFetchVoyageSummaries
    });
  }, [voyageData, routeDispatch, voyageError]);

  const onToggleRouteHeatMap = () => {
    routeDispatch({
      type: RouteActionType.TOGGLE_ROUTE_HEAT_MAP,
      payload: {}
    });
  };

  return (
    <div className="p-2">
      <div className="flex justify-between items-center w-full">
        <Button
          variant="tertiary"
          className="!px-3"
          onClick={() => push(HOME_PAGE_URL)}
          aria-label="close"
        >
          <MdClose size={20} />
        </Button>
        <ActionDropdown
          items={[
            {
              icon: MdShare,
              label: 'Copy URL',
              onClick: () => navigator.clipboard.writeText(window.location.href)
            },
            {
              icon: MdOpenInNew,
              label: 'Open in New Window',
              onClick: () =>
                window.open(
                  `${window.location.origin}/details/${vesselInfo?.AssetNumber}`,
                  '_blank'
                )
            }
          ]}
        >
          <IconButton Icon={MdOutlineMoreHoriz} />
        </ActionDropdown>
      </div>
      <div className="flex flex-row justify-between px-4 pt-2 items-center gap-4">
        <Typography as="h3" variant="h500">
          {vesselInfo.VesselName}
        </Typography>
        <StatusBadge vessel={vesselInfo} />
      </div>
      <Typography
        variant="body-small"
        className="text-silver-90 px-4 flex items-center"
      >
        <MdGpsFixed size={16} className="mr-1 text-silver-40" />
        {formatLatLong(vesselInfo.Latitude, vesselInfo.Longitude)}
      </Typography>

      <LastPositionUpdate
        aisLastUpdated={vesselInfo.AISLastUpdated}
        className="px-4 pb-1"
      />

      <CollapsibleContainer heading="Port Details" startOpen={false}>
        <SpecSheet
          className="text-silver-90 my-4"
          statBlocks={[
            {
              stats: [
                {
                  key: 'Previous Port',
                  value: (
                    <StackedText
                      title={vesselInfo.PreviousPortName}
                      subTitle={vesselInfo.PreviousPortCountry}
                    />
                  )
                },
                {
                  key: 'Next Port',
                  value: (
                    <StackedText
                      title={vesselInfo.NextPort}
                      subTitle={DATA_PENDING_TEXT}
                    />
                  )
                },
                {
                  key: (
                    <Typography variant="h200" className="flex items-center">
                      <span className="mr-2">ATD</span>
                    </Typography>
                  ),
                  value: <DateDisplay date={vesselInfo.PreviousPortADT} />
                },
                {
                  key: (
                    <Typography variant="h200" className="flex items-center">
                      <span className="mr-2">ETA</span>
                    </Typography>
                  ),
                  value: <DateDisplay date={vesselInfo.ETA} />
                }
              ]
            }
          ]}
          statBlockVariant="stacked"
        />
      </CollapsibleContainer>
      <div className="pb-3 text-center">
        <Toggle
          id="toggle-show-current-route"
          checked={!!selectedRoute}
          onChange={onToggleCurrentRoute}
          title="Show Current Route"
        >
          <Typography variant="h200" className="text-silver-100">
            Show Current Route
          </Typography>
        </Toggle>
      </div>
      {selectedRoute && (
        <div className="text-center pt-1 pb-3">
          <Toggle
            id="toggle-route-heat-map"
            checked={!!showRouteHeatMap}
            onChange={onToggleRouteHeatMap}
            title="Speed Over Ground Heat Map"
          >
            <Typography variant="h200" className="text-silver-100">
              SOG Heat Map
            </Typography>
          </Toggle>
        </div>
      )}
      <Link
        href={{
          pathname: `${DETAILS_PAGE_URL}/${vesselInfo.AssetNumber}`,
          query: {
            from: `${HOME_PAGE_URL}?summary=${vesselInfo.AssetNumber}`
          }
        }}
        passHref
      >
        <Button as="a" className="w-full">
          More Vessel Details
        </Button>
      </Link>
    </div>
  );
}

export default MobileSelectedPopupContent;
