import { Layer, Source } from 'react-map-gl';
import { CycloneMapData } from '../../../../utils/cycloneUtils';
import { MapLayerId, MapSourceId } from '../../../../utils/mapLayers';

export interface CycloneForecastLineLayerProps {
  cycloneMapData: CycloneMapData;
  symbolColor: '#000000' | '#FFFFFF';
}

const CycloneForecastLineLayer = ({
  cycloneMapData,
  symbolColor
}: CycloneForecastLineLayerProps) => {
  return (
    <Source
      id={MapSourceId.CycloneForecastLine}
      type="geojson"
      data={cycloneMapData.forecastLineData}
    >
      <Layer
        id={MapLayerId.CycloneForecastLine}
        type="line"
        layout={{ 'line-join': 'round', 'line-cap': 'round' }}
        paint={{
          'line-color': symbolColor,
          'line-opacity': 0.25,
          'line-width': ['interpolate', ['linear'], ['zoom'], 0, 4, 10, 1],
          'line-dasharray': [0, 2, 0, 2]
        }}
      ></Layer>
    </Source>
  );
};

export default CycloneForecastLineLayer;
