import { VoyageSchedule } from '../models/Voyage';
import { searchVoyageTime } from './searchVoyageTime';

export const checkRouteDepartureTime = (voyageData: VoyageSchedule) => {
  const currentVoyage = voyageData.voyageSummaries[voyageData.currentVoyage];
  const fiveDaysAgo = new Date().setDate(new Date().getDate() - 5);
  const departureTime = searchVoyageTime(currentVoyage).departureTime.value;

  //The limit to go back is a maximum of 15 days
  const startTimestampLimit = new Date().setDate(new Date().getDate() - 15);

  const departureTimeInMillis =
    departureTime && typeof departureTime === 'string'
      ? new Date(departureTime).getTime()
      : Number(departureTime);

  let searchTime;
  if (departureTime) {
    if (departureTimeInMillis < startTimestampLimit) {
      searchTime = fiveDaysAgo;
    } else {
      searchTime = departureTimeInMillis;
    }
  } else {
    searchTime = fiveDaysAgo;
  }

  return searchTime;
};
